import React from 'react';
import PropTypes from 'prop-types';
import classes from './styles.module.scss';

const steps = (props) => <div className={[classes.Steps, props.transition ? classes["Steps--transition"] : '', props.twoColumns ? classes["Steps--withSidebar"] : ''].join(' ')}>{props.children}</div>;

steps.propTypes = {
    twoColumns: PropTypes.bool,
    transition: PropTypes.bool,
};

steps.defaultProps = {
    twoColumns: false,
    transition: false,
};

export default steps;
