import React from 'react';
import PropTypes from 'prop-types';
import classes from './styles.module.scss';

const flexBox = (props) => <div style={props.styles} className={[classes.FlexBox, props.direction === 'column' ? classes["FlexBox--column"] : ''].join(' ')}>{props.children}</div>;

flexBox.propTypes = {
    direction: PropTypes.oneOf(['row', 'column']),
    styles: PropTypes.object
};

flexBox.defaultProps = {
    direction: 'row'
};

export default flexBox;
