import * as actionTypes from '../actions/actionTypes';

export const INVITATIONS_DEFAULT_STATE = {
    loading: false,
    saving: false,
    stored: false,
    error: '',
    invitations: null,
    selected: null,
};

export default function invitations (state = INVITATIONS_DEFAULT_STATE, action) {

    //console.log(action);

    switch (action.type) {

        case actionTypes.SEND_INVITATIONS:
            return {...state, selected: null, saving: true, stored: false, error: ''};

        case actionTypes.SEND_INVITATIONS_SUCCESS:
            return {...state, selected: action.invitations, stored: true, saving: false, error: ''};

        case actionTypes.SEND_INVITATIONS_FAIL:
            return {...state, selected: null, stored: false, saving: false, error: action.error};

        default:
            return state
    }
}
