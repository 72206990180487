import React from 'react';
import PropTypes from 'prop-types';
import classes from './styles.module.scss';

const title = (props) => {
    const { children, alignment, styles, theme, size } = props;

    let themeClass = null;
    switch (theme) {
        case 'secondary': themeClass = classes["Title--secondary"];
            break;
        default: themeClass = null;
    }

    let sizeClass = null;
    switch (size) {
        case 'small': sizeClass = classes["Title--sm"];
            break;
        case 'medium': sizeClass = classes["Title--md"];
            break;
        case 'xLarge': sizeClass = classes["Title--xLg"];
            break;
        default: sizeClass = null;
    }

    let alignmentClass = null;
    switch (alignment) {
        case 'center': alignmentClass = classes["Title--center"];
            break;
        case 'right': alignmentClass = classes["Title--right"];
            break;
        default: alignmentClass = null;
    }

    const titleClass = [classes.Title, alignmentClass, sizeClass, themeClass].join(' ');

    return <h1 className={titleClass} style={styles}>{ children }</h1>
};

title.propTypes = {
    styles: PropTypes.object,
    children: PropTypes.node.isRequired,
    alignment: PropTypes.oneOf(['center', 'left', 'right']),
    theme: PropTypes.oneOf(['primary', 'secondary']),
    size: PropTypes.oneOf(['small', 'medium', 'large', 'xLarge']),
};

title.defaultProps = {
    theme: 'primary',
    size: 'large',
};

export default title;
