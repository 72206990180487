// USERS
export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAIL = 'FETCH_USER_FAIL';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';

// SESSIONS
export const ADD_SESSION = 'ADD_SESSION';
export const ADD_SESSION_SUCCESS = 'ADD_SESSION_SUCCESS';
export const ADD_SESSION_FAIL = 'ADD_SESSION_FAIL';
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const UPDATE_SESSION_SUCCESS = 'UPDATE_SESSION_SUCCESS';
export const UPDATE_SESSION_FAIL = 'UPDATE_SESSION_FAIL';
export const FETCH_SESSION = 'FETCH_SESSION';
export const FETCH_SESSION_SUCCESS = 'FETCH_SESSION_SUCCESS';
export const FETCH_SESSION_FAIL = 'FETCH_SESSION_FAIL';
export const FETCH_SESSION_PLAYERS = 'FETCH_SESSION_PLAYERS';
export const FETCH_SESSION_PLAYERS_SUCCESS = 'FETCH_SESSION_PLAYERS_SUCCESS';
export const FETCH_SESSION_PLAYERS_FAIL = 'FETCH_SESSION_PLAYERS_FAIL';
export const FETCH_SESSION_ANSWERS = 'FETCH_SESSION_ANSWERS';
export const FETCH_SESSION_ANSWERS_SUCCESS = 'FETCH_SESSION_ANSWERS_SUCCESS';
export const FETCH_SESSION_ANSWERS_FAIL = 'FETCH_SESSION_ANSWERS_FAIL';
export const FETCH_SESSIONS = 'FETCH_SESSIONS';
export const FETCH_SESSIONS_SUCCESS = 'FETCH_SESSIONS_SUCCESS';
export const FETCH_SESSIONS_FAIL = 'FETCH_SESSIONS_FAIL';
export const DELETE_SESSION = 'DELETE_SESSION';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';
export const DELETE_SESSION_FAIL = 'DELETE_SESSION_FAIL';

// PLAYERS
export const ADD_PLAYER = 'ADD_PLAYER';
export const ADD_PLAYER_SUCCESS = 'ADD_PLAYER_SUCCESS';
export const ADD_PLAYER_FAIL = 'ADD_PLAYER_FAIL';
export const UPDATE_PLAYER = 'UPDATE_PLAYER';
export const UPDATE_PLAYER_SUCCESS = 'UPDATE_PLAYER_SUCCESS';
export const UPDATE_PLAYER_FAIL = 'UPDATE_PLAYER_FAIL';
export const UPDATE_PLAYER_BY_TOKEN = 'UPDATE_PLAYER_BY_TOKEN';
export const UPDATE_PLAYER_BY_TOKEN_SUCCESS = 'UPDATE_PLAYER_BY_TOKEN_SUCCESS';
export const UPDATE_PLAYER_BY_TOKEN_FAIL = 'UPDATE_PLAYER_BY_TOKEN_FAIL';
export const FETCH_PLAYER = 'FETCH_PLAYER';
export const FETCH_PLAYER_SUCCESS = 'FETCH_PLAYER_SUCCESS';
export const FETCH_PLAYER_FAIL = 'FETCH_PLAYER_FAIL';
export const FETCH_PLAYER_BY_TOKEN = 'FETCH_PLAYER_BY_TOKEN';
export const FETCH_PLAYER_BY_TOKEN_SUCCESS = 'FETCH_PLAYER_BY_TOKEN_SUCCESS';
export const FETCH_PLAYER_BY_TOKEN_FAIL = 'FETCH_PLAYER_BY_TOKEN_FAIL';
export const FETCH_PLAYERS = 'FETCH_PLAYERS';
export const FETCH_PLAYERS_SUCCESS = 'FETCH_PLAYERS_SUCCESS';
export const FETCH_PLAYERS_FAIL = 'FETCH_PLAYERS_FAIL';
export const DELETE_PLAYER = 'DELETE_PLAYER';
export const DELETE_PLAYER_SUCCESS = 'DELETE_PLAYER_SUCCESS';
export const DELETE_PLAYER_FAIL = 'DELETE_PLAYER_FAIL';

// ANSWERS
export const ADD_ANSWER = 'ADD_ANSWER';
export const ADD_ANSWER_SUCCESS = 'ADD_ANSWER_SUCCESS';
export const ADD_ANSWER_FAIL = 'ADD_ANSWER_FAIL';
export const UPDATE_ANSWER = 'UPDATE_ANSWER';
export const UPDATE_ANSWER_SUCCESS = 'UPDATE_ANSWER_SUCCESS';
export const UPDATE_ANSWER_FAIL = 'UPDATE_ANSWER_FAIL';
export const UPDATE_ANSWER_BY_PLAYER_TOKEN = 'UPDATE_ANSWER_BY_PLAYER_TOKEN';
export const UPDATE_ANSWER_BY_PLAYER_TOKEN_SUCCESS = 'UPDATE_ANSWER_BY_PLAYER_TOKEN_SUCCESS';
export const UPDATE_ANSWER_BY_PLAYER_TOKEN_FAIL = 'UPDATE_ANSWER_BY_PLAYER_TOKEN_FAIL';
export const FETCH_ANSWER = 'FETCH_ANSWER';
export const FETCH_ANSWER_SUCCESS = 'FETCH_ANSWER_SUCCESS';
export const FETCH_ANSWER_FAIL = 'FETCH_ANSWER_FAIL';
export const FETCH_ANSWER_BY_PLAYER_TOKEN = 'FETCH_ANSWER_BY_PLAYER_TOKEN';
export const FETCH_ANSWER_BY_PLAYER_TOKEN_SUCCESS = 'FETCH_ANSWER_BY_PLAYER_TOKEN_SUCCESS';
export const FETCH_ANSWER_BY_PLAYER_TOKEN_FAIL = 'FETCH_ANSWER_BY_PLAYER_TOKEN_FAIL';
export const FETCH_ANSWERS = 'FETCH_ANSWERS';
export const FETCH_ANSWERS_SUCCESS = 'FETCH_ANSWERS_SUCCESS';
export const FETCH_ANSWERS_FAIL = 'FETCH_ANSWERS_FAIL';
export const DELETE_ANSWER_BY_PLAYER_TOKEN = 'DELETE_ANSWER_BY_PLAYER_TOKEN';
export const DELETE_ANSWER_BY_PLAYER_TOKEN_SUCCESS = 'DELETE_ANSWER_BY_PLAYER_TOKEN_SUCCESS';
export const DELETE_ANSWER_BY_PLAYER_TOKEN_FAIL = 'DELETE_ANSWER_BY_PLAYER_TOKEN_FAIL';

// SETTINGS
export const ADD_SETTINGS = 'ADD_SETTINGS';
export const ADD_SETTINGS_SUCCESS = 'ADD_SETTINGS_SUCCESS';
export const ADD_SETTINGS_FAIL = 'ADD_SETTINGS_FAIL';
//export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
//export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
//export const UPDATE_SETTINGS_FAIL = 'UPDATE_SETTINGS_FAIL';
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAIL = 'FETCH_SETTINGS_FAIL';

// INVITATIONS
export const SEND_INVITATIONS = 'SEND_INVITATIONS';
export const SEND_INVITATIONS_SUCCESS = 'SEND_INVITATIONS_SUCCESS';
export const SEND_INVITATIONS_FAIL = 'SEND_INVITATIONS_FAIL';
