import React from 'react';
import classes from './styles.module.scss';
import Logo from "../Logo";

const header = () => (
    <div className={classes.Header}>
        <Logo />
    </div>
);

export default header;
