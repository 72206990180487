import * as actionTypes from '../actions/actionTypes';

export const SETTINGS_DEFAULT_STATE = {
    loading: false,
    saving: false,
    stored: false,
    error: '',
    item: null,
};

export default function users (state = SETTINGS_DEFAULT_STATE, action) {

    switch (action.type) {
        case actionTypes.FETCH_SETTINGS: {
            return {...state, item: null, loading: true, stored: false, error: ''};
        }

        case actionTypes.FETCH_SETTINGS_SUCCESS: {
            return {...state, item: action.settings, loading: false, error: ''};
        }

        case actionTypes.FETCH_SETTINGS_FAIL: {
            return {...state, item: null, loading: false, error: action.error};
        }

        /*
        case actionTypes.ADD_SETTINGS:
            return {...state, item: null, saving: true, stored: false, error: ''};

        case actionTypes.ADD_SETTINGS_SUCCESS:
            return {...state, item: action.user, stored: true, saving: false, error: ''};

        case actionTypes.ADD_SETTINGS_FAIL:
            return {...state, item: null, stored: false, saving: false, error: action.error};

        case actionTypes.UPDATE_SETTINGS:
            return {...state, item: null, saving: true, stored: false, error: ''};

        case actionTypes.UPDATE_SETTINGS_SUCCESS:
            return {...state, item: action.user, stored: true, saving: false, error: ''};

        case actionTypes.UPDATE_SETTINGS_FAIL:
            return {...state, stored: false, saving: false, error: action.error};
        */

        default:
            return state
    }
}
