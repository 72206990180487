import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classes from './styles.module.scss';

const paragraph = (props) => {
    const { styles, textId, order, children } = props;

    const boxDescriptionClass = [classes.BoxDescription].join(' ');

    return (
        textId ?
        <FormattedMessage id={textId} defaultMessage={''}>
            {
                textId =>
                    <div className={boxDescriptionClass} style={styles}>
                        <div dangerouslySetInnerHTML={{ __html: textId }}/>
                        {
                            order &&
                                <div className={classes["BoxDescription-order"]}>{ order }</div>
                        }
                    </div>
            }
        </FormattedMessage> :
            <div className={boxDescriptionClass} style={styles}>
                <div dangerouslySetInnerHTML={{ __html: children }}/>
                {
                    order &&
                        <div className={classes["BoxDescription-order"]}>{ order }</div>
                }
            </div>
    )
};

paragraph.propTypes = {
    styles: PropTypes.object,
    children: PropTypes.any,
    textId: PropTypes.string,
    order: PropTypes.string,
};

export default paragraph;
