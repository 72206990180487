// action types
import * as actionTypes from './actionTypes';

// action creators
export function addPlayer(player) {
    return { type: actionTypes.ADD_PLAYER, player }
}

export function addPlayerSuccess(player) {
    return { type: actionTypes.ADD_PLAYER_SUCCESS, player }
}

export function addPlayerFail(error) {
    return { type: actionTypes.ADD_PLAYER_FAIL, error }
}

export function updatePlayer(player) {
    return { type: actionTypes.UPDATE_PLAYER, player }
}

export function updatePlayerSuccess(player) {
    return { type: actionTypes.UPDATE_PLAYER_SUCCESS, player }
}

export function updatePlayerFail(error) {
    return { type: actionTypes.UPDATE_PLAYER_FAIL, error }
}

export function updatePlayerByToken(player) {
    return { type: actionTypes.UPDATE_PLAYER_BY_TOKEN, player }
}

export function updatePlayerByTokenSuccess(player) {
    return { type: actionTypes.UPDATE_PLAYER_BY_TOKEN_SUCCESS, player }
}

export function updatePlayerByTokenFail(error) {
    return { type: actionTypes.UPDATE_PLAYER_BY_TOKEN_FAIL, error }
}

export function fetchPlayer(email) {
    return { type: actionTypes.FETCH_PLAYER, email }
}

export function fetchPlayerSuccess(player) {
    return { type: actionTypes.FETCH_PLAYER_SUCCESS, player }
}

export function fetchPlayerFail(error) {
    return { type: actionTypes.FETCH_PLAYER_FAIL, error }
}

export function fetchPlayerByToken(token) {
    return { type: actionTypes.FETCH_PLAYER_BY_TOKEN, token }
}

export function fetchPlayerByTokenSuccess(player) {
    return { type: actionTypes.FETCH_PLAYER_BY_TOKEN_SUCCESS, player }
}

export function fetchPlayerByTokenFail(error) {
    return { type: actionTypes.FETCH_PLAYER_BY_TOKEN_FAIL, error }
}

export function fetchPlayers() {
    return { type: actionTypes.FETCH_PLAYERS }
}

export function fetchPlayersSuccess(players) {
    return { type: actionTypes.FETCH_PLAYERS_SUCCESS, players }
}

export function fetchPlayersFail(error) {
    return { type: actionTypes.FETCH_PLAYERS_FAIL, error }
}

export function deletePlayer(id) {
    return { type: actionTypes.DELETE_PLAYER, id }
}

export function deletePlayerSuccess(player) {
    return { type: actionTypes.DELETE_PLAYER_SUCCESS, player  }
}

export function deletePlayerFail(error) {
    return { type: actionTypes.DELETE_PLAYER_FAIL, error }
}
