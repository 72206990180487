import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import constants from "../shared/constants";

const getAuth = () => {
    return localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_SESSION) !== null &&
            localStorage.getItem(constants.LOCAL_STORAGE.KEYS.ANSWER_ID) !== null &&
            localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN) !== null;
};

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        getAuth() ? (
            <Component {...props} />
        ) : (
            <Redirect to={{
                pathname: '/',
                state: { from: props.location }
            }}
            />
        )
    )} />
);

export default PrivateRoute;
