import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import constants from "../../../shared/constants";
import * as routes from "../../../shared/routes";
import classes from './styles.module.scss';
import Title from "../../../components/Title";
import ProgressBar from "../../../components/UI/ProgressBar";
import Separator from "../../../components/UI/Separator";
import FlexBox from "../../FlexBox";
import EmptyBox from "../../../components/EmptyBox";
import Circle from "../../../components/UI/Circle";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import biancospinoGif from "../../../assets/gif/companies/biancospino.gif";
import ginestraGif from "../../../assets/gif/companies/ginestra.gif";
import malvaGif from "../../../assets/gif/companies/malva.gif";
import margheritaGif from "../../../assets/gif/companies/margherita.gif";
import narcisoGif from "../../../assets/gif/companies/narciso.gif";
import orchideaGif from "../../../assets/gif/companies/orchidea.gif";
import primulaGif from "../../../assets/gif/companies/primula.gif";
import trifoglioGif from "../../../assets/gif/companies/trifoglio.gif";

import searchingMiddleLeftImage from "../../../assets/images/searching_middle_right.png";
import searchingMiddleRightImage from "../../../assets/images/searching_middle_left.png";

class StepSearchCompanies extends Component {
    constructor(props) {
        super(props);

        this.state = {
            delay: 3800,
            show1: false,
            show2: false,
            show3: false,
            show4: false,
            show5: false,
            show6: false,
            show7: false,
            show8: false,
        };

        this.onSearchingStart = this.onSearchingStart.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.onSearchingStart();
    }

    onSearchingStart(){
        setTimeout(() => {
            this.setState({ show1: true })

        }, 300);
        setTimeout(() => {
            this.setState({ show2: true })

        }, 600);
        setTimeout(() => {
            this.setState({ show3: true })

        }, 900);
        setTimeout(() => {
            this.setState({ show4: true })

        }, 1200);
        setTimeout(() => {
            this.setState({ show5: true })

        }, 1500);
        setTimeout(() => {
            this.setState({ show6: true })

        }, 1800);
        setTimeout(() => {
            this.setState({ show7: true })

        }, 2100);
        setTimeout(() => {
            this.setState({ show8: true })

        }, 2400);

        setTimeout(() => {
            const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);
            this.props.history.push(routes.COMPANIES.replace(':token', token));

        }, this.state.delay);
    }

    render() {
        const { show1, show2, show3, show4, show5, show6, show7, show8 } = this.state;

        const titleStyles = {
            margin: '60px 0 30px'
        };

        const flexStyles = {
            width: '100%',
            maxWidth: '950px',
            margin: '60px auto 50px',
            justifyContent: 'space-between',
        };

        const separatorStyles = {
            margin: '0 auto 60px',
            maxWidth: '460px',
        };

        const blueCircleStyles = {
            position: 'absolute',
            top: '90px',
            right: '300px'
        };

        const orangeCircleStyles = {
            position: 'absolute',
            bottom: '300px',
            left: '90px'
        };

        return (
            <div className={classes.Step} style={{minHeight: '800px'}}>
                <Header />
                <div
                    className={
                    [
                        classes["Step-content"],
                        classes["is-visible"],
                    ].join(' ')}>
                    <div>
                        <Title
                            alignment="center"
                            styles={titleStyles}>
                            <FormattedMessage id="searchCompanies.title"/>
                        </Title>
                        <Separator styles={separatorStyles} />
                        <FlexBox styles={flexStyles}>
                            <EmptyBox>
                                {
                                    show1 &&
                                        <img src={biancospinoGif} alt="biancospino"/>
                                }
                            </EmptyBox>
                            <EmptyBox>
                                {
                                    show2 &&
                                        <img src={ginestraGif} alt="ginestra" />
                                }
                            </EmptyBox>
                            <EmptyBox>
                                {
                                    show3 &&
                                        <img src={malvaGif} alt="malva" />
                                }
                            </EmptyBox>
                            <EmptyBox>
                                {
                                    show4 &&
                                        <img src={margheritaGif} alt="margherita" />
                                }
                            </EmptyBox>
                            <EmptyBox>
                                {
                                    show5 &&
                                        <img src={narcisoGif} alt="narciso" />
                                }
                            </EmptyBox>
                            <EmptyBox>
                                {
                                    show6 &&
                                        <img src={orchideaGif} alt="orchidea" />
                                }
                            </EmptyBox>
                            <EmptyBox>
                                {
                                    show7 &&
                                        <img src={primulaGif} alt="primula" />
                                }
                            </EmptyBox>
                            <EmptyBox>
                                {
                                    show8 &&
                                        <img src={trifoglioGif} alt="trifoglio" />
                                }
                            </EmptyBox>
                        </FlexBox>
                        <ProgressBar secondary />
                    </div>
                    <div>
                        <Circle styles={blueCircleStyles} diameter={23} backgroundColor={'#56f6ff'} />
                        <Circle styles={orangeCircleStyles} diameter={23} backgroundColor={'#fbb03b'} />
                        <img
                            className={[classes["Step-background"], classes["Step-background--searching"], classes["Step-background--middleLeft"]].join(' ')} src={searchingMiddleLeftImage} alt="background middle left"/>
                        <img
                            className={[classes["Step-background"], classes["Step-background--searching"], classes["Step-background--middleRight"]].join(' ')} src={searchingMiddleRightImage} alt="background middle right"/>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default StepSearchCompanies;
