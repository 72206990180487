import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import classes from './styles.module.scss';
import * as routes from '../../shared/routes';
import Banner from "../Banner";
import Header from "../Header";
import Sessions from "../Sessions";
import Session from "../Session";
import Footer from "../Footer";

class Admin extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        window.scrollTo(0, 0)
    }

    render() {
        const bannerStyles = {
            transform: 'translateY(-65px)'
        };

        return (
            <div className={classes.Admin}>
                <Header />
                <div
                    className={
                        [
                            classes["Admin-header"],
                            classes["is-visible"],
                        ].join(' ')}>
                    <div>
                        <Banner styles={bannerStyles} />
                    </div>
                </div>
                <div
                    className={
                        [
                            classes["Admin-content"],
                            classes["is-visible"],
                        ].join(' ')}>
                    <div className={classes["Admin-wrapper"]}>
                        <Switch>
                            <Route exact path={routes.ADMIN_SESSIONS} component={Sessions} />
                            <Route exact path={routes.ADMIN_NEW_SESSION} component={Session} />
                            <Route exact path={routes.ADMIN_EDIT_SESSION} component={Session} />
                            <Redirect to={routes.ADMIN_SESSIONS}/>
                        </Switch>
                    </div>
                </div>
                <Footer position={'static'} />
            </div>
        )
    }
}

export default Admin;
