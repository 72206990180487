import constants from './constants';

export const toColumnName = (num) => {
    for (var ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
        ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
    }
    return ret;
};

export const getYearsArray = (limit = 65) => {
    const currentYear = (new Date()).getFullYear();

    let yearsArray = [];

    for(let i = currentYear - limit; i < currentYear; i++){
        yearsArray.push(
            {
                description: i.toString(),
                value: i.toString(),
            }
        )
    }

    return yearsArray;
};

export const getNumberOfDaysInMonth = (m, y) => { // m is 0 indexed: 0-11
    switch (m) {
        case 1 :
            return (y % 4 === 0 && y % 100) || y % 400 === 0 ? 29 : 28;
        case 8 : case 3 : case 5 : case 10 :
            return 30;
        default :
            return 31
    }
};

export const getDaysArray = (month, year) => {
    let yearsArray = [];

    for(let i = 1; i <= getNumberOfDaysInMonth(month, year); i++){
        yearsArray.push(
            {
                description: i.toString(),
                value: i.toString(),
            }
        )
    }

    return yearsArray;
};

export const checkValidEmail = (email) => {
    if(!email || email.trim() === ''){
        return false;
    }

    const regExp = '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

    const matcher = new RegExp(regExp);
    const keywordTest = email.trim();

    return matcher.test(keywordTest);
};

export const checkValidPhone = (prefix, phone) => {
    if((!prefix || !phone) && (prefix.trim() === '' || phone.trim() === '')){
        return false;
    }

    let valid = false;

    const fullPhone = prefix.trim() + phone.trim();

    if(fullPhone.length > 15){
        valid = false;
    } else {

        if(phone.trim() !== '') {
            const regExp = '^[0-9]*$';
            const matcher = new RegExp(regExp);

            const keywordTest = phone.trim();

            valid = matcher.test(keywordTest);
        } else {
            valid = false;
        }
    }

    return valid;
};

export const countOccurrencesInArrayOfObject = (objects, property, value) => {
    return objects.filter(item => item[property] === value).length;
};

export const getObjectByArray = (objects, valueKey) => {
    return objects.reduce((acc, cur) => ({ ...acc, [cur.key]: cur[valueKey] }), {})
};

export const getArrayByArrayOfObjects = (objects, valueKey) => {
    return objects.map(item => item[valueKey]);
};

export const randomId = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
};

export const checkMediaQuery = (maxWidth) => {
    return window.matchMedia(`(max-width: ${maxWidth}px)`).matches;
};

export const getCurrentRoute = () => {
    return window.location.pathname;
};

export const resetUserStorage = () => {
    localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.USER_ID);
    localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.USER_EMAIL);
    localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.ANSWER_ID);
    localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);
    localStorage.removeItem(constants.LOCAL_STORAGE.KEYS.PLAYER_SESSION);
};
