import React, { Component } from 'react'
import { FormattedMessage } from "react-intl";
import PropTypes from 'prop-types'
import classes from './styles.module.scss';
import Backdrop from '../Backdrop';

class Modal extends Component {

    constructor(props) {
        super(props);

        this.setReferenceToModal = this.setReferenceToModal.bind(this);
        this.handleClickOutsideModal = this.handleClickOutsideModal.bind(this);
    }

    componentDidMount(){
        document.addEventListener('mousedown', this.handleClickOutsideModal);
    }

    setReferenceToModal(node) {
        this.wrapperRefModal = node;
    }

    componentWillUnmount(){
        document.removeEventListener('mousedown', this.handleClickOutsideModal);
    }


    handleClickOutsideModal(event) {
        if (this.wrapperRefModal && !this.wrapperRefModal.contains(event.target)) {

            this.props.onModalCloseClicked();
        }
    }

    render() {

        const { children, onModalCloseClicked, showCloseButton } = this.props;

        return (
            <Backdrop show={true} clicked={this.handleClickOutsideModal}>
                <div className={classes.Modal} ref={this.setReferenceToModal}>
                    <div className={classes["Modal-header"]}>
                        {
                            showCloseButton &&
                            <button className={classes["Modal-close"]} onClick={onModalCloseClicked}>
                                <FormattedMessage id="general.close" />
                            </button>
                        }
                    </div>
                    <div className={classes["Modal-content"]}>
                        {
                            children
                        }
                    </div>
                </div>
            </Backdrop>
        )
    }
}

Modal.propTypes = {
    showCloseButton: PropTypes.bool,
    children: PropTypes.node,
    onModalCloseClicked: PropTypes.func,
};

Modal.defaultProps = {
    showCloseButton: true,
};

export default Modal
