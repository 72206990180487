// action types
import * as actionTypes from './actionTypes';

// action creators
export function addAnswer(answer) {
    return { type: actionTypes.ADD_ANSWER, answer }
}

export function addAnswerSuccess(answer) {
    return { type: actionTypes.ADD_ANSWER_SUCCESS, answer }
}

export function addAnswerFail(error) {
    return { type: actionTypes.ADD_ANSWER_FAIL, error }
}

export function updateAnswer(answer) {
    return { type: actionTypes.UPDATE_ANSWER, answer }
}

export function updateAnswerSuccess(answer) {
    return { type: actionTypes.UPDATE_ANSWER_SUCCESS, answer }
}

export function updateAnswerFail(error) {
    return { type: actionTypes.UPDATE_ANSWER_FAIL, error }
}

export function updateAnswerByPlayerToken(answer) {
    return { type: actionTypes.UPDATE_ANSWER_BY_PLAYER_TOKEN, answer }
}

export function updateAnswerByPlayerTokenSuccess(answer) {
    return { type: actionTypes.UPDATE_ANSWER_BY_PLAYER_TOKEN_SUCCESS, answer }
}

export function updateAnswerByPlayerTokenFail(error) {
    return { type: actionTypes.UPDATE_ANSWER_BY_PLAYER_TOKEN_FAIL, error }
}

export function fetchAnswer(userId) {
    return { type: actionTypes.FETCH_ANSWER, userId }
}

export function fetchAnswerSuccess(answer) {
    return { type: actionTypes.FETCH_ANSWER_SUCCESS, answer }
}

export function fetchAnswerFail(error) {
    return { type: actionTypes.FETCH_ANSWER_FAIL, error }
}

export function fetchAnswerByPlayerToken(playerToken) {
    return { type: actionTypes.FETCH_ANSWER_BY_PLAYER_TOKEN, playerToken }
}

export function fetchAnswerByPlayerTokenSuccess(answer) {
    return { type: actionTypes.FETCH_ANSWER_BY_PLAYER_TOKEN_SUCCESS, answer }
}

export function fetchAnswerByPlayerTokenFail(error) {
    return { type: actionTypes.FETCH_ANSWER_BY_PLAYER_TOKEN_FAIL, error }
}

export function fetchAnswers() {
    return { type: actionTypes.FETCH_ANSWERS }
}

export function fetchAnswersSuccess(answers) {
    return { type: actionTypes.FETCH_ANSWERS_SUCCESS, answers }
}

export function fetchAnswersFail(error) {
    return { type: actionTypes.FETCH_ANSWERS_FAIL, error }
}

export function deleteAnswerByPlayerToken(playerToken) {
    return { type: actionTypes.DELETE_ANSWER_BY_PLAYER_TOKEN, playerToken }
}

export function deleteAnswerByPlayerTokenSuccess(player) {
    return { type: actionTypes.DELETE_ANSWER_BY_PLAYER_TOKEN_SUCCESS, player  }
}

export function deleteAnswerByPlayerTokenFail(error) {
    return { type: actionTypes.DELETE_ANSWER_BY_PLAYER_TOKEN_FAIL, error }
}
