import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import PropTypes from 'prop-types';
import classes from './styles.module.scss';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
//import minusIcon from '../../../assets/icons/minus.svg';
//import plusIcon from '../../../assets/icons/plus.svg';
import eyeIcon from '../../../assets/icons/eye.svg';
import eyeDisabledIcon from '../../../assets/icons/eye-disabled.svg';
import Question from "../../Question";
import {checkMediaQuery} from "../../../shared/helpers";

// Powered by https://codesandbox.io/s/ql08j35j3q

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

//const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    cursor: 'default',
    //padding: grid * 2,
    //margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    //background: isDragging ? 'lightgreen' : 'grey',

    // styles we need to apply on draggables
    ...draggableStyle
});

/*
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
    width: 300
});
*/

let mobile = false;
let itemsListWidth = '300px';
let selectedItemsListWidth = '300px';
let paddingSelectedItemList = '25px 20px';

if(checkMediaQuery(1080)) {
    itemsListWidth = '275px';
    selectedItemsListWidth = '160px';
    paddingSelectedItemList = '15px 10px';
    mobile = true;
} else {
    mobile = false;

    if(checkMediaQuery(1280)) {
        itemsListWidth = '300px';
        selectedItemsListWidth = '250px';
        paddingSelectedItemList = '15px 10px';
    }
}

const getItemsListStyle = isDraggingOver => ({
    background: 'transparent',
    //display: 'flex',
    //flexFlow: 'column',
    //justifyContent: 'space-between',
    padding: 0,
    width: itemsListWidth,
    margin: '0 10px 0 0',
    boxSizing: 'border-box',
});

const getSelectedListStyle = isDraggingOver => ({
    background: '#08274a',
    borderRadius: '3px',
    padding: paddingSelectedItemList,
    width: selectedItemsListWidth,
    boxSizing: 'border-box',
    minHeight: '637px',
    maxHeight: '680px',
    zIndex: 50
});

class DragAndDrop extends Component {
    state = {
        items: this.props.items,
        selected: [],
        empty: this.props.items,
    };

    /**
     * A semi-generic way to handle multiple lists. Matches
     * the IDs of the droppable container to the names of the
     * source arrays stored in the state.
     */
    id2List = {
        droppable: 'items',
        droppable2: 'selected'
    };

    getList = id => this.state[this.id2List[id]];

    onToggleDescription = ev => {
        const index = parseInt(ev.target.getAttribute('data-index'));

        let items = [...this.state.items];

        items = items.map((item, ix) => {
            return { ...item, showDescription: ix === index && !items[index].showDescription }
        });

        this.setState({
            items
        });
    };

    onDragStart = () => {
        const items = [...this.state.items];

        items.map(item => item.showDescription = false);

        this.setState({ items });
    };

    onDragEnd = result => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
            );

            let state = { items };

            if (source.droppableId === 'droppable2') {
                state = { selected: items };
            }

            this.setState(state, () => {
                this.props.changedSelected(this.state.selected)
            });
        } else {
            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
            );

            this.setState({
                items: result.droppable,
                selected: result.droppable2,
                empty: result.droppable
            }, () => {
                this.props.changedSelected(this.state.selected)
            });
        }
    };

    // Normally you would want to split things out into separate components.
    // But in this example everything is just done in one place for simplicity
    render() {
        const { titles } = this.props;

        const questionStyles = {
            padding: '0 5px',
        };

        return (
            <div className={classes.DragAndDrop}>
                <DragDropContext
                    onDragStart={this.onDragStart}
                    onDragEnd={this.onDragEnd}>
                    <div>
                        {
                            titles[0] &&
                                <div>
                                    <Question
                                        styles={questionStyles}
                                        title={<FormattedMessage id={titles[0]}/>}
                                        titleStyles={{ margin: '0 0 5px 0' }} />
                                        <div className={classes["DragAndDrop-subTitle"]}>
                                            <FormattedMessage id="company.question.press"/>
                                            <img style={{ margin: '0 5px' }} src={eyeDisabledIcon} alt="legend" />
                                            <FormattedMessage id="company.question.more"/>
                                        </div>
                                </div>
                        }
                        <Droppable droppableId="droppable">
                            {
                                (provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={getItemsListStyle(snapshot.isDraggingOver)}>
                                        {
                                            this.state.items.map((item, index) => (
                                                <Draggable
                                                    key={item.id}
                                                    draggableId={item.id}
                                                    index={index}>
                                                        {
                                                            (provided, snapshot) => (
                                                                <div
                                                                    className={classes.DragAndDropItem}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}>
                                                                    <div className={classes["DragAndDropItem-label"]}>
                                                                        { item.icon ? <img className={classes["DragAndDropItem-icon"]} src={item.icon} alt={item.label} /> : item.label }
                                                                    </div>
                                                                    <div className={classes["DragAndDropItem-content"]}>
                                                                        {
                                                                            item.title &&
                                                                                <label className={classes["DragAndDropItem-title"]}>{ item.title }</label>
                                                                        }
                                                                        {item.content}
                                                                    </div>
                                                                    <button
                                                                        onClick={this.onToggleDescription}
                                                                        data-index={index}
                                                                        className={[classes["DragAndDrop-action"], item.showDescription ? classes["is-active"] : ''].join(' ')}>
                                                                        <img style={{pointerEvents: 'none'}} src={eyeIcon} alt="plus" />
                                                                    </button>
                                                                    {
                                                                        item.showDescription &&
                                                                            <div className={classes["DragAndDropItem-arrow"]} />
                                                                    }
                                                                    {
                                                                        item.showDescription &&
                                                                            <FormattedMessage id={item.descriptionId || 'general.emptyMessage'} defaultMessage={''}>
                                                                                {
                                                                                    description =>
                                                                                        <div className={classes.DragAndDropModal}>
                                                                                            <div className={classes["DragAndDropModal-description"]}  dangerouslySetInnerHTML={{ __html: description }} />
                                                                                        </div>
                                                                                }
                                                                            </FormattedMessage>
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                </Draggable>
                                            ))
                                        }
                                        {
                                            provided.placeholder
                                        }
                                    </div>
                                )
                            }
                        </Droppable>
                    </div>
                    <div>
                        {
                            titles[1] &&
                                <Question
                                    styles={questionStyles}
                                    titleStyles={{ margin: '23px 0 16px' }}
                                    title={<FormattedMessage id={titles[1]}/>} />
                        }
                        <Droppable droppableId="droppable2">
                            {
                                (provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={getSelectedListStyle(snapshot.isDraggingOver)}>
                                        {
                                            this.state.selected.map((item, index) => (
                                                <Draggable
                                                    key={item.id}
                                                    draggableId={item.id}
                                                    index={index}>
                                                    {
                                                        (provided, snapshot) => (
                                                            <div
                                                                className={[classes.DragAndDropItem, item.showDescription ? '' : [classes["DragAndDropItem--level"]]].join(' ')}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}>
                                                                <div className={classes["DragAndDropItem-label"]}>
                                                                    { item.icon ? <img className={classes["DragAndDropItem-icon"]} src={item.icon} alt={item.label} /> : item.label }
                                                                </div>
                                                                <div className={classes["DragAndDropItem-content"]}>
                                                                    {
                                                                        item.title &&
                                                                            <label className={classes["DragAndDropItem-title"]} style={{ fontSize: mobile ? '14px' : '10px' }}>{ item.title }</label>
                                                                    }

                                                                    {
                                                                        !mobile && item.content
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </Draggable>
                                            ))
                                        }
                                        {
                                            this.state.empty.map((item, index) => (
                                                <div key={index} className={[classes.DragAndDropItem, classes["DragAndDropItem--empty"]].join(' ')} />
                                            ))
                                        }
                                        {
                                            provided.placeholder
                                        }
                                    </div>
                                )
                            }
                        </Droppable>
                    </div>
                </DragDropContext>
            </div>
        );
    }
}

DragAndDrop.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
    changedSelected: PropTypes.func,
    titles: PropTypes.array,
};

DragAndDrop.defaultProps = {
    titles: ['', ''],
};

export default DragAndDrop;
