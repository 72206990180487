import React, { Component } from 'react';
import Main from '../Main';
import Content from '../Content';

class Wrapper extends Component {

    state = {};

    render() {

        return (
            <Main>
                <Content />
            </Main>
        )
    }
}

export default Wrapper;
