import React from 'react';
import PropTypes from 'prop-types';
import classes from './styles.module.scss';

const adminInput = (props) => {
    const { reference, icon, disabled, changed, name, type, readOnly, value, placeholder, dark, styles } = props;

    const wrapperClasses = [classes.AdminInput, dark ? classes["AdminInput--dark"] : ''].join(' ');

    return (
        <div
            style={styles}
            className={wrapperClasses}>
            {
                icon &&
                    <img
                        className={[classes["AdminInput-icon"], value && value.trim() !== '' ? classes["AdminInput-icon--focused"] : ''].join(' ')}
                        alt={'icon'}
                        src={icon} />
            }
            <input
                ref={reference}
                disabled={disabled}
                name={name}
                type={type}
                onChange={changed}
                readOnly={readOnly}
                className={classes["AdminInput-control"]}
                placeholder={placeholder}
                autoComplete={'none'}
                value={value} />
        </div>
    );
};

adminInput.propTypes = {
    icon: PropTypes.string,
    styles: PropTypes.object,
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    changed: PropTypes.func.isRequired,
    reference: PropTypes.func,
    readOnly: PropTypes.bool,
    type: PropTypes.string,
    dark: PropTypes.bool,
};

export default adminInput;
