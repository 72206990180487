import React from 'react';
import classes from './styles.module.scss';
import PropTypes from "prop-types";

const button = (props) => {
    const { btnType, disabled, children, clicked, styles, icon, step, hoverEffect } = props;

    return (
        <button
            style={styles}
            className={[classes.Button, classes[`Button--${btnType}`], disabled ? classes["is-disabled"] : '', icon ? classes["Button--icon"] : '', hoverEffect ? classes["Button--effect"] : ''].join(' ')}
            disabled={disabled}
            type="button"
            onClick={clicked}>
            {
                icon &&
                    <img className={classes["Button-icon"]} src={ icon } alt="icon" />
            }
            { children }
            {
                step &&
                    <span className={classes["Button-step"]}>({ step })</span>
            }
        </button>

    )
};


button.propTypes = {
    btnType: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'danger', 'success']),
    disabled: PropTypes.bool,
    children: PropTypes.any,
    clicked: PropTypes.func,
    styles: PropTypes.object,
    icon: PropTypes.string,
    step: PropTypes.string,
    hoverEffect: PropTypes.bool,
};

button.defaultProps = {
    btnType: 'primary',
    hoverEffect: false,
};

export default button;
