import React from 'react';
import PropTypes from 'prop-types';
import classes from './styles.module.scss';
import AdminSelectItem from './AdminSelectItem'
import downIcon from '../../assets/icons/admin/arrow-down.svg';
import downLightIcon from '../../assets/icons/admin/arrow-down-light.svg';

const select = (props) => {
    const { reference, disabled, emptySelection, selected, options, name, clicked, itemClicked, styles, placeholder, showList, light } = props;

    const allOptions = options.map((item, i) => {
        return <AdminSelectItem
                    light={light}
                    key={i}
                    itemClicked={itemClicked}
                    inputName={name}
                    description={item['description']}
                    value={item['value']} />
    });

    return (
        <div style={styles}
             className={[classes.Select, light ? classes["Select--light"] : '', showList ? classes["is-open"] : '', disabled ? classes["is-disabled"] : ''].join(' ')}
             ref={reference}>
            <button
                className={classes["Select-button"]}
                onClick={clicked}
                disabled={disabled}
                placeholder={placeholder}
                data-name={name}
            >{ selected || emptySelection }</button>
            {
                light ?
                    <img className={classes["Select-arrow"]} src={downLightIcon} alt="down" /> :
                    <img className={classes["Select-arrow"]} src={downIcon} alt="down" />
            }
            <div className={[classes["Select-list"], showList ? classes["is-visible"] : ''].join(' ')}>{ allOptions }</div>
        </div>
    );
};

select.propTypes = {
    styles: PropTypes.object,
    emptySelection: PropTypes.object,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    showList: PropTypes.bool,
    clicked: PropTypes.func,
    itemClicked: PropTypes.func,
    reference: PropTypes.func,
    light: PropTypes.bool,
};

export default select;
