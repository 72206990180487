import React from 'react';
import { FormattedMessage } from "react-intl";
import PropTypes from 'prop-types';
import classes from './styles.module.scss';
import SelectItem from './SelectItem'
import downIcon from '../../../assets/icons/down.svg';

const select = (props) => {
    const { reference, disabled, value, items, name, clicked, itemClicked, styles, placeholder, showList } = props;

    let selected = 'Select...';

    const allOptions = items.map((item, i) => {
        if(item.value === value){
            selected = item.hasOwnProperty('descriptionId') ? <FormattedMessage id={item.descriptionId} /> : item.description;
        }

        return <SelectItem
                    key={i}
                    itemClicked={itemClicked}
                    inputName={name}
                    descriptionId={item['descriptionId']}
                    description={item['description']}
                    value={item['value']} />
    });

    return (
        <div style={styles}
             className={[classes.Select,  showList ? classes["is-open"] : '', disabled ? classes["is-disabled"] : ''].join(' ')}
             ref={reference}>
            <button
                className={classes["Select-button"]}
                onClick={clicked}
                disabled={disabled}
                placeholder={placeholder}
                data-name={name}
            >{ selected }</button>
            <img className={classes["Select-arrow"]} src={downIcon} alt="down" />
            <div className={[classes["Select-list"], showList ? classes["is-visible"] : ''].join(' ')}>{ allOptions }</div>
        </div>
    );
};

select.propTypes = {
    styles: PropTypes.object,
    items: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string,
    placeholder: PropTypes.string,
    showList: PropTypes.bool,
    value: PropTypes.string,
    clicked: PropTypes.func,
    itemClicked: PropTypes.func,
    reference: PropTypes.func,
};

export default select;
