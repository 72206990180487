import React from 'react';
import classes from './styles.module.scss';
import Logo from "../Logo";

const footer = (props) => {

    const { position } = props;

    const footerClass = [classes.Footer, position === 'static' ? classes["Footer--static"] : ''].join(' ');

    return (

        <div className={footerClass}>
            <Logo type="tertiary" />
        </div>
    );
};

export default footer;
