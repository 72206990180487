import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import * as moment from "moment";
import {fetchSessions, updateSession, deleteSession} from "../../store/actions/sessions";
import {connect} from "react-redux";
import { NavLink } from 'react-router-dom';
import classes from './styles.module.scss';
import * as routes from '../../shared/routes';
import AdminTable from "../AdminTable";
import Dialog from "../Dialog";
import plusIcon from '../../assets/icons/admin/plus.svg';
import editLightIcon from '../../assets/icons/admin/edit-light.svg';
import trashIcon from '../../assets/icons/admin/trash.svg';
import Button from "../UI/Button";
import AdminTableHeader from "../AdminTableHeader";

class Sessions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            sessionIdToRemove: null,
            sessionNameToRemove: null,
            sessions: {
                columns: [
                    {
                        name: <FormattedMessage id={'table.sessions.column.sessionName'}/>,
                        key: 'name',
                    },
                    {
                        name: <FormattedMessage id={'table.sessions.column.dateTime'}/>,
                        key: 'period',
                    },
                    {
                        name: <FormattedMessage id={'table.sessions.column.users'}/>,
                        key: 'playersCounter',
                    },
                    {
                        name: '',
                        key: 'actions',
                    }
                ],
                rows: []
            }
        };

        this.handleRemoveSessionClicked = this.handleRemoveSessionClicked.bind(this);
        this.handleToggleDialogVisibility = this.handleToggleDialogVisibility.bind(this);
        this.removeSession = this.removeSession.bind(this);
    }

    componentDidMount() {
        this.props.fetchSessions();
    }

    handleRemoveSessionClicked(id){
        const sessionToRemove = this.state.sessions.rows.filter(session => session.data['_id'] === id)[0];

        this.setState({
            sessionIdToRemove: id,
            sessionNameToRemove: sessionToRemove.hasOwnProperty('data') ? sessionToRemove.data.name : '',
            showDialog: true,
        });
    }

    removeSession(){
        const { sessionIdToRemove, sessions } = this.state;

        const sessionToRemove = sessions.rows.filter(session => session.data._id === sessionIdToRemove)[0];

        const data = {
            ...sessionToRemove,
            id: sessionIdToRemove,
            deleted: true,
        };

        this.props.updateSession(data);
    }

    handleEditSession(id){

        this.props.history.push(routes.ADMIN_EDIT_SESSION.replace(':sessionId', id))
    }

    handleToggleDialogVisibility() {
        let showDialog = !this.state.showDialog;

        this.setState({
            showDialog
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.sessions !== this.props.sessions){
            let sessions = {...this.state.sessions};

            sessions.rows = nextProps.sessions.map(session => {

                const period = `${moment(session.startDate).format('DD MMM').toUpperCase()} - ${moment(session.endDate).format('DD MMM').toUpperCase()}`;

                return {
                    data: {
                        ...session,
                        playersCounter: session.playersCounter,
                        period
                    },
                    actions: {
                        edit: {
                            func: () => this.handleEditSession(session._id),
                            icon: <img src={editLightIcon} alt={'edit'} />
                        },
                        remove: {
                            func: () => this.handleRemoveSessionClicked(session._id),
                            icon: <img src={trashIcon} alt={'remove'} />
                        },
                    }
                };
            });

            this.setState({
                sessions
            })
        }

        if(nextProps.updated !== this.props.updated && nextProps.updated){
            let sessions = {...this.state.sessions};
            let rows = [...sessions.rows];

            sessions.rows = rows.filter(session => session['data']['_id'] !== this.state.sessionIdToRemove);

            this.setState({
                sessions,
                sessionIdToRemove: null,
                sessionNameToRemove: null,
                showDialog: false,
            });
        }
    }

    render() {
        const { sessions, sessionNameToRemove, showDialog } = this.state;

        return (
            <div className={classes.Sessions}>
                <div
                    className={
                        [
                            classes["Sessions-content"],
                            classes["is-visible"],
                        ].join(' ')}>
                    <AdminTableHeader
                        title={ <FormattedMessage id={'list.sessions.title'}/>}>
                        <NavLink className={classes["Sessions-navLink"]} to={routes.ADMIN_NEW_SESSION}><img src={plusIcon} alt={'add'} /><FormattedMessage id={'button.session.add'}/></NavLink>
                    </AdminTableHeader>
                    <AdminTable
                        emptyMessage={<FormattedMessage id={'list.sessions.empty'}/>}
                        model={sessions} />
                </div>
                {
                    showDialog &&
                        <Dialog
                            width={390}
                            allowScroll={false}
                            title={<FormattedMessage id={'session.delete.title'}/>}
                            onDialogCloseClicked={this.handleToggleDialogVisibility}
                            showCloseButton={true}>
                            <div className={classes.Session}>
                                <h3 className={classes["Session-title"]}>{ `${sessionNameToRemove}?` }</h3>
                                <small className={classes["Session-subtitle"]}>
                                    <FormattedMessage id={'session.delete.subtitle'}/>
                                </small>
                                <div className={classes["Session-footer"]}>
                                    <Button
                                        btnType={'tertiary'}
                                        hoverEffect={true}
                                        clicked={this.handleToggleDialogVisibility}>
                                        <FormattedMessage id="session.delete.undo"/>
                                    </Button>
                                    <Button
                                        btnType={'danger'}
                                        hoverEffect={true}
                                        clicked={this.removeSession}>
                                        <FormattedMessage id="session.delete.confirm"/>
                                    </Button>
                                </div>
                            </div>
                        </Dialog>
                }
            </div>
        )
    }
}



const mapStateToProps = (state) => {
    return {
        sessions: state.sessions.sessions,
        error: state.sessions.error,
        loading: state.sessions.loading,
        removed: state.sessions.removed,
        updated: state.sessions.updated,
    }
};

const mapDispatchToProps = {
    fetchSessions,
    deleteSession,
    updateSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sessions);
