import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { FormattedMessage } from 'react-intl';
import classes from './style.module.scss';
import './style.scss';

const EDITOR_OPTIONS = {
    options: ['inline', 'history'],
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
        bold: { className: classes['EditorTextArea-button'] },
        italic: { className: classes['EditorTextArea-button'] },
        underline: { className: classes['EditorTextArea-button'] },
        strikethrough: { className: classes['EditorTextArea-button'] }
    }
};

class EditorTextArea extends Component {
	constructor(props) {
		super(props);

		const html = props.value ? props.value : '<p></p>';
		const contentBlock = htmlToDraft(html);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			const editorState = EditorState.createWithContent(contentState);
			this.state = {
				editorState,
			};
		}
	}

	onEditorStateChange = (editorState) => {
		this.setState({
			editorState,
		}, this.props.onChange(draftToHtml(convertToRaw(editorState.getCurrentContent()))));
	};

	render() {
        const {
            toolbarHidden,
            placeholderId,
            styles,
            onBlur,
        } = this.props;

        const { editorState } = this.state;

        return (
            <div className={classes.EditorTextArea}>
                <FormattedMessage
                    id={placeholderId || 'general.emptyMessage'}
                    defaultMessage={''}>
                        {
                            (placeholder) => (
                                <div>
                                    <Editor
                                        wrapperStyle={styles}
                                        toolbarHidden={toolbarHidden}
                                        editorState={editorState}
                                        toolbarClassName={classes['EditorTextArea-toolbar']}
                                        wrapperClassName={classes['EditorTextArea-wrapper']}
                                        editorClassName={
                                            toolbarHidden
                                                ? classes['EditorTextArea-editor']
                                                : [
                                                        classes['EditorTextArea-editor'],
                                                        classes['EditorTextArea-editor--withToolbar']
                                                    ].join(' ')
                                        }
                                        toolbar={EDITOR_OPTIONS}
                                        onEditorStateChange={this.onEditorStateChange}
                                        onBlur={onBlur}
                                    />
                                    <textarea
                                        style={{width: '1px', height: '1px', visibility: 'hidden'}}
                                        disabled
                                        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))} />
                                </div>
                            )
                        }
                </FormattedMessage>
            </div>
        );
  }
}

export default EditorTextArea;
