import React from 'react';
import PropTypes from 'prop-types';
import classes from './styles.module.scss';

const adminBox = (props) => {
    const { children, styles, title } = props;

    return (
        <div className={classes.AdminBox} style={styles}>
            {
                title &&
                    <h3 className={classes["AdminBox-title"]}>{ title }</h3>
            }
            <div className={classes["AdminBox-content"]}>
                {
                    children
                }
            </div>
        </div>
    )
};

adminBox.propTypes = {
    styles: PropTypes.object,
    children: PropTypes.node.isRequired,
    title: PropTypes.object,
};

export default adminBox;
