import React from 'react';
import PropTypes from 'prop-types';
import bannerLogo from '../../assets/images/online-business-challenge.png';
import classes from './styles.module.scss';

const banner = (props) => (
    <div className={classes.Banner} style={props.styles}>
        <img className={classes["Banner-image"]} src={bannerLogo} alt="Online Business Challenge" />
    </div>
);

banner.propTypes = {
    styles: PropTypes.object
};

export default banner;
