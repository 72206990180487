import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import scrollIntoView from "scroll-into-view-if-needed";
import constants from "../../../shared/constants";
import { connect } from 'react-redux';
import * as routes from "../../../shared/routes";
import {fetchAnswerByPlayerToken, updateAnswerByPlayerToken} from "../../../store/actions/answers";
import { countOccurrencesInArrayOfObject, getObjectByArray } from "../../../shared/helpers";
import classes from './styles.module.scss';
import Button from "../../../components/UI/Button";
import Question from "../../../components/Question";
import Separator from "../../../components/UI/Separator";
import Checkbox from "../../../components/UI/Checkbox";
import FlexBox from "../../FlexBox";
import Square from "../../../components/UI/Square";
import nextButtonIcon from '../../../assets/icons/next.svg';
import Textarea from "../../../components/UI/Textarea";
import Feedback from "../../../components/Feedback";
import Sidebar from "../../../components/Sidebar";
import Steps from "../";
import TwoColumnsWrapper from "../../TwoColumnsWrapper";
import Footer from "../../../components/Footer";

class StepCountry extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: '',
            animate: false,
            supportAnimation: constants.SUPPORT_ANIMATION,
            dbCountry: null,
            checkboxes: [
                {
                    label: <FormattedMessage id="country.checkbox.attractive"/>,
                    key: 'attractive',
                    score: 0,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.weather"/>,
                    key: 'weather',
                    score: 0,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.consumptionGrowth"/>,
                    key: 'consumptionGrowth',
                    score: 1,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.economicGrowth"/>,
                    key: 'economicGrowth',
                    score: 2,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.multiculturalContext"/>,
                    key: 'multiculturalContext',
                    score: 2,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.marketDiversification"/>,
                    key: 'marketDiversification',
                    score: 1,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.visaLaws"/>,
                    key: 'visaLaws',
                    score: 2,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.jobLanguage"/>,
                    key: 'jobLanguage',
                    score: 1,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.numberOfEnrolledStudents"/>,
                    key: 'numberOfEnrolledStudents',
                    score: 1,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.exchangeAgreements"/>,
                    key: 'exchangeAgreements',
                    score: 0,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.inclusionsPolicies"/>,
                    key: 'inclusionsPolicies',
                    score: 1,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.workLifeBalance"/>,
                    key: 'workLifeBalance',
                    score: 0,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.alumniPresence"/>,
                    key: 'alumniPresence',
                    score: 2,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.relationsWithItaly"/>,
                    key: 'relationsWithItaly',
                    score: 0,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.studentInterest"/>,
                    key: 'studentInterest',
                    score: 1,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.healthRisk"/>,
                    key: 'healthRisk',
                    score: 2,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.politicalStability"/>,
                    key: 'politicalStability',
                    score: 2,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.sectorAverageSalary"/>,
                    key: 'sectorAverageSalary',
                    score: 0,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.corruptionRate"/>,
                    key: 'corruptionRate',
                    score: 0,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.unemploymentRate"/>,
                    key: 'unemploymentRate',
                    score: 2,
                    checked: false,
                },
                {
                    label: <FormattedMessage id="country.checkbox.birthRate"/>,
                    key: 'birthRate',
                    score: 0,
                    checked: false,
                },
            ],
            numberOfSelections: 0,
            showConsiderations: false,
            considerations: '',
            score: 0,
        };

        this.onClickNext = this.onClickNext.bind(this);
        this.onCheckOption = this.onCheckOption.bind(this);
        this.onChangeConsiderations = this.onChangeConsiderations.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0,0);

        const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);

        if(token !== null) {
            this.props.fetchAnswerByPlayerToken(token);
        } else {
            this.props.history.push(routes.ERROR);
        }
    }

    onCheckOption(ev){
        const index = ev.target.getAttribute('data-index');
        let currentScore = this.state.score;

        let checkboxes = [...this.state.checkboxes];

        checkboxes[index].checked = !checkboxes[index].checked;
        currentScore += checkboxes[index].checked ? checkboxes[index].score : checkboxes[index].score*-1;

        const numberOfSelections = countOccurrencesInArrayOfObject(checkboxes, 'checked', true);

        this.setState({ checkboxes, numberOfSelections, score: currentScore });
    }

    onChangeConsiderations(ev) {
        this.setState({
            considerations: ev.target.value,
        });
    };

    updateAnswerData(data){
        const playerToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);

        if(playerToken !== null) {
            const object = {
                ...data,
                playerToken
            };

            this.props.updateAnswerByPlayerToken(object);
        } else {
            this.setState({ error: 'error.game.removed' }, () => {
                setTimeout(() => {
                    this.props.history.push(routes.ERROR);
                }, 3000);
            });
        }
    }

    onClickNext(ev){
        ev.preventDefault();

        const { checkboxes, considerations, score, dbCountry, } = this.state;

        let country = {
            ...getObjectByArray(checkboxes, 'checked'),
            considerations,
            score,
        };

        if(dbCountry !== null){
            country = {
                ...country,
                ...dbCountry,
                considerations,
                score: dbCountry.score,
            }
        }

        const data = {
            country,
            progress: {
                step: 'country',
                index: this.state.showConsiderations ? 2 : 1,
            },
        };

        this.updateAnswerData(data);
    }

    componentWillReceiveProps(nextProps, nextState){
        if(this.props.answer !== nextProps.answer &&
            nextProps.answer !== null){

            const { progress: { step, index }, country } = nextProps.answer;
            //console.log(step, index);
            const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);

            if(step !== 'role' && step !== 'country') {
                this.props.history.push(`/challenge/${token}/${step}`);
            } else {
                if(step === 'country') {
                    if(index < 2){
                        this.setState({
                            dbCountry: country,
                            showConsiderations: index === 1,
                        })
                    } else {
                        this.props.history.push(routes.SEARCHING_COUNTRIES.replace(':token', token));
                    }
                }
            }
        }

        if(this.props.isStored !== nextProps.isStored){
            if(nextProps.isStored){
                if(!this.state.showConsiderations){
                    const wrapper = document.getElementById('Wrapper');

                    this.setState({ animate: false }, () => {
                        scrollIntoView(wrapper, { block: 'start', behavior: 'smooth' });

                        this.setState({
                            animate: true,
                            showConsiderations: true,
                        });
                    });
                } else {
                    const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);
                    this.props.history.push(routes.SEARCHING_COUNTRIES.replace(':token', token));
                }
            }
        }

        if(this.props.error !== nextProps.error){
            setTimeout(() => {
                localStorage.clear();
                this.props.history.push(routes.ERROR);
            }, 5000);
        }
    }

    render() {
        const { animate, checkboxes, numberOfSelections, showConsiderations, considerations, error } = this.state;

        const checkboxStyles = {
            width: '158px',
            margin: '0 10px 30px 0'
        };

        const checkboxesTpl = checkboxes.map((checkbox, index) => <Checkbox index={index} clicked={this.onCheckOption} styles={checkboxStyles} key={index} checked={checkbox.checked}>{ checkbox.label }</Checkbox>);

        const questionStyles = {
            padding: '0 5px',
            margin: '0 0 30px'
        };

        const questionDescriptionStyles = {
            margin: '0 10px',
            fontSize: '16px',
            fontWeight: '400',
            color: '#FFF'
        };

        const nextButtonStyles = {
            width: '130px',
            margin: '0',
            padding: '10px 15px'
        };

        const contentClass = {
            margin: 0,
        };

        const disabledNext = (!showConsiderations && (numberOfSelections === 0 || numberOfSelections > 7)) || (showConsiderations && considerations.trim() === '');

        return (
            <TwoColumnsWrapper>
                <Sidebar
                    currentStep={3}
                    stepIndex={0}/>

                <Steps twoColumns={true} transition={animate}>
                    <div id="Wrapper" className={[classes.Step, classes["Step--withSidebar"]].join(' ')} style={{padding: '60px 10px 0'}}>
                        {
                            !showConsiderations &&
                                <Question
                                    styles={questionStyles}
                                    title={<FormattedMessage id="country.question.title"/>}
                                    description={<FormattedMessage id="country.question.description"/>} />
                        }
                        {
                            !showConsiderations &&
                                <Separator/>
                        }
                        {
                            !showConsiderations ?
                                <div
                                    className={
                                        [
                                            classes["Step-content"],
                                            classes["is-visible"],
                                        ].join(' ')}
                                    style={contentClass}>
                                    <FlexBox
                                        styles={{justifyContent: 'space-between', padding: '40px 0 60px'}}>
                                        { checkboxesTpl }
                                    </FlexBox>
                                </div> :
                                <div
                                    className={
                                        [
                                            classes["Step-content"],
                                            classes["is-visible"],
                                        ].join(' ')}
                                    style={{...contentClass, padding: '30px 0'}}>
                                    <Question
                                        styles={{...questionStyles, maxWidth: '579px'}}
                                        titleStyles={questionDescriptionStyles}
                                        title={<FormattedMessage id="country.question.considerations"/>} />
                                    <Textarea
                                        changed={this.onChangeConsiderations}
                                        value={considerations}
                                        placeholderId="country.placeholder.considerations"
                                        styles={{margin: '0 10px 20px'}} />
                                </div>
                        }
                        {
                            (this.props.error || error) &&
                                <Feedback type="error" styles={{ margin: '40px auto'}}>
                                    { error ? <FormattedMessage id={error}/> : <FormattedMessage id="error.generic"/> }
                                </Feedback>
                        }
                        <Separator />
                        <div className={classes["Step-footer"]}>
                            {
                                !showConsiderations &&
                                    <Square large>{ numberOfSelections }</Square>
                            }
                            <Question
                                titleStyles={{fontSize: '16px', margin: 0 }}
                                title={<FormattedMessage id="general.noBack"/>} />
                            <Button
                                hoverEffect={true}
                                icon={nextButtonIcon}
                                disabled={disabledNext}
                                styles={nextButtonStyles}
                                clicked={this.onClickNext}>
                                <FormattedMessage id="general.next"/>
                            </Button>
                        </div>
                    </div>
                </Steps>
                <Footer />
            </TwoColumnsWrapper>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        answer: state.answers.selected,
        isLoading: state.answers.loading,
        isSaving: state.answers.saving,
        isStored: state.answers.stored,
        error: state.answers.error
    }
};

const mapDispatchToProps = {
    fetchAnswerByPlayerToken,
    updateAnswerByPlayerToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepCountry);
