import React from 'react';
import PropTypes from 'prop-types';
import classes from './styles.module.scss';

const twoColumnsWrapper = (props) => <div style={props.styles} className={classes.TwoColumnsWrapper}>{props.children}</div>;

twoColumnsWrapper.propTypes = {
    styles: PropTypes.object
};

export default twoColumnsWrapper;
