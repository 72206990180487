import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {fetchAnswerByPlayerToken, updateAnswerByPlayerToken} from "../../../store/actions/answers";
import {connect} from "react-redux";
import * as routes from "../../../shared/routes";
import classes from './styles.module.scss';
import Banner from "../../../components/Banner";
import Title from "../../../components/Title";
import Paragraph from "../../../components/Paragraph";
import Button from "../../../components/UI/Button";
import Circle from "../../../components/UI/Circle";
import Loader from "../../../components/UI/Loader";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import constants from "../../../shared/constants";
import yellowBookImage from "../../../assets/images/yellow_book.png";
import manImage from "../../../assets/images/man.png";
import womanImage from "../../../assets/images/woman.png";
import roleTopRightImage from "../../../assets/images/general_top_right.png";
import roleBottomLeftImage from "../../../assets/images/role_bottom_left.png";

class StepRole extends Component {
    constructor(props) {
        super(props);

        this.state = {
            animate: false,
            startedAt: null,
        };

        this.onClickStart = this.onClickStart.bind(this);
    }

    componentDidMount(){
        window.scrollTo(0, 0);

        const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);

        if(token !== null) {
            this.props.fetchAnswerByPlayerToken(token);
        } else {
            this.props.history.push(routes.ERROR);
        }
    }

    onClickStart(ev){
        ev.preventDefault();

        //console.log('StartedAt:', this.state.startedAt);

        if(!this.state.startedAt){
            const data = {
                startedAt: new Date()
            };

            this.updateAnswerData(data);
        } else {
            this.handleRedirect();
        }
    }

    handleRedirect() {
        this.setState({animate: true}, () => {
            setTimeout(() => {
                const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);

                if (token) {
                    this.props.history.push(routes.COUNTRY.replace(':token', token));
                } else {
                    this.props.history.push(routes.ERROR);
                }
            }, 500);
        });
    }

    updateAnswerData(data){
        const playerToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);

        if(playerToken !== null) {
            const object = {
                ...data,
                playerToken
            };

            this.props.updateAnswerByPlayerToken(object);
        } else {
            this.setState({ error: 'error.game.removed' }, () => {
                setTimeout(() => {
                    this.props.history.push(routes.ERROR);
                }, 3000);
            });
        }
    }

    componentWillReceiveProps(nextProps, nextState){
        if(this.props.answer !== nextProps.answer &&
            nextProps.answer !== null){

            if(nextProps.answer.hasOwnProperty('startedAt')){
                this.setState({
                    startedAt: nextProps.answer.startedAt,
                });
            }
        }

        if(this.props.isStored !== nextProps.isStored){
            if(nextProps.isStored){
                this.handleRedirect()
            }
        }

        if(this.props.error !== nextProps.error){
            setTimeout(() => {
                localStorage.clear();
                this.props.history.push(routes.ERROR);
            }, 5000);
        }
    }

    render() {

        const { animate } = this.state;

        const bannerStyles = {
            transform: 'translateY(-65px)'
        };
        const titleStyles = {
            margin: '50px 0 70px'
        };
        const firstParagraphStyles = {
            margin: '30px auto',
            maxWidth: '440px',
            padding: '0'
        };
        const secondParagraphStyles = {
            margin: '0 auto 100px',
            maxWidth: '440px',
            padding: '0'
        };
        const startButtonStyles = {
            width: '320px',
            margin: '0 auto 100px'
        };

        const whiteCircleStyles = {
            position: 'absolute',
            top: '75px',
            right: '520px'
        };

        const blueCircleStyles = {
            position: 'absolute',
            top: '65px',
            right: '170px'
        };

        const orangeCircleStyles = {
            position: 'absolute',
            bottom: '300px',
            left: '80px'
        };

        const loaderStyles = {
            visibility: animate ? 'visible' : 'hidden',
            margin: '0 auto 100px'
        };

        return (

            <div className={classes.Step} id="Wrapper">
                <Header />
                <div
                    className={
                        [
                            classes["Step-content"],
                            classes["is-visible"],
                        ].join(' ')}>
                    <div>
                        <Banner styles={bannerStyles} />
                        <Title
                            alignment="center"
                            styles={titleStyles}>
                            <FormattedMessage id="role.title"/>
                        </Title>
                        <Paragraph
                            alignment="center"
                            styles={firstParagraphStyles}
                            textId="role.description1"/>

                        <Paragraph
                            alignment="center"
                            styles={secondParagraphStyles}
                            textId="role.description2"/>

                        <Button
                            hoverEffect={true}
                            styles={startButtonStyles}
                            clicked={this.onClickStart}>
                            <FormattedMessage id="general.startChallenge"/>
                        </Button>
                        <Loader styles={loaderStyles} />
                    </div>
                    <div>
                        <Circle styles={whiteCircleStyles} diameter={10} backgroundColor={'#FFF'} />
                        <Circle styles={blueCircleStyles} diameter={23} backgroundColor={'#56f6ff'} />
                        <Circle styles={orangeCircleStyles} diameter={23} backgroundColor={'#fbb03b'} />
                        <img
                            className={[classes["Step-background"], classes["Step-background--role"], classes["Step-background--topRight"]].join(' ')} src={roleTopRightImage} alt="background top right"/>
                        <img
                            className={[classes["Step-background"], classes["Step-background--role"], classes["Step-background--middleRight"]].join(' ')} src={manImage} alt="man"/>
                        <img
                            className={[classes["Step-background"], classes["Step-background--role"], classes["Step-background--bottomRight"]].join(' ')} src={yellowBookImage} alt="yellow book"/>
                        <img
                            className={[classes["Step-background"], classes["Step-background--role"], classes["Step-background--middleRight"]].join(' ')} src={manImage} alt="man"/>
                        <img
                            className={[classes["Step-background"], classes["Step-background--role"], classes["Step-background--middleLeft"]].join(' ')} src={womanImage} alt="woman"/>
                        <img
                            className={[classes["Step-background"], classes["Step-background--role"], classes["Step-background--bottomLeft"]].join(' ')} src={roleBottomLeftImage} alt="background bottom left"/>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        answer: state.answers.selected,
        isLoading: state.answers.loading,
        isSaving: state.answers.saving,
        isStored: state.answers.stored,
        error: state.answers.error
    }
};

const mapDispatchToProps = {
    fetchAnswerByPlayerToken,
    updateAnswerByPlayerToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepRole);
