import React, { Component } from 'react';
import classes from './styles.module.scss';
import {FormattedMessage, FormattedHTMLMessage} from "react-intl";
import XLSX from 'xlsx';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './styles.scss';
import {checkValidEmail, randomId} from "../../shared/helpers";
import { NavLink } from 'react-router-dom';
import * as moment from 'moment';
import {fetchSession, fetchSessionPlayers, addSession, updateSession, fetchSessionAnswers} from "../../store/actions/sessions";
import {addPlayer, deletePlayer, updatePlayerByToken} from "../../store/actions/players";
import {sendInvitations} from "../../store/actions/invitations";
import {deleteAnswerByPlayerToken} from "../../store/actions/answers";
import {connect} from "react-redux";
import Button from "../UI/Button";
import Dialog from "../Dialog";
import * as routes from "../../shared/routes";
import backIcon from '../../assets/icons/admin/arrow-left.svg';
import AdminBox from '../AdminBox';
import AdminSelect from '../AdminSelect';
import AdminTable from "../AdminTable";
import AdminTableHeader from "../AdminTableHeader";
import AdminInput from "../AdminInput";
import AdminTextarea from "../AdminTextarea";
import IconLabel from '../IconLabel';
import Loader from "../UI/Loader";
import Legend from "../Legend";
import crossIcon from "../../assets/icons/admin/cross.svg";
import trashIcon from "../../assets/icons/admin/trash.svg";
import checkIcon from "../../assets/icons/admin/check.svg";
import mailIcon from "../../assets/icons/admin/mail.svg";
import mailLightIcon from "../../assets/icons/admin/mail-light.svg";
import plusLightIcon from "../../assets/icons/admin/plus-light.svg";
import resetIcon from "../../assets/icons/admin/reset.svg";
import searchIcon from "../../assets/icons/admin/search.svg";
import constants from "../../shared/constants";

const LEGEND = '<p>Per inserire data, link e durata della competizione basta utilizzare, nel paragrafo, le espressioni seguenti:</p><ul><li>{email} -> per inserire l\'email del mittente</li><li>{link} -> per inserire il <strong>link alla sessione</strong></li><li>{duration} -> per la <strong>durata sessione</strong></li><li>{date} -> per indicare la <strong>fine sessione</strong></li></ul>';

const DURATION_OPTIONS = [
    {
        description: <FormattedMessage id={'session.minutes.30'}/>,
        value: 30,
    },
    {
        description: <FormattedMessage id={'session.minutes.60'}/>,
        value: 60,
    },
    {
        description: <FormattedMessage id={'session.minutes.90'}/>,
        value: 90,
    },
    {
        description: <FormattedMessage id={'session.minutes.120'}/>,
        value: 120,
    },
];

const SELECT_TO_OPTIONS = [
    {
        description: <FormattedMessage id={'select.option.completed'}/>,
        value: 'completed',
    },
    {
        description: <FormattedMessage id={'select.option.notCompleted'}/>,
        value: 'not-completed',
    },
];

/*
<a href='${path}/challenge/${token}'>clicca qui</a>
90
${moment(this.state.session.endDate).format('DD MMMM YYYY')}
 */

const DEFAULT_EMAIL_MESSAGE = `Gentilissima/o,<br><br>ti scriviamo in riferimento allo svolgimento dell’ONLINE BUSINESS CHALLENGE, come da mail precedentemente ricevuta.<br><br>La challenge online è un game pensato per vestire i panni di un nostro collega proponendoti un caso di progettazione di una missione internazionale destinata al placement di nostri studenti e laureati.<br><br>Ecco alcuni consigli da ricordare per lo svolgimento efficace del game:<br><ul style="list-style-type: decimal"><li>leggi attentamente le istruzioni prima dello svolgimento di ognuna delle 4 fasi;</li><li>rispondi a tutte le domande: non sono previste penalità per risposte sbagliate e vengono conteggiate solo le risposte corrette;</li><li>usa tutti i {duration}’ a tua disposizione per completare il game;</li><li>controlla di avere dato le risposte corrette in ogni singola fase: non è possibile modificare le risposte date dopo il passaggio alla fase successiva;</li><li>trova lo spazio e il tempo adeguati per svolgere il game: avrai infatti una sola possibilità di accesso per completarlo;</li></ul>Per completare la challenge devi avere un pc fisso/portatile.<br><br>Per accedere al game inserisci <u>i tuoi dati personali e l'indirizzo mail a cui ti stiamo contattando.</u><br><br>Se sei pronto/a, {link}!<br><br>Qualora dovessi avere problemi con l'accesso, ti preghiamo di scriverci a {email} e prontamente cercheremo di risolvere il problema.<br><br>Ricordati che la data ultima per la compilazione del game è {date}<br><br><br>Un cordiale saluto,<br>-- <br>Talent Acquisition Team <br>Funzione Risorse Umane <br>Università Bocconi`;

class Session extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showResetDialog: false,
            sessionFeedback: null,
            showAddDialog: false,
            showInvitationsDialog: false,
            showRemoveDialog: false,
            focusedDateInput: null,
            sessionId: null,
            session: {
                name: '',
                startDate: null,
                endDate: null,
                minutes: 90,
            },
            duration: {
                selected: <FormattedMessage id={'session.minutes.90'}/>,
                value: 120,
                name: 'duration',
                emptySelection: <FormattedMessage id={'session.minutes.0'}/>,
                options: DURATION_OPTIONS,
                showList: false,
                disabled: false
            },
            invitations: {
                type: 'single', // single, multiple, direct
                selectTo: {
                    selected: <FormattedMessage id={'select.option.notCompleted'}/>,
                    value: 'not-completed',
                    name: 'selectTo',
                    showList: false,
                    options: SELECT_TO_OPTIONS
                },
                controls: {
                    from: 'development.program@unibocconi.it',
                    to: '',
                    subject: `Bocconi Graduate Development Program - ON LINE BUSINESS CHALLENGE`,
                    message: DEFAULT_EMAIL_MESSAGE,
                },
                defaultMessage: true,
                lastMessageCreated: DEFAULT_EMAIL_MESSAGE,
                valid: false,
                notInList: false,
            },
            playerTokenRemoved: null,
            players: {
                columns: [
                    {
                        name: <FormattedMessage id={'table.players.column.email'}/>,
                        key: 'email',
                    },
                    {
                        name: <FormattedMessage id={'table.players.column.invited'}/>,
                        key: 'invitedIcon',
                    },
                    {
                        name: <FormattedMessage id={'table.players.column.completed'}/>,
                        key: 'completedIcon',
                    },
                    {
                        name: '',
                        key: 'actions',
                    }
                ],
                rows: [],
                all: [],
                filterKey: '',
                newPlayersCounter: 0,
                completed: [],
                notCompleted: [],
            },
            playerToRemove: null,
            playerToReset: null,
            playerToInvite: null,
            playersToAdd: {
                single: '',
                multiple: '',
                notValidEmails: [],
                existingEmails: [],
            },
            answers: [],
            excel: {
                data: [],
            }
        };

        this.setPeriod = this.setPeriod.bind(this);
        this.setDuration = this.setDuration.bind(this);
        this.onClickDuration = this.onClickDuration.bind(this);
        this.onClickDurationItem = this.onClickDurationItem.bind(this);
        this.setName = this.setName.bind(this);
        this.onAddPlayer = this.onAddPlayer.bind(this);
        this.onAddMultiplePlayers = this.onAddMultiplePlayers.bind(this);
        this.onRemovePlayer = this.onRemovePlayer.bind(this);
        this.onResetPlayer = this.onResetPlayer.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleExportClicked = this.handleExportClicked.bind(this);
        this.handleToggleAddDialogVisibility = this.handleToggleAddDialogVisibility.bind(this);
        this.handleToggleSessionResetDialogVisibility = this.handleToggleSessionResetDialogVisibility.bind(this);
        this.handleToggleInvitationsDialogVisibility = this.handleToggleInvitationsDialogVisibility.bind(this);
        this.handleToggleRemoveDialogVisibility = this.handleToggleRemoveDialogVisibility.bind(this);
        this.handleChangePlayerInput = this.handleChangePlayerInput.bind(this);
        this.handleChangePlayerTextarea = this.handleChangePlayerTextarea.bind(this);
        this.handleValidationForMultipleEmails = this.handleValidationForMultipleEmails.bind(this);
        this.handleValidationForExistingEmails = this.handleValidationForExistingEmails.bind(this);
        this.handleChangePlayersFilterInput = this.handleChangePlayersFilterInput.bind(this);
        this.searchPlayersByEmail = this.searchPlayersByEmail.bind(this);
        this.handleChangeEmailFormInput = this.handleChangeEmailFormInput.bind(this);
        this.handleChangeEmailMessageFormInput = this.handleChangeEmailMessageFormInput.bind(this);
        this.handleSendEmailClicked = this.handleSendEmailClicked.bind(this);
        this.onClickSelectTo = this.onClickSelectTo.bind(this);
        this.onClickSelectToItem = this.onClickSelectToItem.bind(this);
        this.handleSendEmailTypeClicked = this.handleSendEmailTypeClicked.bind(this);
        this.resetEmailControls = this.resetEmailControls.bind(this);
        this.resetPlayersControls = this.resetPlayersControls.bind(this);
        this.sendCreatedMessage = this.sendCreatedMessage.bind(this);
    }

    componentDidMount() {

        const { match: { params: { sessionId } } } = this.props;

        if(sessionId){
            this.setState({ sessionId }, () => {
                this.props.fetchSessionPlayers(sessionId);
                this.props.fetchSessionAnswers(sessionId);
                this.props.fetchSession(sessionId);
            });
        }
    }

    setPeriod(startDate, endDate) {
        let session = {
            ...this.state.session,
            startDate,
            endDate
        };

        this.setState({ session });
    }

    setName(e){
        const value = e.target.value;
        const inputName = e.target.name;

        let session = {
            ...this.state.session,
            [inputName]: value
        };

        this.setState({ session });
    }

    onClickSelectTo(ev){
        ev.preventDefault();

        let invitations = {...this.state.invitations};

        invitations.selectTo.showList = !invitations.selectTo.showList;

        this.setState({
            invitations
        });
    };

    onClickSelectToItem(event){
        const value = event.target.getAttribute('data-value');

        let invitations = {...this.state.invitations};
        invitations.selectTo.value = value;

        // eslint-disable-next-line
        invitations.selectTo.options.map(item => {
            if(item.value === value){
                invitations.selectTo.selected = item.description;
            }
        });

        invitations.selectTo.showList = false;

        this.setState({
            invitations,
        });
    };

    onClickDuration(ev){
        ev.preventDefault();

        let duration = {...this.state.duration};

        duration.showList = !duration.showList;

        this.setState({
            duration
        });
    };

    onClickDurationItem(event){
        const value = +event.target.getAttribute('data-value');

        let session = {...this.state.session};
        session.minutes = value;

        this.setState({
            session
        }, () => {
            this.setDuration(value)
        });
    };

    setDuration(value){
        let duration = {...this.state.duration};

        duration.showList = false;

        switch (value) {
            case 30: duration.selected = <FormattedMessage id={'session.minutes.30'}/>;
                break;
            case 60: duration.selected = <FormattedMessage id={'session.minutes.60'}/>;
                break;
            case 90: duration.selected = <FormattedMessage id={'session.minutes.90'}/>;
                break;
            case 120: duration.selected = <FormattedMessage id={'session.minutes.120'}/>;
                break;
            default: duration.selected = <FormattedMessage id={'session.minutes.90'}/>;
        }

        this.setState({
            duration
        });
    }

    handleSubmit(){
        if(this.state.sessionId){
            const sessionObject = {
                id: this.state.sessionId,
                ...this.state.session,
                startDate: moment(this.state.session.startDate).format('YYYY-MM-DD'),
                endDate: moment(this.state.session.endDate).format('YYYY-MM-DD'),
            };

            this.props.updateSession(sessionObject);
        } else {
            this.props.addSession(this.state.session);
        }
    }

    handleExportClicked(){
        console.log('Export data');
        this.createDataToExport();
    }

    createDataToExport(){
        const { players, answers } = this.state;
        let excel = {...this.state.excel};

        const users = players.all;

        /*
        console.log("Users");
        console.log(users);

        console.log("Answers");
        console.log(answers);*/

        let data = [];

        // eslint-disable-next-line
        users.map(user => {
            let row = [];

            const { firstName, lastName, token, email, birthday, bocconiGraduateDevelopmentProgram, other } = user.data;

            const userObj = {
                firstName,
                lastName,
                email,
                birthday: moment(birthday).format('DD/MM/YYYY'),
                bocconiGraduateDevelopmentProgram: bocconiGraduateDevelopmentProgram ? 'yes' : 'no',
                other: other ? 'yes' : 'no',
            };

            let answerObj = null;

            // eslint-disable-next-line
            answers.map(answer => {
                if(answer.playerToken === token && answer.completed){

                    const { country, countriesAnalysis, companies, meeting, startedAt, completedAt } = answer;

                    // Score
                    let score = 0;
                    score += country.score/constants.SCORE.COUNTRY.MAX*100;
                    score += countriesAnalysis.score/constants.SCORE.COUNTRY_ANALYSIS.MAX*100;
                    score += companies.score/constants.SCORE.COMPANIES.MAX*100;
                    score /= 3;

                    // Country data
                    const countryScore = country.score;
                    const countryConsiderations = country.considerations;
                    delete country.score;
                    delete country.considerations;

                    let countryObj = Object.assign({}, ...Object.keys(country).map(key => {
                        const value = country[key] ? 'yes' : 'no';

                        return {
                            [`country.${key}`]: country[key].toString() === 'true' || country[key].toString() === 'false' ? value : country[key]
                        }
                    }));

                    // hack to check that score is always after considerations
                    countryObj = {
                        ...countryObj,
                        [`country.considerations`]: countryConsiderations,
                        [`country.score`]: countryScore,
                    };

                    // Countries Analysis data
                    const countriesAnalysisObj = Object.assign({}, ...countriesAnalysis.country.map(country => {
                        return Object.assign({}, ...Object.keys(country).map(key => {
                            let currentCountry = key;
                            return Object.assign({}, ...Object.keys(country[currentCountry]).map(innerKey => {
                                const value = country[currentCountry][innerKey] ? 'yes' : 'no';
                                //console.log(`countriesAnalysis.${currentCountry}.${innerKey}`, country[currentCountry][innerKey]);
                                return {
                                    [`countriesAnalysis.${currentCountry}.${innerKey}`]: country[currentCountry][innerKey].toString() === 'false' || country[currentCountry][innerKey].toString() === 'true' ? value : country[currentCountry][innerKey]
                                }
                            }));
                        }));
                    }));

                    // Companies Data
                    const companiesObj = companies.order.map(item => item).join(', ');

                    const ms = moment(completedAt).diff(moment(startedAt));
                    const d = moment.duration(ms);
                    const s = Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

                    // Create answer row
                    answerObj = {
                        ...countryObj,
                        ...countriesAnalysisObj,
                        [`countriesAnalysis.considerations`]: countriesAnalysis.considerations,
                        [`countriesAnalysis.score`]: countriesAnalysis.score,
                        [`companies.order`]: companiesObj,
                        [`companies.considerations`]: companies.considerations,
                        [`companies.score`]: companies.score,
                        [`meeting.considerations`]: meeting.considerations,
                        [`score %`]: score.toFixed(2),
                        [`start.dateTime (DD/MM/YYYY hh:mm:ss)`]: moment(startedAt).format('DD/MM/YYYY HH:mm:ss'),
                        [`end.dateTime (DD/MM/YYYY hh:mm:ss)`]: moment(completedAt).format('DD/MM/YYYY HH:mm:ss'),
                        [`duration (hh:mm:ss)`]: s,
                    };

                    const model = {
                        ...userObj,
                        ...answerObj,
                    };

                    // Check to create data header
                    if(!data.length){
                        data.push(Object.keys(model).map(key => key));
                        row = Object.keys(model).map(key => model[key]);
                    } else {
                        row = Object.keys(model).map(key => model[key]);
                    }

                    data.push(row);
                }
            });
        });

        excel.data = data;

        this.setState({
            excel
        }, () => {
            this.createExcelSheet();
        });
    }

    createExcelSheet (){
        const { excel: { data }, session } = this.state;

        /* convert state to workbook */
        const ws = XLSX.utils.aoa_to_sheet(data);
        ws['!cols'] = [];

        if(data[0]) {
            for(let i = 0; i < data[0].length; i++){
                ws['!cols'].push({wch:40})
            }
        }

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "List");

        /* generate XLSX file and send to client */
        XLSX.writeFile(wb, `${session.name ? session.name.replace(' ', '_') : 'session'}_${moment().format('YYYY-MM-DD')}.xlsx`)
    }

    handleToggleAddDialogVisibility() {
        let showAddDialog = !this.state.showAddDialog;

        this.setState({
            showAddDialog
        }, () => {
            if(this.state.showAddDialog){
                this.searchPlayersByEmail()
            }

            if(!showAddDialog){
                this.resetPlayersControls();
            }
        });
    }

    writePlayers(sessionId = null){
        if(this.state.players.rows.length){
            let players = {...this.state.players};
            let counter = 0;

            players.rows.forEach(player => {

                if(player.data.hasOwnProperty('new')){
                    counter++;

                    delete player.data.new;

                    //console.log('Write players...', player.data.email);
                    let data = {
                        firstName: '',
                        lastName: '',
                        email: player.data.email,
                        invited: false,
                        completed: false,
                        session: !sessionId ? this.state.session._id : sessionId,
                    };

                    this.props.addPlayer(data);
                    counter++;

                    //console.log('End write players...');
                }
            });

            players.newPlayersCounter = counter;

            this.setState({
                players
            })
        }
    }


    resetPlayersControls () {
        let playersToAdd = {...this.state.playersToAdd};

        // eslint-disable-next-line
        Object.keys(playersToAdd).map(key => {
            if(key === 'notValidEmails')
                playersToAdd[key] = [];
            else {
                playersToAdd[key] = '';
            }
        });

        this.setState({
            playersToAdd
        })
    }

    resetEmailControls () {
        let invitations = {...this.state.invitations};
        let controls = {...invitations.controls};

        // eslint-disable-next-line
        Object.keys(controls).map(key => {
            invitations.controls[key] = key === 'to' ? '' : invitations.controls[key];
        });

        this.setState({
            invitations
        })
    }

    sendCreatedMessage(token, to){
        let invitations = {...this.state.invitations};

        let path = null;

        if (typeof window !== 'undefined') {
            path = window.location.protocol + '//' + window.location.host;
        }

        let message = invitations.controls.lastMessageCreated;

        //console.log(message);

        if(invitations.defaultMessage){
            message = DEFAULT_EMAIL_MESSAGE.replace('{email}', `<a href='mailto:${invitations.controls.from}'>${invitations.controls.from}</a>`).replace('{link}', `<a href='${path}/challenge/${token}'>clicca qui</a>`).replace('{duration}', `${this.state.session.minutes.toString()}`).replace('{date}', `${moment(this.state.session.endDate).format('DD MMMM YYYY')}`)
        } else {
            message = message.replace('{email}', `<a href='mailto:${invitations.controls.from}'>${invitations.controls.from}</a>`).replace('{link}', `<a href='${path}/challenge/${token}'>clicca qui</a>`).replace('{duration}', `${this.state.session.minutes.toString()}`).replace('{date}', `${moment(this.state.session.endDate).format('DD MMMM YYYY')}`)
        }

        invitations.controls.to = to ? to : '';
        //invitations.type = type ? type : 'single';
        invitations.controls.message = message;

        const data = {
            from: invitations.controls.from,
            message,
            subject: invitations.controls.subject,
            to,
        };

        this.setState({
            playerToInvite: null,
            invitations
        }, () => {
            //console.log('payload', data);
            this.props.sendInvitations(data);
            this.props.updatePlayerByToken({token, invited: true});
        })
    }

    handleToggleInvitationsDialogVisibility(type, token, email) {
        //this.createMessage(type, token, email);

        let showInvitationsDialog = !this.state.showInvitationsDialog;

        let invitations = {...this.state.invitations};

        invitations.type = type;
        if(email) {
            invitations.controls.to = email;

            this.setState({
                playerToInvite: token
            });
        } else {
            invitations.controls.to = '';
        }

        this.setState({
            invitations,
            showInvitationsDialog,
            //playerToInvite: token,
        }, () => {
            if(!showInvitationsDialog){
                this.resetEmailControls()
            }
        });
    }

    onAddPlayer(){
        let players = {...this.state.players};
        let playersToAdd = {...this.state.playersToAdd};

        const lastIndex = players.rows.length;
        if(!this.handleValidationForExistingEmails(true)) {

            let showAddDialog = false;

            const tempId = `${lastIndex}_${randomId()}`;

            const player = {
                data: {
                    invitedIcon: <IconLabel icon={crossIcon} label={<FormattedMessage id={'general.no'}/>}/>,
                    completedIcon: <IconLabel icon={crossIcon} label={<FormattedMessage id={'general.no'}/>}/>,
                    invited: false,
                    completed: false,
                    email: playersToAdd.single,
                    firstName: '',
                    lastName: '',
                    new: true,
                    tempId
                },
                actions: {
                    email: {
                        func: () => this.handleToggleInvitationsDialogVisibility('direct', tempId, playersToAdd.single),
                        icon: <img src={mailLightIcon} alt={'email'}/>,
                        styles: {
                            visibility: 'hidden'
                        }
                    },
                    reset: {
                        func: () => console.log('RESET for index: ', lastIndex),
                        icon: <img src={resetIcon} alt={'reset'}/>,
                        styles: {
                            visibility: 'hidden'
                        }
                    },
                    remove: {
                        func: () => this.handleToggleRemoveDialogVisibility(tempId, true, playersToAdd.single),
                        icon: <img src={trashIcon} alt={'remove'}/>
                    },
                }
            };

            players.rows.push(player);
            players.all = players.rows;

            this.setState({
                players,
                playersToAdd,
                showAddDialog
            }, () => {
                this.resetPlayersControls();
                if(this.state.sessionId)
                    this.writePlayers();
            });
        }
    }

    onAddMultiplePlayers(){
        let players = {...this.state.players};
        let playersToAdd = {...this.state.playersToAdd};

        const lastIndex = players.rows.length;

        if(!this.handleValidationForMultipleEmails() && !this.handleValidationForExistingEmails()){
            const emails = playersToAdd.multiple.split('\n');

            emails.forEach((email, index) => {

                const tempId = `${lastIndex}_${randomId()}`;

                const player = {
                    data: {
                        invitedIcon: <IconLabel icon={crossIcon} label={<FormattedMessage id={'general.no'}/>} />,
                        completedIcon: <IconLabel icon={crossIcon} label={<FormattedMessage id={'general.no'}/>} />,
                        invited: false,
                        completed: false,
                        email: email,
                        firstName: '',
                        lastName: '',
                        new: true,
                        tempId
                    },
                    actions: {
                        email: {
                            func: () => this.handleToggleInvitationsDialogVisibility('direct', tempId, email),
                            icon: <img src={mailLightIcon} alt={'email'} />,
                            styles: {
                                visibility: 'hidden'
                            }
                        },
                        reset: {
                            func: () => console.log('RESET for index: ', lastIndex + index),
                            icon: <img src={resetIcon} alt={'reset'} />,
                            styles: {
                                visibility: 'hidden'
                            }
                        },
                        remove: {
                            func: () => this.handleToggleRemoveDialogVisibility(tempId, true, email),
                            icon: <img src={trashIcon} alt={'remove'} />
                        },
                    }
                };

                players.rows.push(player);
                players.all = players.rows;
            });

            this.setState({
                players,
                playersToAdd,
                showAddDialog: false,
            }, () => {
                this.resetPlayersControls();
                if(this.state.sessionId)
                    this.writePlayers();
            });
        }
    }

    handleToggleSessionResetDialogVisibility(_token, newPlayer = false, email) {
        //console.log(_token, newPlayer, email);

        let showResetDialog = !this.state.showResetDialog;
        const playerToReset = {
            token: _token,
            newPlayer,
            email
        };

        this.setState({
            showResetDialog,
            playerToReset
        });
    }

    handleToggleRemoveDialogVisibility(_id, newPlayer = false, email) {

        let showRemoveDialog = !this.state.showRemoveDialog;
        const playerToRemove = {
            id: _id,
            newPlayer,
            email
        };

        this.setState({
            showRemoveDialog,
            playerToRemove
        });
    }

    onRemovePlayer(){
        //console.log("Remove player with id: ", _id);
        //console.log("New player?", newPlayer);

        const { id, newPlayer } = this.state.playerToRemove;

        if(newPlayer){
            const newPlayers = this.state.players.rows.filter(player => player.data.tempId !== id);
            const allNewPlayers = this.state.players.all.filter(player => player.data.tempId !== id);

            let players = {...this.state.players};

            players.rows = newPlayers;
            players.all = allNewPlayers;

            this.setState({
                showRemoveDialog: false,
                players,
                playerToRemove: null,
            });
        } else {
            const player = this.state.players.rows.filter(player => player.data._id === id)[0];

            const token = player.data.token;

            this.setState({
                playerTokenRemoved: token
            }, () => {
                delete player.data._id;
                delete player.data.invitedIcon;
                delete player.data.completedIcon;

                const data = {
                    ...player.data,
                    token,
                    deleted: true,
                };

                //console.log(data);
                this.props.updatePlayerByToken(data);
                //this.props.deletePlayer(_id);
            });
        }
    }

    onResetPlayer(){
        //console.log("Remove player with id: ", _id);
        //console.log("New player?", newPlayer);

        const { token, newPlayer } = this.state.playerToReset;

        if(!newPlayer){
            const player = this.state.players.rows.filter(player => player.data.token === token)[0];

            //console.log(player.data.completed);

            if(player.data.completed || player.data.expired){
                delete player.data._id;
                delete player.data.invitedIcon;
                delete player.data.completedIcon;

                const data = {
                    ...player.data,
                    token,
                    completed: false,
                    expired: false,
                    invited: false,
                };

                this.props.updatePlayerByToken(data);

                this.props.deleteAnswerByPlayerToken(token);
            } else {
                this.setState({
                    playerToReset: null,
                    showResetDialog: false,
                })
            }
        }
    }

    searchPlayersByEmail(key = ''){
        let newPlayers = { ...this.state.players };

        if(key.trim() === '') {
            newPlayers.rows = newPlayers.all;
        } else {
            newPlayers.rows = this.state.players.rows.filter(player => player.data.email.toLowerCase().includes(key.toLowerCase()));
        }

        newPlayers.filterKey = key;

        this.setState({
            players: newPlayers
        });
    }

    handleChangeEmailFormInput(e) {
        let invitations = { ...this.state.invitations };

        invitations.notInList = false;

        invitations.controls[e.target.name] = e.target.value;

        this.setState({
            invitations
        });
    }

    handleChangeEmailMessageFormInput(message) {
        let invitations = { ...this.state.invitations };

        invitations.notInList = false;

        if(invitations.defaultMessage){
            invitations.defaultMessage = false;
        }

        invitations.controls.lastMessageCreated = message;
        invitations.controls.message = message;

        this.setState({
            invitations
        });
    }

    handleSendEmailClicked(){
        let notInList = true;

        let invitations = {...this.state.invitations};

        if(invitations.type === 'direct'){
            notInList = false;

            const data = {
                token: this.state.playerToInvite,
                invited: true,
            };

            this.props.updatePlayerByToken(data);
        } else {
            if(invitations.type === 'single'){
                let players = {...this.state.players};

                let token = null;
                let email = null;

                // eslint-disable-next-line
                players.all.map(player => {
                    if(player.data.email.toLowerCase() === invitations.controls.to.toLowerCase()) {
                        notInList = false;
                        token = player.data.token;
                        email = player.data.email;
                    }
                });

                if(!notInList) {
                    this.sendCreatedMessage(token, email);
                }
            } else {
                notInList = false;
                let players = {...this.state.players};
                const playersTest = invitations.selectTo.value === 'not-completed' ? players.notCompleted : players.completed;

                //console.log(players.completed, players.notCompleted);

                // eslint-disable-next-line
                playersTest.map(player => {
                    this.sendCreatedMessage(player.token, player.email);
                });
            }

            if(!notInList) {
                this.setState({
                    showInvitationsDialog: false,
                }, () => {
                    this.resetEmailControls()
                })
            } else {
                invitations.notInList = notInList;
                this.setState({
                    invitations
                });
            }
        }
    }

    handleSendEmailTypeClicked(type){
        let invitations = {...this.state.invitations};

        invitations.notInList = false;
        invitations.controls.to = '';

        invitations.type = type;

        this.setState({
            invitations
        })
    }

    handleChangePlayersFilterInput(e) {
        this.searchPlayersByEmail(e.target.value);
    }

    handleChangePlayerInput(e) {
        let playersToAdd = { ...this.state.playersToAdd };

        playersToAdd.single = e.target.value;

        this.setState({
            playersToAdd
        });
    }

    handleChangePlayerTextarea(e) {
        let playersToAdd = { ...this.state.playersToAdd };

        playersToAdd.multiple = e.target.value.trim();

        playersToAdd.notValidEmails = [];

        this.setState({
            playersToAdd
        });
    }

    handleValidationForExistingEmails(single = false) {
        let playersToAdd = {...this.state.playersToAdd};

        let emails = [];

        if(single)
            emails.push(playersToAdd.single);
        else
            emails = playersToAdd.multiple.split('\n');


        playersToAdd.existingEmails = [];

        emails.forEach(email => {
            // eslint-disable-next-line
            this.state.players.rows.map(player => {
                if(player.data.email.toLowerCase() === email.toLowerCase()){
                    playersToAdd.existingEmails.push(email);
                }
            })
        });

        this.setState({
            playersToAdd
        });

        return playersToAdd.existingEmails.length > 0;
    }

    handleValidationForMultipleEmails() {
        let playersToAdd = {...this.state.playersToAdd};

        const emails = playersToAdd.multiple.split('\n');

        playersToAdd.notValidEmails = [];

        emails.forEach(email => {
            if(!checkValidEmail(email)) {
                playersToAdd.notValidEmails.push(email);
            }
        });

        this.setState({
            playersToAdd
        });

        return playersToAdd.notValidEmails.length > 0;
    }

    componentWillReceiveProps(nextProps, nextContext) {

        if(this.props.answers !== nextProps.answers){
            this.setState({
                answers: nextProps.answers
            });
        }

        if(this.props.session !== nextProps.session && nextProps.session){
            if(nextProps.session.hasOwnProperty('_id')){

                const session = {
                    ...this.state.session,
                    ...nextProps.session,
                    startDate: moment(nextProps.session.startDate),
                    endDate: moment(nextProps.session.endDate),
                };

                this.setDuration(session.minutes);

                this.setState({
                    session
                })
            }
        }

        if(this.props.idSessionCreated !== nextProps.idSessionCreated && nextProps.idSessionCreated){
            this.writePlayers(nextProps.idSessionCreated);

            this.setState({
                sessionId: nextProps.idSessionCreated,
                sessionFeedback: 'feedback.session.saved'
            }, () => {
                setTimeout(() => {
                    this.setState({
                        sessionFeedback: null,
                    });
                }, 1000);
                this.props.fetchSessionPlayers(nextProps.idSessionCreated);
                this.props.history.push(routes.ADMIN_EDIT_SESSION.replace(':sessionId', nextProps.idSessionCreated));
            });
        }

        if(this.props.players !== nextProps.players){
            let players = {...this.state.players};
            let completed = [];
            let notCompleted = [];

            players.rows = nextProps.players.map((player, index) => {
                const playerTest = {
                    email: player.email,
                    token: player.token,
                };

                if(player.completed){

                    completed.push(playerTest);
                } else {

                    notCompleted.push(playerTest);
                }

                return {
                    data: {
                        ...player,
                        invitedIcon:
                            player.invited ?
                                <IconLabel icon={checkIcon} label={<FormattedMessage id={'general.yes'}/>} /> :
                                <IconLabel icon={crossIcon} label={<FormattedMessage id={'general.no'}/>} />,
                        completedIcon:
                            player.completed ?
                                <IconLabel icon={checkIcon} label={<FormattedMessage id={'general.yes'}/>} /> :
                                <IconLabel icon={crossIcon} label={<FormattedMessage id={'general.no'}/>} />,
                    },
                    actions: {
                        email: {
                            func: () => this.handleToggleInvitationsDialogVisibility('direct', player.token, player.email),
                            icon: <img src={mailLightIcon} alt={'email'} />
                        },
                        reset: {
                            func: () => this.handleToggleSessionResetDialogVisibility(player.token, false, player.email),
                            icon: <img src={resetIcon} alt={'reset'} />
                        },
                        remove: {
                            func: () => this.handleToggleRemoveDialogVisibility(player._id, false, player.email),
                            icon: <img src={trashIcon} alt={'remove'} />
                        },
                    }
                };
            });

            players.all = players.rows;
            players.completed = completed;
            players.notCompleted = notCompleted;

            this.setState({
                players
            });
        }

        if(this.props.playersUpdated !== nextProps.playersUpdated){
            const playerTokenRemoved = this.state.playerTokenRemoved;
            const playerTokenInvited = this.state.playerToInvite;

            if(playerTokenRemoved){
                const newPlayers = this.state.players.rows.filter(player => player.data.token !== playerTokenRemoved);
                const allNewPlayers = this.state.players.all.filter(player => player.data.token !== playerTokenRemoved);

                //console.log(newPlayers);

                let players = {...this.state.players};

                players.rows = newPlayers;
                players.all = allNewPlayers;

                this.setState({
                    players,
                    playerToRemove: null,
                    showRemoveDialog: false,
                    playerTokenRemoved: null,
                });
            }

            if(playerTokenInvited){

                this.sendCreatedMessage(playerTokenInvited, this.state.invitations.controls.to);

                let players = {...this.state.players};

                players.rows = this.state.players.rows.map((playerToUpdate) => {
                    const player = playerToUpdate.data;

                    return {
                        data: {
                            ...player,
                            invitedIcon:
                                player.invited || player.token === playerTokenInvited ?
                                    <IconLabel icon={checkIcon} label={<FormattedMessage id={'general.yes'}/>} /> :
                                    <IconLabel icon={crossIcon} label={<FormattedMessage id={'general.no'}/>} />,
                            completedIcon:
                                player.completed ?
                                    <IconLabel icon={checkIcon} label={<FormattedMessage id={'general.yes'}/>} /> :
                                    <IconLabel icon={crossIcon} label={<FormattedMessage id={'general.no'}/>} />,
                        },
                        actions: {
                            email: {
                                func: () => this.handleToggleInvitationsDialogVisibility('direct', player.token, player.email),
                                icon: <img src={mailLightIcon} alt={'email'} />
                            },
                            reset: {
                                func: () => this.handleToggleSessionResetDialogVisibility(player.token, false, player.email),
                                icon: <img src={resetIcon} alt={'reset'} />
                            },
                            remove: {
                                func: () => this.handleToggleRemoveDialogVisibility(player._id, false, player.email),
                                icon: <img src={trashIcon} alt={'remove'} />
                            },
                        }
                    };
                });

                players.all = players.rows;

                this.setState({
                    players,
                    playerToInvite: null,
                    showInvitationsDialog: false,
                })
            }
        }

        if(this.props.sessionUpdated !== nextProps.sessionUpdated && nextProps.sessionUpdated){
            //this.props.fetchSessionPlayers(this.state.sessionId);
            //console.log('UPDATED');

            this.setState({
                sessionFeedback: 'feedback.session.saved'
            }, () => {
                setTimeout(() => {

                    this.setState({
                        sessionFeedback: null,
                    });
                    //this.props.history.push(routes.ADMIN_SESSIONS);
                }, 1000);

                this.props.fetchSessionPlayers(this.state.sessionId);
                this.props.history.push(routes.ADMIN_EDIT_SESSION.replace(':sessionId', this.state.sessionId));
            });
        }

        if(this.props.answerRemoved !== nextProps.answerRemoved && nextProps.answerRemoved){
            let playerToReset = this.state.playerToReset;

            if(playerToReset){

                let players = {...this.state.players};

                players.rows = this.state.players.rows.map((playerToUpdate) => {
                    const player = playerToUpdate.data;

                    const completed = player.token === playerToReset.token ? false : player.completed;

                    return {
                        data: {
                            ...player,
                            invitedIcon:
                                player.invited ?
                                    <IconLabel icon={checkIcon} label={<FormattedMessage id={'general.yes'}/>} /> :
                                    <IconLabel icon={crossIcon} label={<FormattedMessage id={'general.no'}/>} />,
                            completedIcon:
                                completed ?
                                    <IconLabel icon={checkIcon} label={<FormattedMessage id={'general.yes'}/>} /> :
                                    <IconLabel icon={crossIcon} label={<FormattedMessage id={'general.no'}/>} />,
                        },
                        actions: {
                            email: {
                                func: () => this.handleToggleInvitationsDialogVisibility('direct', player.token, player.email),
                                icon: <img src={mailLightIcon} alt={'email'} />
                            },
                            reset: {
                                func: () => this.handleToggleSessionResetDialogVisibility(player.token, false, player.email),
                                icon: <img src={resetIcon} alt={'reset'} />
                            },
                            remove: {
                                func: () => this.handleToggleRemoveDialogVisibility(player._id, false, player.email),
                                icon: <img src={trashIcon} alt={'remove'} />
                            },
                        }
                    };
                });

                players.all = players.rows;

                this.setState({
                    players,
                    playerToReset: null,
                    showResetDialog: false,
                })
            }
        }
    }

    render() {
        const { focusedDateInput, playerToRemove, playerToReset, answers, showResetDialog, showRemoveDialog, sessionFeedback, session, sessionId, duration, players, playersToAdd, showAddDialog, showInvitationsDialog, invitations } = this.state;

        const filterInputTpl = (
            <FormattedMessage id={'list.players.filter'}>
                {
                    placeholder => (
                        <AdminInput
                            icon={searchIcon}
                            styles={{height: '40px', width: '250px'}}
                            dark
                            placeholder={placeholder}
                            value={players.filterKey}
                            changed={this.handleChangePlayersFilterInput}/>
                    )
                }
            </FormattedMessage>
        );

        return (
            <div className={classes.Session}>
                <div
                    className={
                        [
                            classes["Session-content"],
                            classes["is-visible"],
                        ].join(' ')}>

                    <div className={classes["Session-header"]}>
                        <NavLink className={classes["Session-backLink"]} to={routes.ADMIN_SESSIONS}>
                            <img src={backIcon} alt={'back'}/><FormattedMessage id={'button.backToSessionsList'}/>
                        </NavLink>
                        {
                            sessionFeedback &&
                                <div className={[classes["Session-feedback"], this.props.error ? classes["Session-feedback--error"] : ''].join(' ')}>
                                    <FormattedMessage id={sessionFeedback}/>
                                </div>
                        }
                    </div>
                    <div className={classes["Session-wrapper"]}>
                        <FormattedMessage id={'session.placeholder.name'}>
                            {
                                placeholder => {
                                    return (
                                        <input
                                            autoComplete={'off'}
                                            placeholder={placeholder}
                                            className={classes["Session-input"]}
                                            type="text"
                                            name={'name'}
                                            value={session.name}
                                            onChange={this.setName}
                                        />
                                    )
                                }
                            }
                        </FormattedMessage>

                    </div>
                    <div className={classes["Session-boxes"]}>
                        <AdminBox
                            title={<FormattedMessage id={'session.box.dates'}/>}>
                            <DateRangePicker
                                displayFormat={'D MMMM'}
                                hideKeyboardShortcutsPanel={true}
                                startDate={session.startDate}
                                startDateId={'sessionStartDate'}
                                endDate={session.endDate}
                                endDateId={'sessionEndDate'}
                                onDatesChange={({ startDate, endDate }) => this.setPeriod(startDate, endDate)}
                                focusedInput={focusedDateInput}
                                onFocusChange={focusedDateInput => this.setState({ focusedDateInput })}
                                startDatePlaceholderText={'Start'}
                                endDatePlaceholderText={'End'}
                            />
                        </AdminBox>
                        <AdminBox
                            title={<FormattedMessage id={'session.box.duration'}/>}>

                            <AdminSelect
                                styles={{margin: 'auto 0 0', width: '180px'}}
                                clicked={this.onClickDuration}
                                itemClicked={this.onClickDurationItem}
                                emptySelection={duration.emptySelection}
                                selected={duration.selected}
                                value={duration.value}
                                name={duration.name}
                                showList={duration.showList}
                                options={duration.options} />
                        </AdminBox>
                        <AdminBox
                            title={<FormattedMessage id={'session.box.export'}/>}>

                            <Button
                                styles={{margin: 'auto auto 0 0', minWidth: '200px'}}
                                hoverEffect={true}
                                disabled={!sessionId || players.rows.length === 0 || !answers.length}
                                clicked={this.handleExportClicked}>
                                <FormattedMessage id="button.session.download"/>
                            </Button>
                        </AdminBox>
                    </div>
                    <div className={classes["Session-table"]}>
                        <AdminTableHeader
                            title={<FormattedMessage id={'list.players.title'}/>}
                            filter={filterInputTpl}>
                            <button
                                className={[classes["Session-navLink"], classes["Session-navLink--light"]].join(' ')}
                                onClick={this.handleToggleAddDialogVisibility}>
                                <img src={plusLightIcon} alt={'add'} /><FormattedMessage id={'button.session.addPlayers'}/>
                            </button>
                            {
                                sessionId && players.rows.length > 0 &&
                                    <button
                                        className={classes["Session-navLink"]}
                                        onClick={() => this.handleToggleInvitationsDialogVisibility('single','')}>
                                        <img src={mailIcon} alt={'invites'} /><FormattedMessage id={'button.session.invitations'}/>
                                    </button>
                            }
                        </AdminTableHeader>
                        <AdminTable
                            emptyMessage={
                                players.filterKey.trim() === '' ?
                                    <FormattedMessage id={'list.players.empty'}/> :
                                    <FormattedMessage id={'list.players.notFound'}/>
                            }
                            model={players} />
                    </div>
                    <div className={classes["Session-footer"]}>
                        <Button
                            disabled={session.name.trim() === '' || !moment(session.startDate).isValid() || !moment(session.endDate).isValid()}
                            hoverEffect={true}
                            clicked={this.handleSubmit}>
                            <FormattedMessage id="button.save"/>
                        </Button>
                        {
                            this.props.saving &&
                                <Loader styles={{ margin: 0 }} />
                        }
                    </div>
                </div>

                {
                    showAddDialog &&
                        <Dialog
                            width={390}
                            allowScroll={false}
                            title={<FormattedMessage id={'player.add.title'}/>}
                            onDialogCloseClicked={this.handleToggleAddDialogVisibility}
                            showCloseButton={true}>
                            <div className={classes.Field} style={{ margin: '20px 0 60px'}}>
                                <label className={classes["Field-label"]}>
                                    <FormattedHTMLMessage id={'player.add.single.label'} />
                                </label>
                                <FormattedMessage id={'player.email.placeholder'}>
                                    {
                                        placeholder => (
                                            <AdminInput
                                                styles={{margin: '0 0 20px'}}
                                                type={'text'}
                                                placeholder={placeholder}
                                                value={playersToAdd.single}
                                                changed={this.handleChangePlayerInput}/>
                                        )
                                    }
                                </FormattedMessage>
                                <Button
                                    btnType={'success'}
                                    hoverEffect={true}
                                    disabled={!checkValidEmail(playersToAdd.single)}
                                    clicked={this.onAddPlayer}>
                                    <FormattedMessage id={'button.add'}/>
                                </Button>
                            </div>
                            <div className={classes.Field}>
                                <label className={classes["Field-label"]}>
                                    <FormattedHTMLMessage id={'player.add.multiple.label'} />
                                </label>
                                {
                                    <FormattedMessage id={'player.email.placeholder'}>
                                        {
                                            placeholder => (
                                                <AdminTextarea
                                                    styles={{margin: '0 0 20px'}}
                                                    placeholder={placeholder}
                                                    value={playersToAdd.multiple}
                                                    changed={this.handleChangePlayerTextarea}/>
                                            )
                                        }
                                    </FormattedMessage>
                                }
                                {
                                    playersToAdd.notValidEmails.length > 0 &&
                                        <div className={classes["Session-emailsErrors"]}>
                                            <FormattedMessage id={'players.emails.error'}/>
                                            <small>
                                            {
                                                playersToAdd.notValidEmails.join('; ')
                                            }
                                            </small>
                                        </div>
                                }
                                {
                                    playersToAdd.existingEmails.length > 0 &&
                                    <div className={classes["Session-emailsErrors"]}>
                                        <FormattedMessage id={'players.emails.existing'}/>
                                        <small>
                                            {
                                                playersToAdd.existingEmails.join('; ')
                                            }
                                        </small>
                                    </div>
                                }
                                <Button
                                    btnType={'success'}
                                    hoverEffect={true}
                                    disabled={playersToAdd.multiple.trim() === '' || playersToAdd.notValidEmails.length > 0}
                                    clicked={this.onAddMultiplePlayers}>
                                    <FormattedMessage id={'button.add'}/>
                                </Button>
                            </div>
                        </Dialog>
                }

                {
                    showInvitationsDialog &&
                        <Dialog
                            width={600}
                            top={10}
                            allowScroll={false}
                            title={<FormattedMessage id={'form.email.title'}/>}
                            selector={
                                invitations.type !== 'direct' ?
                                <div className={classes.Selector}>
                                    <button onClick={() => this.handleSendEmailTypeClicked('single')} className={[classes["Selector-button"], invitations.type === 'single' ? classes["Selector-button--active"] : ''].join(' ')}><FormattedMessage id={'form.email.tab.single'}/></button>
                                    <button onClick={() => this.handleSendEmailTypeClicked('multiple')} className={[classes["Selector-button"], invitations.type === 'multiple' ? classes["Selector-button--active"] : ''].join(' ')}><FormattedMessage id={'form.email.tab.multiple'}/></button>
                                </div> : null
                            }
                            notice={<FormattedMessage id={'form.email.notice'}/>}
                            onDialogCloseClicked={this.handleToggleInvitationsDialogVisibility}
                            showCloseButton={true}>

                            {
                                invitations.type === 'multiple' &&
                                    <div className={classes.Field}>
                                        <label className={classes["Field-label"]}>
                                            <FormattedHTMLMessage id={'form.email.type.label'} />
                                        </label>
                                        <AdminSelect
                                            light
                                            styles={{margin: 'auto 0 0'}}
                                            clicked={this.onClickSelectTo}
                                            itemClicked={this.onClickSelectToItem}
                                            selected={invitations.selectTo.selected}
                                            value={invitations.selectTo.value}
                                            name={invitations.selectTo.name}
                                            showList={invitations.selectTo.showList}
                                            options={invitations.selectTo.options} />
                                    </div>
                            }
                            <div className={classes.Field}>
                                <label className={classes["Field-label"]}>
                                    <FormattedHTMLMessage id={'form.email.from.label'} />
                                </label>
                                <FormattedMessage id={'form.email.from.placeholder'}>
                                    {
                                        placeholder => (
                                            <AdminInput
                                                type={'email'}
                                                placeholder={placeholder}
                                                value={invitations.controls.from}
                                                name={'from'}
                                                changed={this.handleChangeEmailFormInput}/>
                                        )
                                    }
                                </FormattedMessage>
                            </div>
                            {
                                invitations.type !== 'multiple' &&
                                <div className={classes.Field}>
                                    <label className={classes["Field-label"]}>
                                        <FormattedHTMLMessage id={'form.email.to.label'}/>
                                    </label>
                                    <FormattedMessage id={'form.email.to.placeholder'}>
                                        {
                                            placeholder => (
                                                <AdminInput
                                                    type={'email'}
                                                    placeholder={placeholder}
                                                    value={invitations.controls.to}
                                                    name={'to'}
                                                    disabled={invitations.type === 'direct'}
                                                    changed={this.handleChangeEmailFormInput}/>
                                            )
                                        }
                                    </FormattedMessage>
                                </div>
                            }
                            <div className={classes.Field}>
                                <label className={classes["Field-label"]}>
                                    <FormattedHTMLMessage id={'form.email.subject.label'} />
                                </label>
                                <FormattedMessage id={'form.email.subject.placeholder'}>
                                    {
                                        placeholder => (
                                            <AdminInput
                                                type={'text'}
                                                placeholder={placeholder}
                                                value={invitations.controls.subject}
                                                name={'subject'}
                                                changed={this.handleChangeEmailFormInput}/>
                                        )
                                    }
                                </FormattedMessage>
                            </div>
                            <div className={classes.Field}>
                                <label className={classes["Field-label"]}>
                                    <FormattedHTMLMessage id={'form.email.message.label'} />
                                </label>
                                {
                                    <FormattedMessage id={'form.email.message.placeholder'}>
                                        {
                                            placeholder => (
                                                <AdminTextarea
                                                    html
                                                    name={'message'}
                                                    styles={{ height: '150px' }}
                                                    placeholder={placeholder}
                                                    value={invitations.controls.message}
                                                    changed={this.handleChangeEmailMessageFormInput}/>
                                            )
                                        }
                                    </FormattedMessage>
                                }
                            </div>{
                                invitations.notInList &&
                                    <div className={classes["Session-emailsErrors"]} style={{margin: '0 0 12px'}}>
                                        <FormattedMessage id={'players.notInList.error'}/>
                                    </div>
                            }
                            <div className={classes.InvitationLegend}>
                                <Legend>
                                    <div dangerouslySetInnerHTML={{__html: LEGEND}} />
                                </Legend>
                            </div>
                            <div className={classes["Form-footer"]}>
                                <Button
                                    styles={{width: '160px'}}
                                    btnType={'success'}
                                    hoverEffect={true}
                                    disabled={(!checkValidEmail(invitations.controls.to) && invitations.type === 'single') || invitations.notInList || invitations.controls.from.trim() === '' || !checkValidEmail(invitations.controls.from) || invitations.controls.message.trim() === ''}
                                    clicked={this.handleSendEmailClicked}>
                                    <FormattedMessage id={'form.email.button.send'}/>
                                </Button>
                            </div>
                        </Dialog>
                }

                {
                    showRemoveDialog &&
                    <Dialog
                        width={390}
                        allowScroll={false}
                        title={<FormattedMessage id={'player.delete.title'}/>}
                        onDialogCloseClicked={() => this.handleToggleRemoveDialogVisibility(null, false, null)}
                        showCloseButton={true}>
                        <div className={classes.Player}>
                            <h3 className={classes["Player-title"]}>{ `${playerToRemove.email}?` }</h3>
                            <small className={classes["Player-subtitle"]}>
                                <FormattedMessage id={'player.delete.subtitle'}/>
                            </small>
                            <div className={classes["Player-footer"]}>
                                <Button
                                    btnType={'tertiary'}
                                    hoverEffect={true}
                                    clicked={() => this.handleToggleRemoveDialogVisibility(null, false, null)}>
                                    <FormattedMessage id="player.delete.undo"/>
                                </Button>
                                <Button
                                    btnType={'danger'}
                                    hoverEffect={true}
                                    clicked={this.onRemovePlayer}>
                                    <FormattedMessage id="player.delete.confirm"/>
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                }

                {
                    showResetDialog &&
                    <Dialog
                        width={390}
                        allowScroll={false}
                        title={<FormattedMessage id={'player.reset.title'}/>}
                        onDialogCloseClicked={() => this.handleToggleSessionResetDialogVisibility(null, false, null)}
                        showCloseButton={true}>
                        <div className={classes.Player}>
                            <h3 className={classes["Player-title"]}>{ `${playerToReset.email}?` }</h3>
                            <small className={classes["Player-subtitle"]}>
                                <FormattedMessage id={'player.reset.subtitle'}/>
                            </small>
                            <div className={classes["Player-footer"]}>
                                <Button
                                    btnType={'tertiary'}
                                    hoverEffect={true}
                                    clicked={() => this.handleToggleSessionResetDialogVisibility(null, false, null)}>
                                    <FormattedMessage id="player.reset.undo"/>
                                </Button>
                                <Button
                                    btnType={'danger'}
                                    hoverEffect={true}
                                    clicked={this.onResetPlayer}>
                                    <FormattedMessage id="player.reset.confirm"/>
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        players: state.sessions.players,
        answers: state.sessions.answers,
        session: state.sessions.session,
        idSessionCreated: state.sessions.idSessionCreated,
        sessionUpdated: state.sessions.updated,
        error: state.sessions.error,
        loading: state.sessions.saving,
        saving: state.players.saving || state.sessions.saving,
        saved: state.sessions.saved || state.players.saved,
        playerCreated: state.players.created,
        sessionCreated: state.sessions.idSessionCreated !== null,
        removed: state.players.removed,
        invitations: state.invitations.invitations,
        playersUpdated: state.players.updated,
        answerRemoved: state.answers.removed
    }
};

const mapDispatchToProps = {
    fetchSession,
    fetchSessionPlayers,
    fetchSessionAnswers,
    addSession,
    updateSession,
    addPlayer,
    deletePlayer,
    updatePlayerByToken,
    sendInvitations,
    deleteAnswerByPlayerToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(Session);
