import React from 'react';
import classes from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";

const textarea = (props) => {
    const { disabled, name, value, changed, clicked, placeholderId, styles, maxLength } = props;

    return (
        <FormattedMessage id={placeholderId || 'general.emptyMessage'} defaultMessage={''}>
            {
                placeholder =>
                    <textarea
                        placeholder={placeholder}
                        style={styles}
                        name={name}
                        className={[classes.Textarea, disabled ? classes["is-disabled"] : ''].join(' ')}
                        disabled={disabled}
                        onChange={changed}
                        autoComplete="off"
                        value={value}
                        maxLength={maxLength}
                        onClick={clicked} />
            }
        </FormattedMessage>

    )
};


textarea.propTypes = {
    name: PropTypes.string,
    placeholderId: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    clicked: PropTypes.func,
    changed: PropTypes.func,
    styles: PropTypes.object,
    maxLength: PropTypes.number,
};

textarea.defaultProps = {
    value: '',
    maxLength: 1000,
};

export default textarea;
