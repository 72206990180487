import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import scrollIntoView from 'scroll-into-view-if-needed';
import * as routes from "../../../shared/routes";
import constants from "../../../shared/constants";
import { countOccurrencesInArrayOfObject, getObjectByArray } from "../../../shared/helpers";
import {fetchAnswerByPlayerToken, updateAnswerByPlayerToken} from "../../../store/actions/answers";
import connect from "react-redux/es/connect/connect";
import classes from './styles.module.scss';
import Button from "../../../components/UI/Button";
import Stepper from "../../../components/Stepper";
import Question from "../../../components/Question";
import Separator from "../../../components/UI/Separator";
import SelectBox from "../../../components/UI/SelectBox";
import FlexBox from "../../FlexBox";
import Square from "../../../components/UI/Square";
import Textarea from "../../../components/UI/Textarea";
import Modal from "../../../components/UI/Modal";
import Paragraph from "../../../components/Paragraph";
import Feedback from "../../../components/Feedback";
import TwoColumnsWrapper from "../../TwoColumnsWrapper";
import Sidebar from "../../../components/Sidebar";
import Steps from "../";
import Footer from "../../../components/Footer";

// Import charts
import A_Chart1 from '../../../assets/images/charts/A_chart_1.png';
import A_Chart2 from '../../../assets/images/charts/A_chart_2.png';
import A_Chart3 from '../../../assets/images/charts/A_chart_3.png';
import B_Chart1 from '../../../assets/images/charts/B_chart_1.png';
import B_Chart2 from '../../../assets/images/charts/B_chart_2.png';
import B_Chart3 from '../../../assets/images/charts/B_chart_3.png';
import C_Chart1 from '../../../assets/images/charts/C_chart_1.png';
import C_Chart2 from '../../../assets/images/charts/C_chart_2.png';
import C_Chart3 from '../../../assets/images/charts/C_chart_3.png';
import D_Chart1 from '../../../assets/images/charts/D_chart_1.png';
import D_Chart2 from '../../../assets/images/charts/D_chart_2.png';
import D_Chart3 from '../../../assets/images/charts/D_chart_3.png';
import E_Chart1 from '../../../assets/images/charts/E_chart_1.png';
import E_Chart2 from '../../../assets/images/charts/E_chart_2.png';
import E_Chart3 from '../../../assets/images/charts/E_chart_3.png';
import F_Chart1 from '../../../assets/images/charts/F_chart_1.png';
import F_Chart2 from '../../../assets/images/charts/F_chart_2.png';
import F_Chart3 from '../../../assets/images/charts/F_chart_3.png';

// Pins
import azzurroIcon from '../../../assets/icons/pins/azzurro.svg';
import gialloIcon from '../../../assets/icons/pins/giallo.svg';
import lillaIcon from '../../../assets/icons/pins/lilla.svg';
import marroneIcon from '../../../assets/icons/pins/marrone.svg';
import rosaIcon from '../../../assets/icons/pins/rosa.svg';
import verdeIcon from '../../../assets/icons/pins/verde.svg';
import transparentIcon from '../../../assets/icons/pins/transparent.svg';

// Icons
import nextButtonIcon from '../../../assets/icons/next.svg';
import careerIcon from '../../../assets/icons/career.svg';
import exchangeIcon from '../../../assets/icons/exchange.svg';
import meetingIcon from '../../../assets/icons/meeting.svg';
import orientationIcon from '../../../assets/icons/orientation.svg';
import phoneIcon from '../../../assets/icons/phone.svg';
import starIcon from '../../../assets/icons/star.svg';
import shortAcademicProgramIcon from '../../../assets/icons/short_academic_program.svg';
import deleteIcon from '../../../assets/icons/delete.svg';
import detailIcon from '../../../assets/icons/detail.svg';

const supportAnimation = true;
const keyPropertyToStore = 'key';

class StepCountriesAnalysis extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
            animate: false,
            supportAnimation: supportAnimation,
            innerSteps: [
                {
                    id: 1,
                    country: <FormattedMessage id="countriesAnalysis.country.A.title"/>,
                    label: 'A',
                    key: 'lilla',
                    icon: lillaIcon,
                    fallbackIcon: transparentIcon,
                    selectBoxes: [
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.smallSeatOpening.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.smallSeatOpening.description"/>,
                            key: 'smallSeatOpening',
                            icon: starIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.phoneContact.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.phoneContact.description"/>,
                            key: 'phoneContact',
                            icon: phoneIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.shortAcademicProgram.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.shortAcademicProgram.description"/>,
                            key: 'shortAcademicProgram',
                            icon: shortAcademicProgramIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.employerExploratoryMission.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.employerExploratoryMission.description"/>,
                            key: 'employerExploratoryMission',
                            icon: exchangeIcon,
                            checked: false,
                            score: 1,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.careerEvent.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.careerEvent.description"/>,
                            key: 'careerEvent',
                            icon: careerIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.professionalOrientation.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.professionalOrientation.description"/>,
                            key: 'professionalOrientation',
                            icon: orientationIcon,
                            checked: false,
                            score: 1,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.universityExploratoryMission.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.universityExploratoryMission.description"/>,
                            key: 'universityExploratoryMission',
                            icon: meetingIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.noActions.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.noActions.description"/>,
                            key: 'noActions',
                            icon: deleteIcon,
                            checked: false,
                            score: 0,
                        },
                    ]
                },
                {
                    id: 2,
                    country: <FormattedMessage id="countriesAnalysis.country.B.title"/>,
                    label: 'B',
                    key: 'giallo',
                    icon: gialloIcon,
                    fallbackIcon: transparentIcon,
                    selectBoxes: [
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.smallSeatOpening.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.smallSeatOpening.description"/>,
                            key: 'smallSeatOpening',
                            icon: starIcon,
                            checked: false,
                            score: 1,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.phoneContact.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.phoneContact.description"/>,
                            key: 'phoneContact',
                            icon: phoneIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.shortAcademicProgram.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.shortAcademicProgram.description"/>,
                            key: 'shortAcademicProgram',
                            icon: shortAcademicProgramIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.employerExploratoryMission.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.employerExploratoryMission.description"/>,
                            key: 'employerExploratoryMission',
                            icon: exchangeIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.careerEvent.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.careerEvent.description"/>,
                            key: 'careerEvent',
                            icon: careerIcon,
                            checked: false,
                            score: 1,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.professionalOrientation.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.professionalOrientation.description"/>,
                            key: 'professionalOrientation',
                            icon: orientationIcon,
                            checked: false,
                            score: 1,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.universityExploratoryMission.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.universityExploratoryMission.description"/>,
                            key: 'universityExploratoryMission',
                            icon: meetingIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.noActions.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.noActions.description"/>,
                            key: 'noActions',
                            icon: deleteIcon,
                            checked: false,
                            score: 0,
                        },
                    ]
                },
                {
                    id: 3,
                    country: <FormattedMessage id="countriesAnalysis.country.C.title"/>,
                    label: 'C',
                    key: 'verde',
                    icon: verdeIcon,
                    fallbackIcon: transparentIcon,
                    selectBoxes: [
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.smallSeatOpening.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.smallSeatOpening.description"/>,
                            key: 'smallSeatOpening',
                            icon: starIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.phoneContact.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.phoneContact.description"/>,
                            key: 'phoneContact',
                            icon: phoneIcon,
                            checked: false,
                            score: 1,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.shortAcademicProgram.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.shortAcademicProgram.description"/>,
                            key: 'shortAcademicProgram',
                            icon: shortAcademicProgramIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.employerExploratoryMission.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.employerExploratoryMission.description"/>,
                            key: 'employerExploratoryMission',
                            icon: exchangeIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.careerEvent.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.careerEvent.description"/>,
                            key: 'careerEvent',
                            icon: careerIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.professionalOrientation.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.professionalOrientation.description"/>,
                            key: 'professionalOrientation',
                            icon: orientationIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.universityExploratoryMission.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.universityExploratoryMission.description"/>,
                            key: 'universityExploratoryMission',
                            icon: meetingIcon,
                            checked: false,
                            score: 1,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.noActions.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.noActions.description"/>,
                            key: 'noActions',
                            icon: deleteIcon,
                            checked: false,
                            score: 0,
                        },
                    ]
                },
                {
                    id: 4,
                    country: <FormattedMessage id="countriesAnalysis.country.D.title"/>,
                    label: 'D',
                    key: 'azzurro',
                    icon: azzurroIcon,
                    fallbackIcon: transparentIcon,
                    selectBoxes: [
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.smallSeatOpening.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.smallSeatOpening.description"/>,
                            key: 'smallSeatOpening',
                            icon: starIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.phoneContact.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.phoneContact.description"/>,
                            key: 'phoneContact',
                            icon: phoneIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.shortAcademicProgram.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.shortAcademicProgram.description"/>,
                            key: 'shortAcademicProgram',
                            icon: shortAcademicProgramIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.employerExploratoryMission.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.employerExploratoryMission.description"/>,
                            key: 'employerExploratoryMission',
                            icon: exchangeIcon,
                            checked: false,
                            score: 1,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.careerEvent.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.careerEvent.description"/>,
                            key: 'careerEvent',
                            icon: careerIcon,
                            checked: false,
                            score: 1,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.professionalOrientation.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.professionalOrientation.description"/>,
                            key: 'professionalOrientation',
                            icon: orientationIcon,
                            checked: false,
                            score: 1,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.universityExploratoryMission.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.universityExploratoryMission.description"/>,
                            key: 'universityExploratoryMission',
                            icon: meetingIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.noActions.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.noActions.description"/>,
                            key: 'noActions',
                            icon: deleteIcon,
                            checked: false,
                            score: 0,
                        },
                    ]
                },
                {
                    id: 5,
                    country: <FormattedMessage id="countriesAnalysis.country.E.title"/>,
                    label: 'E',
                    key: 'rosa',
                    icon: rosaIcon,
                    fallbackIcon: transparentIcon,
                    selectBoxes: [
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.smallSeatOpening.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.smallSeatOpening.description"/>,
                            key: 'smallSeatOpening',
                            icon: starIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.phoneContact.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.phoneContact.description"/>,
                            key: 'phoneContact',
                            icon: phoneIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.shortAcademicProgram.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.shortAcademicProgram.description"/>,
                            key: 'shortAcademicProgram',
                            icon: shortAcademicProgramIcon,
                            checked: false,
                            score: 1,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.employerExploratoryMission.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.employerExploratoryMission.description"/>,
                            key: 'employerExploratoryMission',
                            icon: exchangeIcon,
                            checked: false,
                            score: 1,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.careerEvent.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.careerEvent.description"/>,
                            key: 'careerEvent',
                            icon: careerIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.professionalOrientation.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.professionalOrientation.description"/>,
                            key: 'professionalOrientation',
                            icon: orientationIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.universityExploratoryMission.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.universityExploratoryMission.description"/>,
                            key: 'universityExploratoryMission',
                            icon: meetingIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.noActions.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.noActions.description"/>,
                            key: 'noActions',
                            icon: deleteIcon,
                            checked: false,
                            score: 0,
                        },
                    ]
                },
                {
                    id: 6,
                    country: <FormattedMessage id="countriesAnalysis.country.F.title"/>,
                    label: 'F',
                    key: 'marrone',
                    icon: marroneIcon,
                    fallbackIcon: transparentIcon,
                    selectBoxes: [
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.smallSeatOpening.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.smallSeatOpening.description"/>,
                            key: 'smallSeatOpening',
                            icon: starIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.phoneContact.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.phoneContact.description"/>,
                            key: 'phoneContact',
                            icon: phoneIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.shortAcademicProgram.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.shortAcademicProgram.description"/>,
                            key: 'shortAcademicProgram',
                            icon: shortAcademicProgramIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.employerExploratoryMission.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.employerExploratoryMission.description"/>,
                            key: 'employerExploratoryMission',
                            icon: exchangeIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.careerEvent.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.careerEvent.description"/>,
                            key: 'careerEvent',
                            icon: careerIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.professionalOrientation.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.professionalOrientation.description"/>,
                            key: 'professionalOrientation',
                            icon: orientationIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.universityExploratoryMission.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.universityExploratoryMission.description"/>,
                            key: 'universityExploratoryMission',
                            icon: meetingIcon,
                            checked: false,
                            score: 0,
                        },
                        {
                            title: <FormattedMessage id="countriesAnalysis.selectBox.noActions.title"/>,
                            description: <FormattedMessage id="countriesAnalysis.selectBox.noActions.description"/>,
                            key: 'noActions',
                            icon: deleteIcon,
                            checked: false,
                            score: 1,
                        },
                    ]
                },
            ],
            considerations: '',
            score: 0,
            currentInnerStep: 0,
            numberOfSelections: 0,
            showDetailModal: false,
        };

        this.onClickNext = this.onClickNext.bind(this);
        this.onClickDetailButton = this.onClickDetailButton.bind(this);
        this.onCheckOption = this.onCheckOption.bind(this);
        this.onChangeConsiderations = this.onChangeConsiderations.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const playerToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);

        if(playerToken !== null) {
            this.props.fetchAnswerByPlayerToken(playerToken);
        } else {
            this.props.history.push(routes.ERROR);
        }
    }

    onCheckOption(ev){
        const index = parseInt(ev.target.getAttribute('data-index'));
        let currentScore = this.state.score;

        let innerSteps = [...this.state.innerSteps];

        let selectBoxes = [...this.state.innerSteps[this.state.currentInnerStep].selectBoxes];

        const lastStepIndex = selectBoxes.length - 1;

        if(index === lastStepIndex && !selectBoxes[index].checked){
            selectBoxes.map(item => item.checked = false);
        } else {
            selectBoxes[lastStepIndex].checked = index === lastStepIndex && selectBoxes[lastStepIndex].checked;
        }

        selectBoxes[index].checked = !selectBoxes[index].checked;
        currentScore += selectBoxes[index].checked ? selectBoxes[index].score : selectBoxes[index].score*-1;

        const numberOfSelections = countOccurrencesInArrayOfObject(selectBoxes, 'checked', true);

        this.setState({ innerSteps, numberOfSelections, score: currentScore });
    }

    updateAnswerData(data){
        //console.log("Data to send [Countries Analysis]: ", data);

        const playerToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);

        if(playerToken !== null) {
            const object = {
                ...data,
                playerToken: playerToken,
                progress: {
                    step: 'countries-analysis',
                    index: this.state.currentInnerStep,
                }
            };

            this.props.updateAnswerByPlayerToken(object);
        } else {
            this.setState({ error: 'error.game.removed' }, () => {
                setTimeout(() => {
                    this.props.history.push(routes.ERROR);
                }, 3000);
            });
        }
    }

    onClickNext(ev){
        ev.preventDefault();
        const currentInnerStep = this.state.currentInnerStep + 1;
        const { innerSteps, considerations, score } = this.state;

        const country = innerSteps.map(step => {
            return { [step[keyPropertyToStore]]: getObjectByArray(step.selectBoxes, 'checked') }
        });

        const data = {
            countriesAnalysis: {
                country,
                considerations,
                score,
            }
        };

        this.setState({
            currentInnerStep,
            numberOfSelections: 0
        }, () => {
            this.updateAnswerData(data);
        });
    }

    onClickDetailButton() {
        const showDetailModal = !this.state.showDetailModal;

        this.setState({ showDetailModal });
    }

    onChangeConsiderations(ev) {
        this.setState({
            considerations: ev.target.value,
        });
    };

    componentWillReceiveProps(nextProps, nextState){
        if(this.props.answer !== nextProps.answer &&
            nextProps.answer !== null){

            const { progress: { step, index }, countriesAnalysis } = nextProps.answer;

            const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);

            if(!(step === 'country' && index === 2) &&
                constants.ROUTES.indexOf(`/${step}`) < constants.ROUTES.indexOf(`/countries-analysis`)){
                this.props.history.push(`/challenge/${token}/${step}`);
            } else {
                if(step !== 'role' && step !== 'country' && step !== 'countries-analysis') {
                    this.props.history.push(`/challenge/${token}/${step}`);
                } else {
                    if(step === 'countries-analysis') {
                        if(index < 7){
                            const { score, country} = countriesAnalysis;

                            let innerSteps = [...this.state.innerSteps];

                            // eslint-disable-next-line
                            innerSteps.map((step, index) => {
                                // eslint-disable-next-line
                                step['selectBoxes'].map(box => {
                                    box.checked = country[index][step['key']][box.key];
                                    //console.log(step['key'], box, country[index][step['key']]);
                                });
                            });


                            this.setState({
                                innerSteps,
                                showConsiderations: index === 6,
                                score: score,
                                currentInnerStep: index,
                            })
                        } else {
                            this.props.history.push(routes.SEARCHING_COMPANIES.replace(':token', token));
                        }
                    }

                }
            }
        }

        if(this.props.isStored !== nextProps.isStored){
            if(nextProps.isStored){

                if(this.state.currentInnerStep > 6){
                    const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);
                    this.props.history.push(routes.SEARCHING_COMPANIES.replace(':token', token));
                } else {
                    const wrapper = document.getElementById('Wrapper');

                    this.setState({ animate: true }, () => {
                        scrollIntoView(wrapper, { block: 'start', behavior: 'smooth' });

                        setTimeout(() => {
                            this.setState({
                                animate: false,
                            })
                        }, 600);
                    });
                }
            }
        }

        if(this.props.error !== nextProps.error){
            setTimeout(() => {
                localStorage.clear();
                this.props.history.push(routes.ERROR);
            }, 5000);
        }
    }

    render() {

        const { animate, innerSteps, considerations, numberOfSelections, currentInnerStep, showDetailModal, error } = this.state;

        const selectBoxStyles = {
            width: '148px',
            margin: '0 10px 40px 0',
        };

        const selectBoxesTpl =
            currentInnerStep < 6 && innerSteps[currentInnerStep].selectBoxes.map((selectBox, index) =>
                <SelectBox
                    currentMainIndex={currentInnerStep}
                    index={index}
                    icon={selectBox.icon}
                    clicked={this.onCheckOption}
                    styles={selectBoxStyles}
                    key={index}
                    title={selectBox.title}
                    description={selectBox.description}
                    checked={selectBox.checked} />
            );

        const questionStyles = {
            padding: '16px 0'
        };

        const questionTitleStyles = {
            margin: '0 10px 5px 10px',
            fontSize: '16px',
            fontWeight: '700'
        };

        const questionDescriptionStyles = {
            margin: '0 10px',
            fontSize: '16px',
            fontWeight: '400',
            color: '#FFF'
        };

        const detailButtonStyles = {
            fontSize: '12px',
            margin: '0 0 0 20px',
            padding: '5px 25px 5px 15px',
        };

        const nextButtonStyles = {
            minWidth: '130px',
            margin: '0',
            padding: '10px 15px'
        };

        const contentStyles = {
            margin: 0,
        };

        const modalBlocksStyles = {
            margin: '0 0 40px 0'
        };

        const modalParagraphStyles = {
            color: '#b4b4b4',
            padding: 0,
            margin: '0 0 40px 0'
        };

        const chartStyles = {
            margin: '0 0 30px 0',
            width: '100%'
        };

        let chartsTpl = null;

        if(currentInnerStep < innerSteps.length) {

            switch (innerSteps[currentInnerStep].label) {
                case 'A':
                    chartsTpl =
                        <>
                            <img
                                style={chartStyles}
                                src={A_Chart1}
                                alt="chart 1" />
                            <img
                                style={chartStyles}
                                src={A_Chart2}
                                alt="chart 2" />
                            <img
                                style={chartStyles}
                                src={A_Chart3}
                                alt="chart 3" />
                        </>;
                    break;
                case 'B':
                    chartsTpl =
                        <>
                            <img
                                style={chartStyles}
                                src={B_Chart1}
                                alt="chart 1" />
                            <img
                                style={chartStyles}
                                src={B_Chart2}
                                alt="chart 2" />
                            <img
                                style={chartStyles}
                                src={B_Chart3}
                                alt="chart 3" />
                        </>;
                    break;
                case 'C':
                    chartsTpl =
                        <>
                            <img
                                style={chartStyles}
                                src={C_Chart1}
                                alt="chart 1" />
                            <img
                                style={chartStyles}
                                src={C_Chart2}
                                alt="chart 2" />
                            <img
                                style={chartStyles}
                                src={C_Chart3}
                                alt="chart 3" />
                        </>;
                    break;
                case 'D':
                    chartsTpl =
                        <>
                            <img
                                style={chartStyles}
                                src={D_Chart1}
                                alt="chart 1" />
                            <img
                                style={chartStyles}
                                src={D_Chart2}
                                alt="chart 2" />
                            <img
                                style={chartStyles}
                                src={D_Chart3}
                                alt="chart 3" />
                        </>;
                    break;
                case 'E':
                    chartsTpl =
                        <>
                            <img
                                style={chartStyles}
                                src={E_Chart1}
                                alt="chart 1" />
                            <img
                                style={chartStyles}
                                src={E_Chart2}
                                alt="chart 2" />
                            <img
                                style={chartStyles}
                                src={E_Chart3}
                                alt="chart 3" />
                        </>;
                    break;
                case 'F':
                    chartsTpl =
                        <>
                            <img
                                style={chartStyles}
                                src={F_Chart1}
                                alt="chart 1" />
                            <img
                                style={chartStyles}
                                src={F_Chart2}
                                alt="chart 2" />
                            <img
                                style={chartStyles}
                                src={F_Chart3}
                                alt="chart 3" />
                        </>;
                    break;
                default: chartsTpl = null;
            }
        }

        const disabledNext = (currentInnerStep < 6 && (numberOfSelections === 0 || numberOfSelections > 3)) || (currentInnerStep === 6 && considerations.trim() === '');

        return (
            <TwoColumnsWrapper>
                <Sidebar
                    currentStep={5}
                    stepIndex={1}/>
                <Steps twoColumns={true} transition={animate}>
                    <div className={[classes.Step, classes["Step--withSidebar"]].join(' ')} id="Wrapper">
                        <Stepper currentStep={currentInnerStep} items={innerSteps} />
                        <Separator styles={{margin: '0 10px', width: 'calc(100% - 20px)'}} />
                        {
                            currentInnerStep < 6 ?
                                <div
                                    className={
                                        [
                                            classes["Step-content"],
                                            classes["is-visible"],
                                        ].join(' ')}
                                    style={contentStyles}>
                                    <Question
                                        styles={questionStyles}
                                        titleStyles={questionTitleStyles}
                                        descriptionStyles={questionDescriptionStyles}
                                        title={<FormattedMessage id="countriesAnalysis.question.title"/>}
                                        description={<FormattedMessage id="countriesAnalysis.question.description"/>} />
                                    <Separator styles={{margin: '0 10px', width: 'calc(100% - 20px)'}} />
                                    <label
                                        className={classes["Step-label"]}
                                        style={{margin: '20px 10px 10px'}}>
                                        <FormattedMessage id="countriesAnalysis.label.country"/>
                                    </label>
                                    <FlexBox styles={{justifyContent: 'flex-start', padding: '0 10px'}}>
                                        <label className={[classes["Step-label"], classes["Step-label--lg"]].join(' ')}>
                                            <FormattedMessage id={`countriesAnalysis.country.${innerSteps[currentInnerStep].label}.title`}/>
                                        </label>
                                        <Button
                                            btnType="secondary"
                                            icon={detailIcon}
                                            styles={detailButtonStyles}
                                            clicked={this.onClickDetailButton}>
                                            <FormattedMessage id="countriesAnalysis.button.detail"/>
                                        </Button>
                                    </FlexBox>
                                    <FlexBox
                                        styles={{justifyContent: 'flex-start', padding: '30px 0 20px'}}>
                                        { selectBoxesTpl }
                                    </FlexBox>
                                </div> :
                                <div
                                    className={
                                        [
                                            classes["Step-content"],
                                            classes["is-visible"],
                                        ].join(' ')}
                                    style={{...contentStyles, padding: '30px 0'}}>
                                    <Question
                                        styles={{...questionStyles, maxWidth: '579px'}}
                                        titleStyles={questionDescriptionStyles}
                                        title={<FormattedMessage id="countriesAnalysis.question.considerations"/>} />
                                    <Textarea
                                        changed={this.onChangeConsiderations}
                                        value={considerations}
                                        placeholderId="countriesAnalysis.placeholder.considerations"
                                        styles={{margin: '0 10px 20px'}} />
                                </div>
                        }
                        {
                            (this.props.error || error) &&
                                <Feedback type="error" styles={{ margin: '40px auto'}}>
                                    { error ? <FormattedMessage id={error}/> : <FormattedMessage id="error.generic"/> }
                                </Feedback>
                        }
                        <Separator styles={{margin: '0 10px', width: 'calc(100% - 20px)'}} />
                        <div className={classes["Step-footer"]} style={{margin: '0 10px'}}>
                            <Square styles={{display: 'none'}} large>{ numberOfSelections }</Square>
                            <Question
                                titleStyles={{fontSize: '16px', margin: 0 }}
                                title={<FormattedMessage id="general.noBack"/>} />
                            <Button
                                hoverEffect={true}
                                step={currentInnerStep < 6 ? `${currentInnerStep + 1}/${innerSteps.length}` : null}
                                icon={nextButtonIcon}
                                disabled={disabledNext}
                                styles={nextButtonStyles}
                                clicked={this.onClickNext}>
                                <FormattedMessage id="general.next"/>
                            </Button>
                        </div>
                        {
                            showDetailModal &&
                                <Modal
                                    onModalCloseClicked={this.onClickDetailButton}>
                                    <div className={classes.Step}>
                                        <label
                                            style={modalBlocksStyles}
                                            className={[classes["Step-label"], classes["Step-label--secondary"], classes["Step-label--lg"]].join(' ')}>
                                            <FormattedMessage id={`countriesAnalysis.country.${innerSteps[currentInnerStep].label}.title`}/>
                                        </label>
                                        <label
                                            style={modalBlocksStyles}
                                            className={[classes["Step-label"],classes["Step-label--tertiary"], classes["Step-label--md"]].join(' ')}>
                                            <FormattedMessage id="countriesAnalysis.country.marketOverview.label"/>
                                        </label>
                                        <Paragraph
                                            styles={modalParagraphStyles}
                                            textId={`countriesAnalysis.country.${innerSteps[currentInnerStep].label}.marketOverview`}/>
                                        {
                                            chartsTpl
                                        }
                                        <label
                                            style={modalBlocksStyles}
                                            className={[classes["Step-label"],classes["Step-label--tertiary"], classes["Step-label--md"]].join(' ')}>
                                            <FormattedMessage id="countriesAnalysis.country.relationship.label"/>
                                        </label>
                                        <Paragraph
                                            styles={modalParagraphStyles}
                                            textId={`countriesAnalysis.country.${innerSteps[currentInnerStep].label}.relationship`}/>
                                        <label
                                            style={modalBlocksStyles}
                                            className={[classes["Step-label"],classes["Step-label--tertiary"], classes["Step-label--md"]].join(' ')}>
                                            <FormattedMessage id="countriesAnalysis.country.mainData.label"/>
                                        </label>
                                        <Paragraph
                                            styles={modalParagraphStyles}
                                            textId={`countriesAnalysis.country.${innerSteps[currentInnerStep].label}.mainData`}/>
                                    </div>
                                </Modal>
                        }
                    </div>
                </Steps>
                <Footer />
            </TwoColumnsWrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        answer: state.answers.selected,
        isLoading: state.answers.loading,
        isSaving: state.answers.saving,
        isStored: state.answers.stored,
        error: state.answers.error
    }
};

const mapDispatchToProps = {
    fetchAnswerByPlayerToken,
    updateAnswerByPlayerToken
};

export default connect(mapStateToProps, mapDispatchToProps)(StepCountriesAnalysis);
