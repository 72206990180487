import React, { Component } from 'react';
import classes from './styles.module.scss';

import Banner from "../../../components/Banner";
import Paragraph from "../../../components/Paragraph";

import Header from "../../../components/Header";

import monitorIcon from "../../../assets/icons/monitor.svg";

class StepMobile extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {

        const bannerStyles = {
            margin: '50px auto'
        };

        /*
        const titleStyles = {
            margin: '20px auto 10px',
        };*/

        const imgStyle = {
            margin: '20px auto 30px'
        };

        return (
            <div style={{minHeight: '500px'}} className={classes.Step}>
                <Header />
                <div
                    className={
                        [
                            classes["Step-content"],
                            classes["is-visible"],
                        ].join(' ')}>
                    <div style={{textAlign: 'center'}}>
                        <Banner styles={bannerStyles} />
                        <img style={imgStyle} src={monitorIcon} alt={'no-mobile'} />
                        {
                            /*

                        <Title
                            styles={titleStyles}
                            size={'xLarge'}
                            alignment="center">
                            <FormattedMessage id="mobile.title"/>
                        </Title>
                             */
                        }
                        {
                            <Paragraph
                                alignment="center"
                                textId={'mobile.description'}/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default StepMobile;
