import React from 'react';
import classes from './styles.module.scss';
import PropTypes from "prop-types";

const loader = (props) => {
    const { styles } = props;

    return (
        <div className={classes.Loader} style={styles}>
            <div className={classes["Loader-circle"]} />
            <div className={classes["Loader-circle"]} />
            <div className={classes["Loader-circle"]} />
            <div className={classes["Loader-circle"]} />
        </div>

    )
};


loader.propTypes = {
    styles: PropTypes.object,
};

loader.defaultProps = {
    circles: 3
};

export default loader;
