import * as actionTypes from '../actions/actionTypes';

export const PLAYERS_DEFAULT_STATE = {
    loading: false,
    saving: false,
    saved: false,
    removing: false,
    removed: null,
    error: '',
    items: [],
    selected: null,
    updated: false,
    created: false,
};

export default function players (state = PLAYERS_DEFAULT_STATE, action) {

    switch (action.type) {
        case actionTypes.FETCH_PLAYER: {
            return {...state, selected: null, loading: true, error: ''};
        }

        case actionTypes.FETCH_PLAYER_SUCCESS: {
            return {...state, selected: action.player, loading: false, error: ''};
        }

        case actionTypes.FETCH_PLAYER_FAIL: {
            return {...state, selected: null, loading: false, error: action.error};
        }
        case actionTypes.FETCH_PLAYER_BY_TOKEN: {
            return {...state, selected: null, loading: true, error: ''};
        }

        case actionTypes.FETCH_PLAYER_BY_TOKEN_SUCCESS: {
            return {...state, selected: action.player, loading: false, error: ''};
        }

        case actionTypes.FETCH_PLAYER_BY_TOKEN_FAIL: {
            return {...state, selected: null, loading: false, error: action.error};
        }

        case actionTypes.FETCH_PLAYERS: {
            return {...state, items: [], loading: true, error: ''};
        }

        case actionTypes.FETCH_PLAYERS_SUCCESS: {
            return {...state, items: action.players, loading: false, error: ''};
        }

        case actionTypes.FETCH_PLAYERS_FAIL: {
            return {...state, items: [], loading: false, error: action.error};
        }

        case actionTypes.ADD_PLAYER:
            return {...state, selected: null, saving: true, created: false, error: ''};

        case actionTypes.ADD_PLAYER_SUCCESS:
            return {...state, selected: action.player, created: true, saving: false, error: ''};

        case actionTypes.ADD_PLAYER_FAIL:
            return {...state, selected: null, saving: false, error: action.error};

        case actionTypes.UPDATE_PLAYER:
            return {...state, selected: null, saving: true, updated: false, error: ''};

        case actionTypes.UPDATE_PLAYER_SUCCESS:
            return {...state, selected: action.player, updated: true, saving: false, error: ''};

        case actionTypes.UPDATE_PLAYER_FAIL:
            return {...state, updated: false, saving: false, error: action.error};

        case actionTypes.UPDATE_PLAYER_BY_TOKEN:
            return {...state, selected: null, saving: true, updated: false, error: ''};

        case actionTypes.UPDATE_PLAYER_BY_TOKEN_SUCCESS:
            return {...state, selected: action.player, updated: true, saving: false, error: ''};

        case actionTypes.UPDATE_PLAYER_BY_TOKEN_FAIL:
            return {...state, updated: false, saving: false, error: action.error};

        case actionTypes.DELETE_PLAYER:
            return {...state, removing: true, removed: null, error: ''};

        case actionTypes.DELETE_PLAYER_SUCCESS:
            return {...state, removed: action.player, removing: false, error: ''};

        case actionTypes.DELETE_PLAYER_FAIL:
            return {...state, removed: null, removing: false, error: action.error};

        default:
            return state
    }
}
