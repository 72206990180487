 export default {
    LOCAL_STORAGE: {
        KEYS: {
            COUNTRY: 'country',
            STEP: 'step',
            LAST_SAVED_STEP: 'last_saved_step',
            USER_ID: 'user_id',
            USER_EMAIL: 'user_email',
            ANSWER_ID: 'answer_id',
            STATUS: 'status',
            PLAYER_TOKEN: 'player_token',
            PLAYER_SESSION: 'player_session',
        }
    },
    SCORE: {
        COUNTRY: {
            MAX: 14,
        },
        COUNTRY_ANALYSIS: {
            MAX: 13,
        },
        COMPANIES: {
            MAX: 8
        }
    },
    MONTHS: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
    SUPPORT_ANIMATION: true,
    ROUTES: [
        '/',
        '/profile',
        '/role',
        '/country',
        '/searching-countries',
        '/countries-analysis',
        '/searching-companies',
        '/companies',
        '/meeting',
        '/thank-you',
        '/error',
        '/export9562egf'
    ],
    TWO_COLUMNS_ROUTES: ['/country', '/countriesAnalysis', '/companies', '/meeting'],
    VISITORS_ROUTES: ['/', '/profile', '/error', '/export9562egf'],
    USERS_ROUTES: ['/role', '/country'],
    ANSWERS_STEPS: [
        '/searching-countries',
        '/countries-analysis',
        '/searching-companies',
        '/companies',
        '/meeting',
        '/thank-you',
    ],
    ENABLE_USER_CREATION: true,
    TWO_COLUMNS_STEPS: [3, 5, 7, 8],
}
