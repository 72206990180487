import React from 'react';
import classes from './styles.module.scss';
import PropTypes from "prop-types";

const square = (props) => {
    const { styles, children, active, clicked, index, large, shadow } = props;

    const buttonStyles = {
        ...styles,
        cursor: clicked ? 'pointer' : 'default',
    };

    return (
        <button
            style={buttonStyles}
            onClick={clicked}
            data-index={index}
            className={[classes.Square, active ? classes["is-active"] : '', large ? classes["Square--large"] : '', shadow ? classes["Square--shaded"] : ''].join(' ')}>
            { children }
        </button>

    )
};


square.propTypes = {
    index: PropTypes.number,
    styles: PropTypes.object,
    children: PropTypes.node,
    checked: PropTypes.bool,
    clicked: PropTypes.func,
    large: PropTypes.bool,
    shadow: PropTypes.bool,
};

square.defaultProps = {
    shadow: true,
};

export default square;
