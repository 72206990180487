import React from 'react';
import PropTypes from 'prop-types';
import classes from './styles.module.scss';

const iconLabel = (props) => {
    const { icon, label, styles } = props;

    return (
        <div className={classes.IconLabel} style={styles}>
            <img className={classes["IconLabel-image"]} src={icon} alt={'status'} />
            {
                label &&
                    <span className={classes["IconLabel-label"]}>{ label }</span>
            }
        </div>
    )
};

iconLabel.propTypes = {
    icon: PropTypes.node.isRequired,
    label: PropTypes.object,
    styles: PropTypes.object
};

export default iconLabel;
