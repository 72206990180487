import React from 'react';
import classes from './styles.module.scss';
import { FormattedMessage } from 'react-intl';
import PropTypes from "prop-types";

const input = (props) => {
    const { type, disabled, name, value, changed, clicked, placeholderId, styles, capitalize, error } = props;

    return (
        <FormattedMessage id={placeholderId || 'general.emptyMessage'} defaultMessage={''}>
            {
                placeholder =>
                    <input
                        placeholder={placeholder}
                        style={styles}
                        name={name}
                        className={[classes.Input, disabled ? classes["is-disabled"] : '', capitalize ? classes["Input--capitalize"] : '', error ? classes["is-invalid"] : ''].join(' ')}
                        disabled={disabled}
                        value={value}
                        type={type}
                        onChange={changed}
                        autoComplete="off"
                        onClick={clicked}/>
            }
        </FormattedMessage>

    )
};


input.propTypes = {
    type: PropTypes.oneOf(['text', 'number', 'email', 'phone']),
    name: PropTypes.string,
    placeholderId: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    clicked: PropTypes.func,
    changed: PropTypes.func,
    styles: PropTypes.object,
    capitalize: PropTypes.bool,
    error: PropTypes.bool,
};

input.defaultProps = {
    type: 'text',
    value: '',
    error: false,
};

export default input;
