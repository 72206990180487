import React from 'react';
import classes from './styles.module.scss';
import PropTypes from "prop-types";
import Square from "../Square";
import checkIcon from '../../../assets/icons/check.svg';

const checkbox = (props) => {
    const { styles, children, checked, large, clicked, index } = props;

    return (

        <div className={classes.Checkbox} style={styles}>
            <Square
                clicked={clicked}
                active={checked}
                large={large}
                index={index}>
                {
                    checked &&
                    <img className={classes["Checkbox-icon"]} src={checkIcon} alt="check" />
                }
            </Square>
            <label className={classes["Checkbox-label"]}>{ children }</label>
        </div>

    )
};


checkbox.propTypes = {
    index: PropTypes.number,
    styles: PropTypes.object,
    children: PropTypes.object,
    checked: PropTypes.bool,
    large: PropTypes.bool,
    clicked: PropTypes.func,
};

checkbox.defaultProps = {
    large: false,
};

export default checkbox;
