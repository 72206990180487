import React from 'react';
import PropTypes from 'prop-types';
import classes from './styles.module.scss';

const emptyBox = (props) => (
    <div className={classes.EmptyBox} style={props.styles}>
        <div>{ props.children }</div>
    </div>
);

emptyBox.propTypes = {
    styles: PropTypes.object,
    children: PropTypes.node,
};

export default emptyBox;
