import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { getArrayByArrayOfObjects } from "../../../shared/helpers";
import {fetchAnswerByPlayerToken, updateAnswerByPlayerToken} from "../../../store/actions/answers";
import * as routes from "../../../shared/routes";
import connect from "react-redux/es/connect/connect";
import classes from './styles.module.scss';
import Button from "../../../components/UI/Button";
import Separator from "../../../components/UI/Separator";
import FlexBox from "../../FlexBox";
import nextButtonIcon from '../../../assets/icons/next.svg';
import scrollIntoView from "scroll-into-view-if-needed";
import DragAndDrop from "../../../components/UI/DragAndDrop";
import Question from "../../../components/Question";
import Textarea from "../../../components/UI/Textarea";
import Feedback from "../../../components/Feedback";
import constants from "../../../shared/constants";
import TwoColumnsWrapper from "../../TwoColumnsWrapper";
import Sidebar from "../../../components/Sidebar";
import Steps from "../";
import Footer from "../../../components/Footer";

// Buildings
import biancospinoIcon from '../../../assets/icons/buildings/biancospino.png';
import ginestraIcon from '../../../assets/icons/buildings/ginestra.png';
import malvaIcon from '../../../assets/icons/buildings/malva.png';
import margheritaIcon from '../../../assets/icons/buildings/margherita.png';
import narcisoIcon from '../../../assets/icons/buildings/narciso.png';
import orchideaIcon from '../../../assets/icons/buildings/orchidea.png';
import primulaIcon from '../../../assets/icons/buildings/primula.png';
import trifoglioIcon from '../../../assets/icons/buildings/trifoglio.png';

const keyPropertyToStore = 'key';

class StepCompanies extends Component {
    constructor(props) {
        super(props);

        this.state = {
            animate: false,
            error: '',
            dbOrder: null,
            boxes: [
                {
                    id: 1,
                    label: 'A',
                    key: 'margherita',
                    icon: margheritaIcon,
                    title: <FormattedMessage id="company.A.title"/>,
                    content: <FormattedMessage id="company.A.content"/>,
                    descriptionId: 'company.A.description',
                    showDescription: false,
                    indexToScore: [1, 2],
                },
                {
                    id: 2,
                    label: 'B',
                    key: 'biancospino',
                    icon: biancospinoIcon,
                    title: <FormattedMessage id="company.B.title"/>,
                    content: <FormattedMessage id="company.B.content"/>,
                    descriptionId: 'company.B.description',
                    showDescription: false,
                    indexToScore: [1, 2],
                },
                {
                    id: 3,
                    label: 'C',
                    key: 'ginestra',
                    icon: ginestraIcon,
                    title: <FormattedMessage id="company.C.title"/>,
                    content: <FormattedMessage id="company.C.content"/>,
                    descriptionId: 'company.C.description',
                    showDescription: false,
                    indexToScore: [3, 4, 5],
                },
                {
                    id: 4,
                    label: 'D',
                    key: 'malva',
                    icon: malvaIcon,
                    title: <FormattedMessage id="company.D.title"/>,
                    content: <FormattedMessage id="company.D.content"/>,
                    descriptionId: 'company.D.description',
                    showDescription: false,
                    indexToScore: [0],
                },
                {
                    id: 5,
                    label: 'E',
                    key: 'narciso',
                    icon: narcisoIcon,
                    title: <FormattedMessage id="company.E.title"/>,
                    content: <FormattedMessage id="company.E.content"/>,
                    descriptionId: 'company.E.description',
                    showDescription: false,
                    indexToScore: [6, 7],
                },
                {
                    id: 6,
                    label: 'F',
                    key: 'orchidea',
                    icon: orchideaIcon,
                    title: <FormattedMessage id="company.F.title"/>,
                    content: <FormattedMessage id="company.F.content"/>,
                    descriptionId: 'company.F.description',
                    showDescription: false,
                    indexToScore: [3, 4, 5],
                },
                {
                    id: 7,
                    label: 'G',
                    key: 'primula',
                    icon: primulaIcon,
                    title: <FormattedMessage id="company.G.title"/>,
                    content: <FormattedMessage id="company.G.content"/>,
                    descriptionId: 'company.G.description',
                    showDescription: false,
                    indexToScore: [6, 7],
                },
                {
                    id: 8,
                    label: 'H',
                    key: 'trifoglio',
                    icon: trifoglioIcon,
                    title: <FormattedMessage id="company.H.title"/>,
                    content: <FormattedMessage id="company.H.content"/>,
                    descriptionId: 'company.H.description',
                    showDescription: false,
                    indexToScore: [3, 4, 5],
                },
            ],
            selected: [],
            showConsiderations: false,
            considerations: '',
            score: 0,
        };

        this.onClickNext = this.onClickNext.bind(this);
        this.onChangeSelected = this.onChangeSelected.bind(this);
        this.onChangeConsiderations = this.onChangeConsiderations.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const playerToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);

        if(playerToken !== null) {
            this.props.fetchAnswerByPlayerToken(playerToken);
        } else {
            this.props.history.push(routes.ERROR);
        }
    }

    onChangeSelected(selected){
        this.setState({ selected });
        //console.log(selected);
    }

    onChangeConsiderations(ev) {
        this.setState({
            considerations: ev.target.value,
        });
    };

    updateAnswerData(data){
        //console.log("Data to send [Company]: ", data);

        const playerToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);

        if(playerToken !== null) {
            const object = {
                ...data,
                playerToken: playerToken,
            };

            this.props.updateAnswerByPlayerToken(object);
        } else {
            this.setState({ error: 'error.game.removed' }, () => {
                setTimeout(() => {
                    this.props.history.push(routes.ERROR);
                }, 3000);
            });
        }
    }

    getScoreByOrder(){
        let score = 0;

        const selected = [...this.state.selected];

        // eslint-disable-next-line
        selected.map((item, index) => {
            if(item['indexToScore'].indexOf(index) > -1){
                score++;
            }
        });

        return score;
    }

    onClickNext(ev){
        ev.preventDefault();

        const { selected, considerations, dbOrder } = this.state;

        const data = {
            companies: {
                order: dbOrder !== null ? dbOrder : getArrayByArrayOfObjects(selected, keyPropertyToStore),
                considerations,
                score: dbOrder !== null ? this.state.score : this.getScoreByOrder(),
            },
            progress: {
                step: 'companies',
                index: this.state.showConsiderations ? 2 : 1,
            },
        };

        this.updateAnswerData(data);
    }

    componentWillReceiveProps(nextProps, nextState){

        if(this.props.answer !== nextProps.answer && nextProps.answer){

            const { progress: { step, index }, companies } = nextProps.answer;

            const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);

            if(!(step === 'countries-analysis' && index === 7) &&
                constants.ROUTES.indexOf(`/${step}`) < constants.ROUTES.indexOf(`/companies`)){
                this.props.history.push(`/challenge/${token}/${step}`);
            } else {
                if (step !== 'role' && step !== 'country' && step !== 'countries-analysis' && step !== 'companies') {

                    this.props.history.push(`/challenge/${token}/${step}`);
                } else {
                    if (step === 'companies') {

                        //console.log(companies);
                        if (index < 2) {
                            this.setState({
                                dbOrder: companies.order,
                                score: companies.score,
                                showConsiderations: index === 1,
                            })
                        } else {
                            this.props.history.push(routes.MEETING.replace(':token', token));
                        }
                    }
                }
            }
        }

        if(this.props.isStored !== nextProps.isStored){

            if(nextProps.isStored){
                if(!this.state.showConsiderations){
                    const wrapper = document.getElementById('Wrapper');

                    this.setState({ animate: false }, () => {
                        scrollIntoView(wrapper, { block: 'start', behavior: 'smooth' });

                        this.setState({
                            animate: true,
                            showConsiderations: true,
                        });
                    });
                } else {
                    const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);
                    this.props.history.push(routes.MEETING.replace(':token', token));
                }
            }
        }

        if(this.props.error !== nextProps.error){
            setTimeout(() => {
                localStorage.clear();
                this.props.history.push(routes.ERROR);
            }, 5000);
        }
    }

    render() {

        const { animate, boxes, selected, showConsiderations, considerations, error } = this.state;

        const nextButtonStyles = {
            width: '130px',
            margin: '0',
            padding: '10px 15px'
        };

        const disabledNext = (!showConsiderations && selected.length < 8) || (showConsiderations && considerations.trim() === '');

        const contentClass = {
            margin: 0,
        };

        const questionStyles = {
            padding: '0 5px',
            margin: '0 0 30px'
        };

        const questionDescriptionStyles = {
            margin: '0 10px',
            fontSize: '16px',
            fontWeight: '400',
            color: '#FFF'
        };

        return (
            <TwoColumnsWrapper>
                <Sidebar
                    currentStep={7}
                    stepIndex={2}/>
                <Steps twoColumns={true} transition={animate}>
                    <div id="Wrapper" className={[classes.Step, classes["Step--withSidebar"]].join(' ')} style={{padding: '60px 10px 90px'}}>
                        {
                            !showConsiderations ?
                                <div
                                    className={
                                        [
                                            classes["Step-content"],
                                            classes["is-visible"],
                                        ].join(' ')}
                                    style={contentClass}>
                                    <FlexBox styles={{ justifyContent: 'space-between', margin: '0 0 30px'}}>
                                        <DragAndDrop
                                            changedSelected={this.onChangeSelected}
                                            titles={['company.question.title1', 'company.question.title2']}
                                            items={boxes}/>
                                    </FlexBox>
                                </div> :
                                <div
                                    className={
                                        [
                                            classes["Step-content"],
                                            classes["is-visible"],
                                        ].join(' ')}
                                    style={{...contentClass, padding: '30px 0'}}>
                                    <Question
                                        styles={{...questionStyles, maxWidth: '579px'}}
                                        titleStyles={questionDescriptionStyles}
                                        title={<FormattedMessage id="company.question.considerations"/>} />
                                    <Textarea
                                        changed={this.onChangeConsiderations}
                                        value={considerations}
                                        placeholderId="company.placeholder.considerations"
                                        styles={{margin: '0 10px 20px'}} />
                                </div>
                        }
                        {
                            (this.props.error || error) &&
                                <Feedback type="error" styles={{ margin: '40px auto'}}>
                                    { error ? <FormattedMessage id={error}/> : <FormattedMessage id="error.generic"/> }
                                </Feedback>
                        }
                        <Separator />
                        <div className={classes["Step-footer"]}>
                            <Question
                                titleStyles={{fontSize: '16px', margin: 0 }}
                                title={<FormattedMessage id="general.noBack"/>} />
                            <Button
                                hoverEffect={true}
                                icon={nextButtonIcon}
                                disabled={disabledNext}
                                styles={nextButtonStyles}
                                clicked={this.onClickNext}>
                                <FormattedMessage id="general.next"/>
                            </Button>
                        </div>
                    </div>
                </Steps>
                <Footer />
            </TwoColumnsWrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        answer: state.answers.selected,
        isLoading: state.answers.loading,
        isSaving: state.answers.saving,
        isStored: state.answers.stored,
        error: state.answers.error
    }
};

const mapDispatchToProps = {
    fetchAnswerByPlayerToken,
    updateAnswerByPlayerToken
};

export default connect(mapStateToProps, mapDispatchToProps)(StepCompanies);
