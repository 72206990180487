import * as actionTypes from '../actions/actionTypes';

export const USERS_DEFAULT_STATE = {
    loading: false,
    saving: false,
    stored: false,
    error: '',
    items: [],
    selected: null,
};

export default function users (state = USERS_DEFAULT_STATE, action) {

    switch (action.type) {
        case actionTypes.FETCH_USER: {
            return {...state, selected: null, loading: true, stored: false, error: ''};
        }

        case actionTypes.FETCH_USER_SUCCESS: {
            return {...state, selected: action.user, loading: false, error: ''};
        }

        case actionTypes.FETCH_USER_FAIL: {
            return {...state, selected: null, loading: false, error: action.error};
        }

        case actionTypes.FETCH_USERS: {
            return {...state, items: [], loading: true, error: ''};
        }

        case actionTypes.FETCH_USERS_SUCCESS: {
            return {...state, items: action.users, loading: false, error: ''};
        }

        case actionTypes.FETCH_USERS_FAIL: {
            return {...state, items: [], loading: false, error: action.error};
        }

        case actionTypes.ADD_USER:
            return {...state, selected: null, saving: true, stored: false, error: ''};

        case actionTypes.ADD_USER_SUCCESS:
            return {...state, selected: action.user, stored: true, saving: false, error: ''};

        case actionTypes.ADD_USER_FAIL:
            return {...state, selected: null, stored: false, saving: false, error: action.error};

        case actionTypes.UPDATE_USER:
            return {...state, selected: null, saving: true, stored: false, error: ''};

        case actionTypes.UPDATE_USER_SUCCESS:
            return {...state, selected: action.user, stored: true, saving: false, error: ''};

        case actionTypes.UPDATE_USER_FAIL:
            return {...state, stored: false, saving: false, error: action.error};

        default:
            return state
    }
}
