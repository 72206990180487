import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import classes from './styles.module.scss';
import constants from "../../shared/constants";
import connect from "react-redux/es/connect/connect";
import * as routes from '../../shared/routes';
import PrivateRoute from "../../hocs/privateRoute";
import { fetchAnswer } from "../../store/actions/answers";
import { fetchSettings } from "../../store/actions/settings";

// Import Admin
import Admin from "../../components/Admin";

// Import Steps
//import StepLogin from "../Steps/Step/StepLogin";
import StepWelcome from "../Steps/Step/StepWelcome";
import StepHome from "../Steps/Step/StepHome";
import StepProfile from "../Steps/Step/StepProfile";
import StepRole from "../Steps/Step/StepRole";
import StepCountry from "../Steps/Step/StepCountry";
import StepSearchCountries from "../Steps/Step/StepSearchCountries";
import StepCountriesAnalysis from "../Steps/Step/StepCountriesAnalysis";
import StepSearchCompanies from "../Steps/Step/StepSearchCompanies";
import StepCompanies from "../Steps/Step/StepCompanies";
import StepMeeting from "../Steps/Step/StepMeeting";
import StepThankYou from "../Steps/Step/StepThankYou";
//import StepExport from "../Steps/Step/StepExport";
import StepError from "../Steps/Step/StepError";
import StepClosed from "../Steps/Step/StepClosed";
import StepMobile from "../Steps/Step/StepMobile";


class content extends Component {

    constructor(props) {
        super(props);

        this.state = {
            gameStatus: 'online',
            dataExport: false,
            twoColumnsSteps: constants.TWO_COLUMNS_STEPS,
        };
    }

    componentDidMount() {
        this.props.fetchSettings();
    }

    componentWillReceiveProps(nextProps, nextState){
        if(nextProps.settings !== this.props.settings &&
            nextProps.settings){
            if(nextProps.settings.hasOwnProperty('game')){
                const { game: { status } } = nextProps.settings;

                if(status !== 'online'){
                    localStorage.setItem(constants.LOCAL_STORAGE.KEYS.STATUS, 'service.closed');

                    this.setState({ gameStatus: status });
                }
            }
        }
    }

    render() {
        const { gameStatus } = this.state;

        return (
            <Router>
                <div className={[classes.Content].join(' ')}>
                    <div className={classes["Content-mobile"]}>
                        <Route component={StepMobile} />
                    </div>
                    <div className={classes["Content-wrapper"]}>
                        {
                            gameStatus === 'online' ?
                                <Switch>
                                    {/* <Route path={routes.LOGIN} component={StepLogin} /> */}
                                    <Route path={routes.ADMIN} component={Admin} />
                                    <Route exact path={routes.HOME} component={StepHome} />
                                    <Route exact path={routes.WELCOME} component={StepWelcome} />
                                    <Route exact path={routes.PROFILE} component={StepProfile} />
                                    <PrivateRoute exact path={routes.ROLE} component={StepRole} />
                                    <PrivateRoute exact path={routes.COUNTRY} component={StepCountry} />
                                    <PrivateRoute exact path={routes.SEARCHING_COUNTRIES} component={StepSearchCountries} />
                                    <PrivateRoute exact path={routes.COUNTRIES_ANALYSIS} component={StepCountriesAnalysis} />
                                    <PrivateRoute exact path={routes.SEARCHING_COMPANIES} component={StepSearchCompanies} />
                                    <PrivateRoute exact path={routes.COMPANIES} component={StepCompanies} />
                                    <PrivateRoute exact path={routes.MEETING} component={StepMeeting} />
                                    <PrivateRoute exact path={routes.THANK_YOU} component={StepThankYou} />
                                    <Route exact path={routes.ERROR} component={StepError} />
                                    <Route exact path={routes.CLOSED_GAME} component={StepClosed} />
                                    {
                                        /*
                                        dataExport &&
                                            <Route exact path={routes.EXPORT} component={StepExport} />
                                            */
                                    }
                                    <Route component={StepError} />
                                </Switch> :
                                <Switch>
                                    {
                                        /*
                                        dataExport &&
                                            <Route exact path={routes.EXPORT} component={StepExport} />
                                            */
                                    }
                                    <Route component={StepClosed} />
                                </Switch>
                        }
                    </div>
                </div>
            </Router>
        );
    }


}

const mapStateToProps = (state) => {

    return {
        answers: state.answers.selected,
        selected: state.users.selected,
        isLoading: state.users.loading,
        isSaving: state.users.saving,
        error: state.users.error,
        settings: state.settings.item,
        errorSettings: state.settings.error,
    }
};

const mapDispatchToProps = {
    fetchAnswer,
    fetchSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(content)
