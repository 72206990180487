import React from 'react';
import PropTypes from 'prop-types';
import classes from './styles.module.scss';

const legend = (props) => {
    const { children } = props;

    return (
        <div className={classes.Legend}>
            { children }
        </div>
    )
};

legend.propTypes = {
    children: PropTypes.object,
};

export default legend;
