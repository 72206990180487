import * as actionTypes from '../actions/actionTypes';

export const ANSWERS_DEFAULT_STATE = {
    loading: false,
    saving: false,
    stored: false,
    error: '',
    items: [],
    selected: null,
};

export default function answers (state = ANSWERS_DEFAULT_STATE, action) {

    //console.log(action);

    switch (action.type) {
        case actionTypes.FETCH_ANSWER: {
            return {...state, selected: null, loading: true, error: ''};
        }

        case actionTypes.FETCH_ANSWER_SUCCESS: {
            return {...state, selected: action.answer, loading: false, error: ''};
        }

        case actionTypes.FETCH_ANSWER_FAIL: {
            return {...state, selected: null, loading: false, error: action.error};
        }

        case actionTypes.FETCH_ANSWER_BY_PLAYER_TOKEN: {
            return {...state, selected: null, loading: true, error: ''};
        }

        case actionTypes.FETCH_ANSWER_BY_PLAYER_TOKEN_SUCCESS: {
            return {...state, selected: action.answer, loading: false, error: ''};
        }

        case actionTypes.FETCH_ANSWER_BY_PLAYER_TOKEN_FAIL: {
            return {...state, selected: null, loading: false, error: action.error};
        }

        case actionTypes.FETCH_ANSWERS: {
            return {...state, items: [], loading: true, error: ''};
        }

        case actionTypes.FETCH_ANSWERS_SUCCESS: {
            return {...state, items: action.answers, loading: false, error: ''};
        }

        case actionTypes.FETCH_ANSWERS_FAIL: {
            return {...state, items: [], loading: false, error: action.error};
        }

        case actionTypes.ADD_ANSWER:
            return {...state, selected: null, saving: true, stored: false, error: ''};

        case actionTypes.ADD_ANSWER_SUCCESS:
            return {...state, selected: action.answer, stored: true, saving: false, error: ''};

        case actionTypes.ADD_ANSWER_FAIL:
            return {...state, selected: null, stored: false, saving: false, error: action.error};

        case actionTypes.UPDATE_ANSWER:
            return {...state, selected: null, saving: true, stored: false, error: ''};

        case actionTypes.UPDATE_ANSWER_SUCCESS:
            return {...state, selected: action.answer, stored: true, saving: false, error: ''};

        case actionTypes.UPDATE_ANSWER_FAIL:
            return {...state, stored: false, saving: false, error: action.error};

        case actionTypes.UPDATE_ANSWER_BY_PLAYER_TOKEN:
            return {...state, selected: null, saving: true, stored: false, error: ''};

        case actionTypes.UPDATE_ANSWER_BY_PLAYER_TOKEN_SUCCESS:
            return {...state, selected: action.answer, stored: true, saving: false, error: ''};

        case actionTypes.UPDATE_ANSWER_BY_PLAYER_TOKEN_FAIL:
            return {...state, stored: false, saving: false, error: action.error};

        case actionTypes.DELETE_ANSWER_BY_PLAYER_TOKEN:
            return {...state, removing: true, removed: null, error: ''};

        case actionTypes.DELETE_ANSWER_BY_PLAYER_TOKEN_SUCCESS:
            return {...state, removed: action.player, removing: false, error: ''};

        case actionTypes.DELETE_ANSWER_BY_PLAYER_TOKEN_FAIL:
            return {...state, removed: null, removing: false, error: action.error};

        default:
            return state
    }
}
