import React from 'react';
import PropTypes from 'prop-types';

import classes from './styles.module.scss';

import NavigationItem from './NavigationItem';

const navigation = (props) => {

    const { jumpToStepFn, currentStep, lastSavedStep } = props;

    return (
        <ul className={classes.NavigationItems}>
            {
                currentStep < 4 &&
                    <NavigationItem
                        clicked={jumpToStepFn}
                        touched={ currentStep > 3 }
                        step={3}
                        disabled={lastSavedStep < 3 && currentStep !== 3}
                        active={ currentStep === 3}>01</NavigationItem>
            }
            {
                currentStep < 6 &&
                    <NavigationItem
                        clicked={jumpToStepFn}
                        touched={currentStep > 5}
                        step={5}
                        disabled={lastSavedStep < 5 && currentStep !== 5}
                        active={currentStep === 5}>02</NavigationItem>
            }
            {
                currentStep < 8 &&
                    <NavigationItem
                        clicked={jumpToStepFn}
                        touched={currentStep > 7}
                        step={7}
                        disabled={lastSavedStep < 7 && currentStep !== 7}
                        active={currentStep === 7}>03</NavigationItem>
            }
            {
                currentStep < 9 &&
                    <NavigationItem
                        clicked={jumpToStepFn}
                        touched={currentStep > 8}
                        step={8}
                        disabled={lastSavedStep < 8 && currentStep !== 8}
                        active={currentStep === 8}>04</NavigationItem>
            }
        </ul>
    )
};

navigation.propTypes = {
    jumpToStepFn: PropTypes.func,
    currentStep: PropTypes.number,
    lastSavedStep: PropTypes.number,
};

export default navigation;
