// action types
import * as actionTypes from './actionTypes';

// action creators
export function sendInvitations(invitations) {
    return { type: actionTypes.SEND_INVITATIONS, invitations }
}

export function sendInvitationsSuccess(answer) {
    return { type: actionTypes.SEND_INVITATIONS_SUCCESS, answer }
}

export function sendInvitationsFail(error) {
    return { type: actionTypes.SEND_INVITATIONS_FAIL, error }
}
