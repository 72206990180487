import React from 'react';
import classes from './styles.module.scss';
import PropTypes from "prop-types";

const circle = (props) => {
    const { styles, backgroundColor, diameter } = props;

    const circleStyles = {
        ...styles,
        width: diameter ? `${diameter}px` : '30px',
        height: diameter ? `${diameter}px` : '30px',
        backgroundColor
    };

    return (
        <div className={classes.Circle} style={circleStyles} />

    )
};


circle.propTypes = {
    diameter: PropTypes.number,
    styles: PropTypes.object,
    backgroundColor: PropTypes.string,
};

export default circle;
