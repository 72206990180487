import React from 'react';
import PropTypes from 'prop-types';
import classes from './styles.module.scss';

const feedback = (props) => {
    const { children, type, styles } = props;

    let typeClass = null;
    switch (type) {
        case 'warning': typeClass = classes["Feedback--warning"];
        break;
        case 'error': typeClass = classes["Feedback--error"];
        break;
        default: typeClass = null;
    }

    const feedbackClass = [classes.Feedback, typeClass].join(' ');

    return <p className={feedbackClass} style={styles}>{ children }</p>
};

feedback.propTypes = {
    styles: PropTypes.object,
    children: PropTypes.node.isRequired,
    type: PropTypes.oneOf(['info', 'warning', 'error']),
};

feedback.defaultProps = {
    type: 'info',
};

export default feedback;
