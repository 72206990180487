import React, { Component } from 'react';
import PropTypes from "prop-types";
import classes from './styles.module.scss';
import previousIcon from '../../assets/icons/admin/arrow-left.svg';
import nextIcon from '../../assets/icons/admin/arrow-right.svg';

const ITEMS_PER_PAGE = 10;

class AdminTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            model: {
                columns: [],
                rows: [],
            },
            currentPage: 1,
            lastPage: 1,
            currentRows: [],
        };
    }

    componentDidMount() {
        const { model } = this.props;

        let currentRows = this.props.model.rows;

        this.setState({
            model,
            currentRows,
        });
    }

    handleGoToPage = (currentPage) => {

        let currentRows = this.state.model.rows;
        currentRows = currentRows.slice((currentPage - 1) * ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE);

        this.setState(
            {
                currentPage,
                currentRows
            },
            () => {
                //window.scrollTo(0, 0);
            }
        );
    };

    handlePaginationClicked = (direction = 'next') => {
        let currentPage = this.state.currentPage + 1;

        if(direction !== 'next'){
            currentPage = this.state.currentPage - 1;
        }

        let currentRows = this.state.model.rows;
        currentRows = currentRows.slice((currentPage - 1) * ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE);

        this.setState(
            {
                currentPage,
                currentRows
            },
            () => {
                //window.scrollTo(0, 0);
            }
        );
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.model !== nextProps.model){
            let currentRows = nextProps.model.rows.slice(0, ITEMS_PER_PAGE);

            this.setState({
                model: nextProps.model,
                currentRows,
                lastPage: Math.ceil(nextProps.model.rows.length  / ITEMS_PER_PAGE)
            });
        }
    }

    render() {
        const { model: { columns }, currentPage, lastPage, currentRows } = this.state;
        const { emptyMessage } = this.props;




        const paginationContentTpl = currentPage === 1 ? (
            <div className={classes["Pagination-content"]}>
                <button onClick={() => this.handleGoToPage(currentPage)} className={[classes["Pagination-page"], currentPage === 1 ? classes["Pagination-page--current"] : ''].join(' ')}>{ currentPage }</button>
                {
                    lastPage === 2 &&
                        <button onClick={() => this.handleGoToPage(lastPage)} className={classes["Pagination-page"]}>{ lastPage }</button>
                }
                {
                    lastPage > 2 &&
                        <>
                            <button onClick={() => this.handleGoToPage(2)} className={classes["Pagination-page"]}>{ '2' }</button>
                            <button onClick={() => this.handleGoToPage(3)} className={classes["Pagination-page"]}>{ '3' }</button>
                        </>
                }
            </div>
        ) : (
            currentPage + 1 <= lastPage ?
            <div className={classes["Pagination-content"]}>
                <button onClick={() => this.handleGoToPage(currentPage - 1)} className={classes["Pagination-page"]}>{ currentPage - 1 }</button>
                <button onClick={() => this.handleGoToPage(currentPage)} className={[classes["Pagination-page"], classes["Pagination-page--current"]].join(' ')}>{ currentPage }</button>
                <button onClick={() => this.handleGoToPage(currentPage + 1)} className={classes["Pagination-page"]}>{ currentPage + 1 }</button>
            </div> :
            <div className={classes["Pagination-content"]}>
                {
                    lastPage > 2 &&
                        <button onClick={() => this.handleGoToPage(currentPage - 2)} className={classes["Pagination-page"]}>{ currentPage - 2 }</button>
                }
                <button onClick={() => this.handleGoToPage(currentPage - 1)} className={classes["Pagination-page"]}>{ currentPage - 1 }</button>
                <button onClick={() => this.handleGoToPage(currentPage)} className={[classes["Pagination-page"], classes["Pagination-page--current"]].join(' ')}>{ currentPage }</button>
            </div>
        );

        const columnsTpl = columns.map((column, index) => {
            const { name } = column;

            if(!name){
                return <th key={index} />
            }

            return (
                <th key={index}>{ name }</th>
            )
        });

        const rowsTpl = currentRows.length ? currentRows.map((row, index) => {

            let actionsTpl = null;

            Object.keys(row).forEach((key) => {
                if(key !== 'data'){
                    actionsTpl = Object.keys(row[key]).map((action, index) => {
                        const style = {
                            ...row[key][action].styles,
                            color: 'red',
                            fontSize: '18px',
                            cursor: 'pointer'
                        };

                        return (
                            <button
                                key={index}
                                style={style}
                                onClick={row[key][action].func}>
                                { row[key][action].icon }
                            </button>
                        )
                    });
                }
            });

            return (
                <tr key={index}>
                    {
                        columns.map((column, ix) => {
                            if(column.key === 'actions'){
                                return (
                                    <td key={ix} className={classes["AdminTable-actions"]}>
                                        {
                                            actionsTpl
                                        }
                                    </td>
                                );
                            } else {
                                return <td key={ix}>{ row.data[column.key] }</td>;
                            }
                        })
                    }
                </tr>
            )
        }): <tr className={classes["AdminTable-empty"]}><td colSpan={4}>{ emptyMessage || 'Record non trovati' }</td></tr>;

        return (
            <>
                <table className={classes.AdminTable}>
                    <thead>
                    <tr>
                        {
                            columnsTpl
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        rowsTpl
                    }
                    </tbody>
                </table>
                <div className={classes.Pagination}>
                    <button onClick={() => this.handlePaginationClicked('previous')} disabled={currentPage === 1} className={classes["Pagination-button"]}>
                        <img src={previousIcon} alt={'previous'} />
                    </button>
                    {
                        paginationContentTpl
                    }
                    <button onClick={() => this.handlePaginationClicked('next')} disabled={currentPage === lastPage} className={classes["Pagination-button"]}>
                        <img src={nextIcon} alt={'next'} />
                    </button>
                </div>
            </>
        )
    }
}

AdminTable.propTypes = {
    model: PropTypes.shape({
        columns: PropTypes.arrayOf(PropTypes.object),
        rows: PropTypes.arrayOf(PropTypes.object),
    }),
    emptyMessage: PropTypes.object,
};

export default AdminTable;
