import React from 'react';
import PropTypes from 'prop-types';
import classes from './styles.module.scss';

const question = (props) => {
    const { title, description, styles, titleStyles, descriptionStyles, } = props;

    return (
        <div className={classes.Question} style={styles}>
            <h1 className={classes["Question-title"]} style={titleStyles}>{ title }</h1>
            {
                description &&
                    <p className={classes["Question-description"]} style={descriptionStyles}>{ description }</p>
            }
        </div>
    );
};

question.propTypes = {
    styles: PropTypes.object,
    title: PropTypes.object.isRequired,
    description: PropTypes.object,
    titleStyles: PropTypes.object,
    descriptionStyles: PropTypes.object,
};

export default question;
