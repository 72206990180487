import React from 'react';
import PropTypes from 'prop-types';
import classes from './styles.module.scss';
import EditorTextArea from '../EditorTextArea'

const adminTextarea = (props) => {
    const { reference, disabled, changed, name, readOnly, value, placeholder, styles, html } = props;

    return (
        !html ?
        <textarea
            ref={reference}
            disabled={disabled}
            name={name}
            readOnly={readOnly}
            style={styles}
            onChange={changed}
            className={classes.AdminTextarea}
            placeholder={placeholder}
            defaultValue={value} /> :
        <EditorTextArea
            toolbarHidden={true}
            ref={reference}
            disabled={disabled}
            name={name}
            readOnly={readOnly}
            style={styles}
            onChange={changed}
            className={classes.AdminTextarea}
            value={value}
        />
    );
};

adminTextarea.propTypes = {
    styles: PropTypes.object,
    name: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    changed: PropTypes.func.isRequired,
    reference: PropTypes.func,
    readOnly: PropTypes.bool,
};

export default adminTextarea;
