import React from 'react';
import classes from './styles.module.scss';
import PropTypes from "prop-types";

const progressBar = (props) => {
    const { styles, secondary } = props;

    return (
        <div
            style={styles}
            className={classes.ProgressBar}>
            <div className={[classes["ProgressBar-inner"], secondary ? classes["ProgressBar-inner--secondary"] : ''].join(' ')} />
        </div>

    )
};


progressBar.propTypes = {
    styles: PropTypes.object,
    secondary: PropTypes.bool
};

progressBar.defaultProps = {
    secondary: false,
}

export default progressBar;
