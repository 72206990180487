import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import * as routes from "../../shared/routes";
import moment from 'moment';
import classes from './styles.module.scss';
import Header from "../Header";
import Navigation from "../Navigation";
import Title from "../Title";
import Paragraph from "../Paragraph";
import Separator from "../UI/Separator";
import eyeIcon from '../../assets/icons/eye.svg';
import clockIcon from "../../assets/icons/admin/clock.svg";

// Covers
import stepCountryCover from '../../assets/images/step-country-cover.png';
import stepCountriesAnalysisCover from '../../assets/images/step-countries-analysis-cover.png';
import stepCompaniesCover from '../../assets/images/step-companies-cover.png';
import stepMeetingCover from '../../assets/images/step-meeting-cover.png';
import connect from "react-redux/es/connect/connect";
import {fetchSession} from "../../store/actions/sessions";
import { withRouter } from "react-router";
import {fetchAnswerByPlayerToken} from "../../store/actions/answers";
import constants from "../../shared/constants";
import {updatePlayerByToken} from "../../store/actions/players";

class Sidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [
                {
                    id: 1,
                    title: 'country.sidebar.title',
                    titleStyles: {
                        padding: '30px 0',
                        margin: '0 auto',
                        fontSize: '26px',
                        lineHeight: '36px'
                    },
                    backgroundStyles: {
                        backgroundImage: `url(${stepCountryCover})`,
                        transform: 'translateY(-100px)',
                        backgroundSize: '45%',
                    },
                    descriptionIds: ["country.sidebar.description"],
                    descriptionStyles: {
                        transform: 'translateY(-90px)',
                    },
                },
                {
                    id: 2,
                    title: 'countriesAnalysis.sidebar.title',
                    titleStyles: {
                        padding: '20px 0',
                        margin: '0 auto',
                        fontSize: '26px',
                        lineHeight: '36px'
                    },
                    backgroundStyles: {
                        backgroundImage: `url(${stepCountriesAnalysisCover})`,
                        backgroundSize: '45%',
                        transform: 'translateY(-25px)',
                    },
                    descriptionIds: ["countriesAnalysis.sidebar.description1", "countriesAnalysis.sidebar.description2"],
                    descriptionStyles: {
                        transform: 'translateY(-60px)',
                    },
                },
                {
                    id: 2,
                    title: 'company.sidebar.title',
                    titleStyles: {
                        padding: '30px 0',
                        margin: '0 auto',
                        maxWidth: '320px',
                        fontSize: '26px',
                        lineHeight: '36px'
                    },
                    backgroundStyles: {
                        backgroundImage: `url(${stepCompaniesCover})`,
                        transform: 'translateY(-80px)',
                        backgroundPosition: 'center center',
                        backgroundSize: '45%',
                    },
                    descriptionIds: ["company.sidebar.description1", "company.sidebar.description2"],
                    descriptionIcons: [eyeIcon],
                    descriptionStyles: {
                        transform: 'translateY(-100px)',
                    },
                },
                {
                    sidebarStyles: {
                        padding: '0 0 120px 0'
                    },
                    id: 3,
                    title: 'meeting.sidebar.title',
                    titleStyles: {
                        padding: '30px 0 0',
                        margin: '0 auto',
                        maxWidth: '350px',
                        fontSize: '26px',
                        lineHeight: '36px'
                    },
                    backgroundStyles: {
                        backgroundImage: `url(${stepMeetingCover})`,
                        backgroundSize: '50%',
                        transform: 'translateY(-40px)',
                    },
                    descriptionIds: ["meeting.sidebar.description"],
                    descriptionStyles: {
                        transform: 'translateY(-80px)',
                    },
                },
            ],
            currentSavedStep: 1,
            duration: 10000,
            countDown: null,
            userStartDate: null,
        };

        this.mounted = false;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    setCountDown = (date, minutes) => {
        if(date && minutes){
            const playerDate = moment(date).add(minutes, 'minutes');
            //console.log("Player:", playerDate.format('DD/MM/YYYY HH:mm:ss'));

            this.countDown = setInterval(() => {
                const now = moment();

                //console.log("Now:", now.format('DD/MM/YYYY HH:mm:ss'));
                const duration = moment.duration(playerDate.diff(now));

                const minutes = Math.floor(duration.asMinutes());
                const seconds = Math.floor(duration.asSeconds()%60);

                //const diff = (Date.parse(date) + minutes*60*1000) - Date.now();

                //const difference = moment(diff).format('mm:ss');

                const difference = `${minutes < 10 ? '0' + minutes.toString() : minutes}:${seconds < 10 ? '0' + seconds.toString() : seconds}`;

                if (minutes <= 0 && seconds <= 0) {
                    this.handleExpiration();
                } else {
                    if(this.mounted) {
                        this.setState({
                            countDown: difference
                        })
                    }
                }
            }, 1000);
        } else {
            this.props.history.push(routes.ERROR);
        }
    };

    handleExpiration(){
        const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);
        if(token)
            this.props.updatePlayerByToken({ token: token, expired: true });
        else
            this.props.history.push(routes.ERROR);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.playerUpdated !== nextProps.playerUpdated && nextProps.playerUpdated){
            if(nextProps.playerUpdated){
                localStorage.setItem(constants.LOCAL_STORAGE.KEYS.STATUS, 'game-expired');
                this.props.history.push(routes.ERROR);
            }
        }


        if(this.props.answer !== nextProps.answer && nextProps.answer !== null){
            //console.log(nextProps.answer);

            if(nextProps.answer.hasOwnProperty('startedAt') && nextProps.answer.startedAt){
                this.setState({
                    userStartDate: nextProps.answer.startedAt
                }, () => {
                    this.props.fetchSession(nextProps.answer.session);
                })
            } else {
                this.props.history.push(routes.ERROR)
            }
        }

        if(this.props.session !== nextProps.session && nextProps.session !== null && this.state.userStartDate){
            //console.log(moment(nextProps.session.endDate).format('DD/MM/YYYY'), moment().format('DD/MM/YYYY'));

            if(moment().isAfter(moment(nextProps.session.endDate))){
                //console.log('Session expired.');
                this.handleExpiration();
            } else {
                if(moment().isBefore(moment(nextProps.session.startDate))) {
                    localStorage.setItem(constants.LOCAL_STORAGE.KEYS.STATUS, 'session-inactive');
                    this.props.history.push(routes.ERROR);
                } else {
                    this.setCountDown(this.state.userStartDate, nextProps.session.minutes);
                }
            }
        }

        if(this.props.sessionError !== nextProps.sessionError && nextProps.sessionError){
            localStorage.setItem(constants.LOCAL_STORAGE.KEYS.STATUS, 'session-deleted');

            this.props.history.push(routes.ERROR);
        }
    }

    componentWillMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
        clearInterval(this.countDown);
    }

    render() {

        const { stepIndex, currentStep } = this.props;
        const { items, countDown } = this.state;

        const navigationItems = <Navigation currentStep={currentStep} />;

        const separatorStyles = {
            width: 'calc(100% - 200px)',
            margin: '0 100px'
        };

        const paragraphStyles = {
            fontSize: '16px',
            lineHeight: '24px',
            margin: '0 0 30px 0'
        };

        return (
            <div className={classes.Sidebar}
                 style={items[stepIndex].sidebarStyles}>
                <Header />
                <div className={classes["Sidebar-content"]}>
                    {
                        countDown &&
                        <div className={classes["Sidebar-countDown"]}>
                            <img src={clockIcon} alt={'alarm'} />
                            <span>
                            {
                                countDown
                            }
                            </span>
                        </div>
                    }
                    <div className={classes["Sidebar-navigation"]}>
                        { navigationItems }
                    </div>
                    <Separator styles={separatorStyles} />
                    <div className={classes["Sidebar-title"]}>
                        <Title
                            styles={items[stepIndex].titleStyles}
                            alignment="center">
                            <FormattedMessage id={items[stepIndex].title}/>
                        </Title>
                    </div>
                    <div className={[classes["Sidebar-cover"], stepIndex === 0 ? classes["Sidebar-cover--fixWidth"] : ''].join(' ')} style={items[stepIndex].backgroundStyles} />
                    <div className={classes["Sidebar-description"]} style={items[stepIndex].descriptionStyles}>
                        {
                            items[stepIndex].hasOwnProperty('descriptionIcons') &&
                            items[stepIndex].descriptionIcons.length ?
                                <div className={classes["Sidebar-special"]}>
                                    <FormattedMessage id={items[stepIndex].descriptionIds[0]}/>
                                    <img src={items[stepIndex].descriptionIcons[0]} alt="info"/>
                                    <FormattedMessage id={items[stepIndex].descriptionIds[1]}/>
                                </div> :
                                items[stepIndex].descriptionIds.map((description, index) => {
                                    return (
                                            <Paragraph
                                                key={index}
                                                styles={{...paragraphStyles}}
                                                textId={description}/>
                                    )
                                })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        answer: state.answers.selected,
        session: state.sessions.session,
        sessionError: state.sessions.error,
        playerUpdated: state.players.updated,
    }
};

const mapDispatchToProps = {
    fetchAnswerByPlayerToken,
    fetchSession,
    updatePlayerByToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sidebar));
