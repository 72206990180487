import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classes from './styles.module.scss';

const paragraph = (props) => {
    const { children, alignment, styles, textId } = props;

    const paragraphClass = [classes.Paragraph, alignment === 'center' ? classes["Paragraph--center"] : '', alignment === 'right' ? classes["Paragraph--right"] : ''].join(' ');

    return (
        textId ?
        <FormattedMessage id={textId} defaultMessage={''}>
            {
                textId =>
                    <div className={paragraphClass} style={styles} dangerouslySetInnerHTML={{ __html: textId }} />
            }
        </FormattedMessage> : <div className={paragraphClass} style={styles} dangerouslySetInnerHTML={{ __html: children }} />
    )
};

paragraph.propTypes = {
    styles: PropTypes.object,
    textId: PropTypes.string,
    children: PropTypes.any,
    alignment: PropTypes.oneOf(['center', 'left', 'right'])
};

export default paragraph;
