import React, { Component } from 'react';
import classes from './styles.module.scss';
import { connect } from 'react-redux';
import moment from 'moment';
import scrollIntoView from "scroll-into-view-if-needed";
import constants from "../../../shared/constants";
import { FormattedMessage } from 'react-intl';
import * as routes from "../../../shared/routes";
import {fetchPlayerByToken, updatePlayerByToken} from '../../../store/actions/players';
import {addAnswer, fetchAnswerByPlayerToken} from '../../../store/actions/answers';
import {checkValidEmail, getDaysArray, getObjectByArray, getYearsArray} from "../../../shared/helpers";
import Banner from "../../../components/Banner";
import Title from "../../../components/Title";
import Paragraph from "../../../components/Paragraph";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";
import Checkbox from "../../../components/UI/Checkbox";
import FlexBox from "../../FlexBox";
import Select from "../../../components/UI/Select";
import Feedback from "../../../components/Feedback";
import Circle from "../../../components/UI/Circle";
import Loader from "../../../components/UI/Loader";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import profileTopRightImage from '../../../assets/images/general_top_right.png';
import profileBottomLeftImage from '../../../assets/images/profile_bottom_left.png';
import profileBottomRightImage from '../../../assets/images/profile_bottom_right.png';
import yellowBookImage from '../../../assets/images/yellow_book.png';
import blueBookImage from '../../../assets/images/blue_book.png';

class StepProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            supportAnimation: constants.SUPPORT_ANIMATION,
            animate: false,
            error: '',
            newPlayer: {
                firstName: '',
                lastName: '',
                email: '',
                active: true,
            },
            fields: [
                {
                    placeholderId: "profile.placeholder.firstName",
                    name: 'firstName',
                    type: 'text',
                    capitalize: false,
                },
                {
                    placeholderId: "profile.placeholder.lastName",
                    name: 'lastName',
                    type: 'text',
                    capitalize: false,
                },
                {
                    placeholderId: "profile.placeholder.email",
                    name: 'email',
                    type: 'email',
                    error: false,
                    capitalize: false,
                    disabled: true,
                },
            ],
            birthDay: {
                labelId: 'profile.birthday.label',
                name: 'birthDay',
                type: 'select',
                day: {
                    selected: '1',
                    options: getDaysArray(0, parseInt('1990')),
                    showList: false,
                    disabled: false,
                    styles: {
                        width: '24%',
                        margin: '0 2% 0 0'
                    }
                },
                month: {
                    selected: 'january',
                    selectedNameId: 'general.month.january',
                    options: [
                        {
                            descriptionId: 'general.month.january',
                            value: 'january',
                        },
                        {
                            descriptionId: 'general.month.february',
                            value: 'february',
                        },
                        {
                            descriptionId: 'general.month.march',
                            value: 'march',
                        },
                        {
                            descriptionId: 'general.month.april',
                            value: 'april',
                        },
                        {
                            descriptionId: 'general.month.may',
                            value: 'may',
                        },
                        {
                            descriptionId: 'general.month.june',
                            value: 'june',
                        },
                        {
                            descriptionId: 'general.month.july',
                            value: 'july',
                        },
                        {
                            descriptionId: 'general.month.august',
                            value: 'august',
                        },
                        {
                            descriptionId: 'general.month.september',
                            value: 'september',
                        },
                        {
                            descriptionId: 'general.month.october',
                            value: 'october',
                        },
                        {
                            descriptionId: 'general.month.november',
                            value: 'november',
                        },
                        {
                            descriptionId: 'general.month.december',
                            value: 'december',
                        },
                    ],
                    showList: false,
                    disabled: false,
                    styles: {
                        width: '48%',
                        margin: '0 2% 0 0'
                    }
                },
                year: {
                    selected: '1990',
                    options: getYearsArray(),
                    showList: false,
                    disabled: false,
                    styles: {
                        width: '24%',
                    }
                },
            },
            contestType: {
                labelId: 'profile.contestType.label',
                name: 'contestType',
                type: 'checkbox',
                checkboxes: [
                    {
                        label: <FormattedMessage id="profile.contestType.checkbox.bocconiGraduateDevelopmentProgram"/>,
                        key: 'bocconiGraduateDevelopmentProgram',
                        checked: false,
                    },
                    {
                        label: <FormattedMessage id="profile.contestType.checkbox.other"/>,
                        key: 'other',
                        checked: false,
                    },
                ],
            },
        };

        this.onChangePlayerValue = this.onChangePlayerValue.bind(this);
        this.onClickStart = this.onClickStart.bind(this);
        this.onClickBirthday = this.onClickBirthday.bind(this);
        this.onClickBirthdayItem = this.onClickBirthdayItem.bind(this);
        this.onCheckOption = this.onCheckOption.bind(this);
        this.savePlayer = this.savePlayer.bind(this);
        this.nextButtonStatus = this.nextButtonStatus.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const {
            match
        } = this.props;

        if(match){
            //console.log("Current route:", match.url);

            if(match.params.token){
                this.props.fetchPlayerByToken(match.params.token);
            }
        }
    }

    goToTop(){
        const wrapper = document.getElementById('Wrapper');
        scrollIntoView(wrapper, { block: 'start', behavior: 'smooth' });
    }

    onChangePlayerValue(ev){
        const name = ev.target.name;
        const newPlayer = {...this.state.newPlayer};
        const fields = [...this.state.fields];

        newPlayer[name] = ev.target.value;

        let error = this.state.error;

        if(name === 'email'){
            fields[2].error = false;
            error = '';
        }

        this.setState({
            newPlayer,
            fields,
            error,
        });
    }

    savePlayer (token) {
        const { newPlayer, birthDay, contestType } = this.state;

        const contestTypeData = getObjectByArray(contestType.checkboxes, 'checked');

        const birthday = new Date(parseInt(birthDay.year.selected), constants.MONTHS.indexOf(birthDay.month.selected), parseInt(birthDay.day.selected));

        const user = {
            ...contestTypeData,
            firstName: newPlayer.firstName,
            lastName: newPlayer.lastName,
            //email: newPlayer.email,
            birthday: moment(birthday).format('YYYY-MM-DD'),
            active: true,
        };

        this.props.updatePlayerByToken({...user, token: token});
    }

    saveAnswer (_playerToken = null, _session = null) {
        if(_playerToken && _session) {
            const answer = {
                playerToken: _playerToken,
                session: _session,
                progress: {
                    step: 'role',
                },
            };

            this.props.addAnswer(answer);
        }
    }

    onClickStart(ev){
        ev.preventDefault();

        // Check email
        if(this.checkEmail()) {
            const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);

            this.savePlayer(token);
        }
    }

    onClickBirthday(event){
        let birthDay = {...this.state.birthDay};
        const name = event.target.getAttribute('data-name');

        birthDay[name].showList = !birthDay[name].showList;

        this.setState({
            birthDay
        });
    };

    onClickBirthdayItem(event){
        let birthDay = {...this.state.birthDay};
        const name = event.target.getAttribute('data-name');
        const value = event.target.getAttribute('data-value');

        birthDay[name].showList = false;
        birthDay[name].selected = value;

        birthDay['day'].options = getDaysArray(constants.MONTHS.indexOf(birthDay['month'].selected), parseInt(birthDay['year'].selected));

        this.setState({
            birthDay
        });
    };

    onCheckOption(ev){
        const index = ev.target.getAttribute('data-index');

        let contestType = {...this.state.contestType};
        let checkboxes = [...contestType.checkboxes];

        checkboxes[index].checked = !checkboxes[index].checked;


        this.setState({ contestType });
    }

    checkEmail(){
        let fields = [...this.state.fields];

        fields[2].error = !checkValidEmail(this.state.newPlayer.email);

        this.setState({
            fields,
            error: fields[2].error ? 'error.checkEmailServerSide' : ''
        });

        if(fields[2].error){
            this.goToTop();
        }

        return !fields[2].error;
    }

    nextButtonStatus(){
        const { newPlayer: { firstName, lastName, email } } = this.state;

        return firstName.trim() === '' || lastName.trim() === '' || email.trim() === '';
    }

    componentWillReceiveProps(nextProps, nextState){
        if(this.props.currentPlayer !== nextProps.currentPlayer && nextProps.currentPlayer){
            const { token, session, email, firstName, lastName, birthday, bocconiGraduateDevelopmentProgram, other, expired } = nextProps.currentPlayer;

            if(expired){
                localStorage.setItem(constants.LOCAL_STORAGE.KEYS.STATUS, 'game-expired');
                this.props.history.push(routes.ERROR);
            } else {
                if(!session || !token){
                    localStorage.setItem(constants.LOCAL_STORAGE.KEYS.STATUS, 'game-removed');
                    this.props.history.push(routes.ERROR);
                } else {
                    let newPlayer = {...this.state.newPlayer};
                    let birthDay = {...this.state.birthDay};
                    let contestType = {...this.state.contestType};

                    newPlayer.email = email;
                    newPlayer.firstName = firstName || '';
                    newPlayer.lastName = lastName || '';

                    if(birthday){
                        moment.locale('en');
                        const date = moment(birthday);
                        const month = date.format('MMMM');
                        const day = date.format('D');
                        const year = date.format('YYYY');

                        contestType.checkboxes[0].checked = bocconiGraduateDevelopmentProgram;
                        contestType.checkboxes[1].checked = other;

                        birthDay.day.selected = day.toString();
                        birthDay.month.selected = month.toLowerCase();
                        birthDay.year.selected = year.toString();
                    }


                    localStorage.setItem(constants.LOCAL_STORAGE.KEYS.PLAYER_SESSION, session);
                    localStorage.setItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN, token);

                    this.setState({
                        newPlayer,
                        birthDay,
                        contestType
                    })
                }
            }
        }

        if(this.props.errorPlayer !== nextProps.errorPlayer){
            if(nextProps.error === 'player not found'){
                localStorage.clear();
                localStorage.setItem(constants.LOCAL_STORAGE.KEYS.STATUS, 'not-registered');
            }

            setTimeout(() => {
                // Force redirect no route to force all reset
                window.location.href = routes.ERROR;
            }, 200);
        }

        if(this.props.errorAnswer !== nextProps.errorAnswer &&
            nextProps.errorAnswer !== ''){
            if(nextProps.errorAnswer === 'Answer not found for this user') {
                const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);
                const session = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_SESSION);
                this.saveAnswer(token, session)
            } else {
                this.props.history.push(routes.ERROR);
            }
        }

        if(this.props.answer !== nextProps.answer && nextProps.answer !== null){
            const { _id, completed, progress: { step } } = nextProps.answer;
            if(completed){
                localStorage.setItem(constants.LOCAL_STORAGE.KEYS.STATUS, 'game-completed');
                this.props.history.push(routes.ERROR);
            } else {
                const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);
                localStorage.setItem(constants.LOCAL_STORAGE.KEYS.ANSWER_ID, _id);
                this.props.history.push(`/challenge/${token}/${step ? step : 'role'}`);
            }
        }

        if(this.props.isStoredPlayer !== nextProps.isStoredPlayer && nextProps.isStoredPlayer){
            const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);
            this.props.fetchAnswerByPlayerToken(token);
        }

        if(this.props.isStoredAnswer !== nextProps.isStoredAnswer &&
            nextProps.isStoredAnswer){
            const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);
            this.props.history.push(routes.ROLE.replace(':token', token));
        }

        if(this.props.playerUpdated !== nextProps.playerUpdated && nextProps.playerUpdated){
            //console.log("Player updated.");
            const playerToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);
            this.props.fetchAnswerByPlayerToken(playerToken);
        }
    }

    render() {
        const { newPlayer, fields, birthDay, contestType, error } = this.state;
        const { isSavingPlayer, isSavingAnswer, isLoading } = this.props;

        const bannerStyles = {
            transform: 'translateY(-65px)'
        };

        const titleStyles = {
            margin: '20px 0 30px'
        };
        const paragraphStyles = {
            margin: '20px auto 0',
            maxWidth: '768px',
        };
        const startButtonStyles = {
            width: '320px',
            margin: '50px auto 100px'
        };

        const labelStyles = {
            color: '#FFF',
            fontFamily: 'Kodchasan, sans-serif',
            fontSize: '14px',
            fontWeight: '600',
            padding: '10px 0 30px',
            display: 'block',
            textAlign: 'left'
        };

        const birthdayLabelStyles = {
            ...labelStyles,
            padding: '10px 0 20px',
        };

        const checkboxStyles = {
            margin: '0 70px 0 0',
            maxWidth: '190px',
            textAlign: 'left'
        };

        const whiteCircleStyles = {
            position: 'absolute',
            top: '75px',
            right: '520px'
        };

        const blueCircleStyles = {
            position: 'absolute',
            top: '65px',
            right: '170px'
        };

        const orangeCircleStyles = {
            position: 'absolute',
            bottom: '450px',
            right: '90px'
        };

        const loaderStyles = {
            visibility: isLoading || isSavingPlayer || isSavingAnswer ? 'visible' : 'hidden',
            margin: '0 auto 100px'
        };

        const formFieldsTpl = fields.map(field => {
            return (
                <div className={classes["Step-control"]} key={field.name}>
                    <Input
                        value={newPlayer[field.name]}
                        name={field.name}
                        placeholderId={field.placeholderId}
                        capitalize={field.capitalize}
                        error={field.error}
                        disabled={field.disabled}
                        changed={this.onChangePlayerValue}/>
                </div>
            )
        });

        const contestTypeTpl = contestType.checkboxes.map((checkbox, index) => <Checkbox large styles={checkboxStyles} index={index} clicked={this.onCheckOption} key={index} checked={checkbox.checked}>{ checkbox.label }</Checkbox>)

        return (
            <div className={classes.Step} id="Wrapper">
                <Header />
                <div
                    className={
                        [
                            classes["Step-content"],
                            classes["is-visible"],
                        ].join(' ')}>
                    <div>
                        <Banner styles={bannerStyles} />
                        <Title
                            alignment="center"
                            styles={titleStyles}>
                            <FormattedMessage id="profile.title"/>
                        </Title>
                        <Paragraph
                            alignment="center"
                            styles={paragraphStyles}
                            textId="profile.description"/>
                        <FlexBox styles={{justifyContent: 'center', height: '100px'}}>
                            {
                                error &&
                                    <Feedback type="error" styles={{textAlign: 'center', margin: '40px auto'}}>
                                        { error ? <FormattedMessage id={error}/> : <FormattedMessage id="error.generic"/> }
                                    </Feedback>
                            }
                        </FlexBox>
                        <div className={classes["Step-form"]}>
                            {
                                formFieldsTpl
                            }
                            {
                                <div className={classes["Step-control"]}>
                                    <label style={birthdayLabelStyles}>{ <FormattedMessage id={birthDay.labelId}/> }</label>
                                    <FlexBox styles={{justifyContent: 'flex-start'}}>
                                        <Select
                                            clicked={this.onClickBirthday}
                                            itemClicked={this.onClickBirthdayItem}
                                            name="month"
                                            value={birthDay.month.selected}
                                            showList={birthDay.month.showList}
                                            styles={birthDay.month.styles}
                                            items={birthDay.month.options} />
                                        <Select
                                            clicked={this.onClickBirthday}
                                            itemClicked={this.onClickBirthdayItem}
                                            name="day"
                                            value={birthDay.day.selected}
                                            showList={birthDay.day.showList}
                                            styles={birthDay.day.styles}
                                            items={birthDay.day.options} />
                                        <Select
                                            clicked={this.onClickBirthday}
                                            itemClicked={this.onClickBirthdayItem}
                                            value={birthDay.year.selected}
                                            name="year"
                                            showList={birthDay.year.showList}
                                            styles={birthDay.year.styles}
                                            items={birthDay.year.options} />
                                    </FlexBox>
                                </div>
                            }
                            {

                                <div className={classes["Step-control"]}>
                                    <label style={labelStyles}>{ <FormattedMessage id={contestType.labelId}/> }</label>
                                    <FlexBox styles={{ justifyContent: 'flex-start'}}>
                                        { contestTypeTpl }
                                    </FlexBox>
                                </div>
                            }
                        </div>
                        <Button
                            hoverEffect={true}
                            styles={startButtonStyles}
                            disabled={this.nextButtonStatus()}
                            clicked={this.onClickStart}>
                            <FormattedMessage id="general.next"/>
                        </Button>
                        <Loader styles={loaderStyles} />
                    </div>
                    <div>
                        <Circle styles={whiteCircleStyles} diameter={10} backgroundColor={'#FFF'} />
                        <Circle styles={blueCircleStyles} diameter={23} backgroundColor={'#56f6ff'} />
                        <Circle styles={orangeCircleStyles} diameter={23} backgroundColor={'#fbb03b'} />
                        <img
                            className={[classes["Step-background"], classes["Step-background--profile"], classes["Step-background--middleLeft"]].join(' ')} src={yellowBookImage} alt="yellow book"/>
                        <img
                            className={[classes["Step-background"], classes["Step-background--profile"], classes["Step-background--middleRight"]].join(' ')} src={blueBookImage} alt="cup"/>
                        <img
                            className={[classes["Step-background"], classes["Step-background--profile"], classes["Step-background--topRight"]].join(' ')} src={profileTopRightImage} alt="background top right"/>
                        <img
                            className={[classes["Step-background"], classes["Step-background--profile"], classes["Step-background--bottomLeft"]].join(' ')} src={profileBottomLeftImage} alt="background bottom left"/>
                        <img
                            className={[classes["Step-background"], classes["Step-background--profile"], classes["Step-background--bottomRight"]].join(' ')} src={profileBottomRightImage} alt="background bottom right"/>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentPlayer: state.players.selected,
        playerUpdated: state.players.updated,
        errorPlayer: state.players.error,
        answer: state.answers.selected,
        errorAnswer: state.answers.error,

        isLoading: state.players.loading,
        isStoredPlayer: state.players.stored,
        isStoredAnswer: state.answers.stored,
        isSavingPlayer: state.players.saving,
        isSavingAnswer: state.answers.saving,
    }
};

const mapDispatchToProps = {
    fetchPlayerByToken,
    updatePlayerByToken,
    addAnswer,
    fetchAnswerByPlayerToken
};

export default connect(mapStateToProps, mapDispatchToProps)(StepProfile)
