import React from 'react';
import PropTypes from 'prop-types';
import classes from './styles.module.scss';

const separator = (props) => <hr style={props.styles} className={classes.Separator} />;

separator.propTypes = {
    styles: PropTypes.object,
};

export default separator;
