import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classes from './styles.module.scss';
import {resetUserStorage} from "../../../shared/helpers";

import Banner from "../../../components/Banner";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Title from "../../../components/Title";
import Paragraph from "../../../components/Paragraph";
import Circle from "../../../components/UI/Circle";
import constants from "../../../shared/constants";
import Button from "../../../components/UI/Button";

// Backgrounds
import errorTopRightImage from '../../../assets/images/general_top_right.png';
import errorBottomLeftImage from '../../../assets/images/thank_you_low_bottom_left.png';

class StepError extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
            status: '',
        };

        this.onClickRestart = this.onClickRestart.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const status = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.STATUS);

        let messageIdToShow = 'error.404';

        switch (status) {
            case 'not-active' : messageIdToShow = 'error.user.notActive';
                break;
            case 'not-registered' : messageIdToShow = 'error.user.notFound';
                break;
            case 'session-inactive' : messageIdToShow = 'error.session.inactive';
                break;
            case 'game-completed' : messageIdToShow = 'error.game.completed';
                break;
            case 'game-expired': messageIdToShow = 'error.game.expired';
                break;
            case 'game-removed' : messageIdToShow = 'error.game.removed';
                break;
            case 'session-deleted': messageIdToShow = 'error.session.notFound';
                break;
            default: messageIdToShow = 'error.404';
        }

        this.setState({
            messageIdToShow,
            status: status ? status : '',
        }, () => {
            resetUserStorage();
        });
    }

    onClickRestart(ev){
        ev.preventDefault();

        const route = this.state.messageIdToShow === 'error.404' ? '/' : '/profile';

        this.props.history.push(route);
    }

    render() {
        const { status, messageIdToShow } = this.state;

        const bannerStyles = {
            transform: 'translateY(-65px)'
        };
        const titleStyles = {
            margin: '110px auto 50px',
            maxWidth: '620px'
        };

        const paragraphStyles = {
            margin: '50px auto 100px',
            maxWidth: '600px',
        };

        const whiteCircleStyles = {
            position: 'absolute',
            top: '75px',
            right: '520px'
        };

        const blueCircleStyles = {
            position: 'absolute',
            top: '65px',
            right: '170px'
        };

        const orangeCircleStyles = {
            position: 'absolute',
            bottom: '250px',
            left: '80px'
        };

        const restartButtonStyles = {
            width: '320px',
            margin: '80px auto 300px'
        };

        return (
            <div style={{minHeight: '800px'}} className={classes.Step} id="Wrapper">
                <Header />
                <div
                    className={
                        [
                            classes["Step-content"],
                            classes["is-visible"],
                        ].join(' ')}>
                    <div>
                        <Banner styles={bannerStyles} />
                        <Title
                            alignment="center"
                            styles={titleStyles}>
                            <FormattedMessage id="service.title"/>
                        </Title>
                        {
                            <Paragraph
                                alignment="center"
                                styles={paragraphStyles}
                                textId={messageIdToShow}/>
                        }
                        {
                            (status === 'not-active' || status === 'not-registered' || status === '') &&
                                <Button
                                    hoverEffect={true}
                                    styles={restartButtonStyles}
                                    clicked={this.onClickRestart}>
                                    <FormattedMessage id="general.restart"/>
                                </Button>
                        }
                    </div>
                    <div>
                        <Circle styles={whiteCircleStyles} diameter={10} backgroundColor={'#FFF'} />
                        <Circle styles={blueCircleStyles} diameter={23} backgroundColor={'#56f6ff'} />
                        <Circle styles={orangeCircleStyles} diameter={23} backgroundColor={'#fbb03b'} />
                        <img
                            className={[classes["Step-background"], classes["Step-background--error"], classes["Step-background--topRight"]].join(' ')} src={errorTopRightImage} alt="top right"/>

                        <img
                            className={[classes["Step-background"], classes["Step-background--error"], classes["Step-background--bottomLeft"]].join(' ')} src={errorBottomLeftImage} alt="background bottom left"/>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default StepError;
