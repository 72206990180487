import { call, put, takeLatest } from 'redux-saga/effects'
import * as actionTypes from './store/actions/actionTypes';

import {
    fetchUserSuccess,
    fetchUsersSuccess,
    addUserSuccess,
    updateUserSuccess,
    fetchUserFail,
    fetchUsersFail,
    addUserFail,
    updateUserFail,
} from './store/actions/users';

import {
    fetchSessionSuccess,
    fetchSessionPlayersSuccess,
    fetchSessionsSuccess,
    fetchSessionAnswersSuccess,
    addSessionSuccess,
    updateSessionSuccess,
    fetchSessionFail,
    fetchSessionsFail,
    fetchSessionPlayersFail,
    fetchSessionAnswersFail,
    addSessionFail,
    updateSessionFail,
    deleteSessionSuccess,
    deleteSessionFail
} from './store/actions/sessions';

import {
    fetchPlayerSuccess,
    fetchPlayersSuccess,
    fetchPlayerByTokenSuccess,
    updatePlayerByTokenSuccess,
    addPlayerSuccess,
    updatePlayerSuccess,
    fetchPlayerFail,
    fetchPlayersFail,
    fetchPlayerByTokenFail,
    addPlayerFail,
    updatePlayerFail,
    updatePlayerByTokenFail,
    deletePlayerSuccess,
    deletePlayerFail
} from './store/actions/players';

import {
    fetchAnswerSuccess,
    fetchAnswersSuccess,
    fetchAnswerByPlayerTokenSuccess,
    updateAnswerByPlayerTokenSuccess,
    deleteAnswerByPlayerTokenSuccess,
    addAnswerSuccess,
    updateAnswerSuccess,
    fetchAnswerFail,
    fetchAnswersFail,
    addAnswerFail,
    updateAnswerFail,
    fetchAnswerByPlayerTokenFail,
    updateAnswerByPlayerTokenFail,
    deleteAnswerByPlayerTokenFail,
} from './store/actions/answers';

import {
    fetchSettingsSuccess,
    fetchSettingsFail,
} from './store/actions/settings';

import {
    sendInvitationsSuccess,
    sendInvitationsFail,
} from './store/actions/invitations';

let headers = new Headers();
headers.append('pragma', 'no-cache');
headers.append('cache-control', 'no-cache');
headers.append('Content-Type', 'application/json');

// Users
function* getAllUsers () {
    try {
        const res = yield call(fetch, `api/v1/users`, { headers });
        const users = yield res.json();
        yield put(fetchUsersSuccess(users));
    } catch (e) {
        yield put(fetchUsersFail(e.message));
    }
}

function* getUserByEmail (action) {
    try {
        const res = yield call(fetch, `api/v1/users/${action.email}`, { headers });
        const user = yield res.json();

        if(user.length){
            yield put(fetchUserSuccess(user[0]));
        } else {
            yield put(fetchUserFail('User not found'));
        }
    } catch (e) {
        yield put(fetchUserFail(e.message));
    }
}

function* saveUser (action) {
    try {
        const options = {
            method: 'POST',
            body: JSON.stringify(action.user),
            headers: new Headers({ 'Content-Type': 'application/json' })
        };

        const endpoint = action.user.hasOwnProperty('id') ? `api/v1/users/${action.user.id}` : `api/v1/users`;

        const res = yield call(fetch, endpoint, options);
        const user = yield res.json();
        if(action.user.hasOwnProperty('id')) {
            yield put(updateUserSuccess(user))
        } else {
            yield put(addUserSuccess(user))
        }
    } catch (e) {
        if(action.user.hasOwnProperty('id')) {
            yield put(updateUserFail(e.message))
        } else {
            yield put(addUserFail(e.message))
        }
    }
}

// Sessions
function* getAllSessions () {
    try {
        const res = yield call(fetch, `api/v1/sessions`, { headers });
        const sessions = yield res.json();

        yield put(fetchSessionsSuccess(sessions));
    } catch (e) {
        yield put(fetchSessionsFail(e.message));
    }
}

function* getSessionById (action) {
    try {
        const res = yield call(fetch, `api/v1/sessions/${action.id}`, { headers });
        const session = yield res.json();

        if(session){
            yield put(fetchSessionSuccess(session));
        } else {
            yield put(fetchSessionFail('Session not found'));
        }
    } catch (e) {
        yield put(fetchSessionFail(e.message));
    }
}

function* getSessionAnswers (action) {
    try {
        const res = yield call(fetch, `api/v1/sessions/${action.id}/answers`, { headers });
        const answers = yield res.json();

        if(answers){
            yield put(fetchSessionAnswersSuccess(answers));
        } else {
            yield put(fetchSessionAnswersFail('Players not found'));
        }
    } catch (e) {
        yield put(fetchSessionAnswersFail(e.message));
    }
}

function* getSessionPlayers (action) {
    try {
        const res = yield call(fetch, `api/v1/sessions/${action.id}/players`, { headers });
        const players = yield res.json();

        if(players){
            yield put(fetchSessionPlayersSuccess(players));
        } else {
            yield put(fetchSessionPlayersFail('Players not found'));
        }
    } catch (e) {
        yield put(fetchSessionPlayersFail(e.message));
    }
}

function* saveSession (action) {
    try {
        const options = {
            method: 'POST',
            body: JSON.stringify(action.session),
            headers: new Headers({ 'Content-Type': 'application/json' })
        };

        const endpoint = action.session.hasOwnProperty('id') ? `api/v1/sessions/${action.session.id}` : `api/v1/sessions`;

        const res = yield call(fetch, endpoint, options);
        const session = yield res.json();
        if(action.session.hasOwnProperty('id')) {
            yield put(updateSessionSuccess(session))
        } else {
            yield put(addSessionSuccess(session))
        }
    } catch (e) {
        if(action.session.hasOwnProperty('id')) {
            yield put(updateSessionFail(e.message))
        } else {
            yield put(addSessionFail(e.message))
        }
    }
}

function* deleteSession (action) {
    try {
        const options = {
            method: 'DELETE',
            headers: new Headers({ 'Content-Type': 'application/json' })
        };

        const endpoint = `api/v1/sessions/${action.id}`;

        const res = yield call(fetch, endpoint, options);
        const session = yield res.json();
        yield put(deleteSessionSuccess(session));
    } catch (e) {
        yield put(deleteSessionFail(e.message));
    }
}

// Players
function* getAllPlayers () {
    try {
        const res = yield call(fetch, `api/v1/players`, { headers });
        const players = yield res.json();
        yield put(fetchPlayersSuccess(players));
    } catch (e) {
        yield put(fetchPlayersFail(e.message));
    }
}

function* getPlayerByEmail (action) {
    try {
        const res = yield call(fetch, `api/v1/players/${action.email}`, { headers });
        const player = yield res.json();

        if(player.length){
            yield put(fetchPlayerSuccess(player[0]));
        } else {
            yield put(fetchPlayerFail('Player not found'));
        }
    } catch (e) {
        yield put(fetchPlayerFail(e.message));
    }
}

function* getPlayerByToken (action) {
    try {
        const res = yield call(fetch, `api/v1/players/${action.token}`, { headers });
        const player = yield res.json();

        if(player.length){
            yield put(fetchPlayerByTokenSuccess(player[0]));
        } else {
            yield put(fetchPlayerByTokenFail('Player not found'));
        }
    } catch (e) {
        yield put(fetchPlayerByTokenFail(e.message));
    }
}

function* savePlayerByToken (action) {
    try {
        const options = {
            method: 'POST',
            body: JSON.stringify(action.player),
            headers: new Headers({ 'Content-Type': 'application/json' })
        };

        const res = yield call(fetch, `api/v1/players/${action.player.token}`, options);
        const player = yield res.json();
        yield put(updatePlayerByTokenSuccess(player))
    } catch (e) {
        yield put(updatePlayerByTokenFail(e.message))
    }
}

function* savePlayer (action) {
    try {
        const options = {
            method: 'POST',
            body: JSON.stringify(action.player),
            headers: new Headers({ 'Content-Type': 'application/json' })
        };

        const endpoint = action.player.hasOwnProperty('id') ? `api/v1/players/${action.player.id}` : `api/v1/players`;

        const res = yield call(fetch, endpoint, options);
        const player = yield res.json();
        if(action.player.hasOwnProperty('id')) {
            yield put(updatePlayerSuccess(player))
        } else {
            yield put(addPlayerSuccess(player))
        }
    } catch (e) {
        if(action.player.hasOwnProperty('id')) {
            yield put(updatePlayerFail(e.message))
        } else {
            yield put(addPlayerFail(e.message))
        }
    }
}

function* deletePlayer (action) {
    try {
        const options = {
            method: 'DELETE',
            headers: new Headers({ 'Content-Type': 'application/json' })
        };

        const endpoint = `api/v1/players/${action.id}`;

        const res = yield call(fetch, endpoint, options);
        const player = yield res.json();
        yield put(deletePlayerSuccess(player));
    } catch (e) {
        yield put(deletePlayerFail(e.message));
    }
}

// Answers
function* getAllAnswers () {
    try {
        const res = yield call(fetch, `api/v1/answers`, { headers });
        const answers = yield res.json();
        yield put(fetchAnswersSuccess(answers));
    } catch (e) {
        yield put(fetchAnswersFail(e.message));
    }
}

function* getAnswerByUserId (action) {
    try {
        const res = yield call(fetch, `api/v1/answers/${action.userId}`, { headers });
        const answer = yield res.json();

        if(answer.length){
            yield put(fetchAnswerSuccess(answer[0]));
        } else {
            yield put(fetchAnswerFail('Answer not found for this user'));
        }
    } catch (e) {
        yield put(fetchAnswerFail(e.message));
    }
}

function* getAnswerByPlayerToken (action) {
    try {
        const res = yield call(fetch, `api/v1/answers/${action.playerToken}`, { headers });
        const answer = yield res.json();

        if(answer.length){
            yield put(fetchAnswerByPlayerTokenSuccess(answer[0]));
        } else {
            yield put(fetchAnswerByPlayerTokenFail('Answer not found for this user'));
        }
    } catch (e) {
        yield put(fetchAnswerByPlayerTokenFail(e.message));
    }
}

function* saveAnswerByPlayerToken (action) {
    try {
        const options = {
            method: 'POST',
            body: JSON.stringify(action.answer),
            headers: new Headers({ 'Content-Type': 'application/json' })
        };

        const res = yield call(fetch, `api/v1/answers/${action.answer.playerToken}`, options);
        const answer = yield res.json();
        yield put(updateAnswerByPlayerTokenSuccess(answer));
    } catch (e) {
        yield put(updateAnswerByPlayerTokenFail(e.message))
    }
}

function* saveAnswer (action) {
    try {
        const options = {
            method: 'POST',
            body: JSON.stringify(action.answer),
            headers: new Headers({ 'Content-Type': 'application/json' })
        };

        const endpoint = action.answer.hasOwnProperty('id') ? `api/v1/answers/${action.answer.id}` : `api/v1/answers`;

        const res = yield call(fetch, endpoint, options);
        const answer = yield res.json();
        if(action.answer.hasOwnProperty('id')) {
            yield put(updateAnswerSuccess(answer))
        } else {
            yield put(addAnswerSuccess(answer))
        }
    } catch (e) {
        if(action.answer.hasOwnProperty('id')) {
            yield put(updateAnswerFail(e.message))
        } else {
            yield put(addAnswerFail(e.message))
        }
    }
}

function* deleteAnswerByPlayerToken (action) {
    try {
        const options = {
            method: 'DELETE',
            headers: new Headers({ 'Content-Type': 'application/json' })
        };

        const endpoint = `api/v1/answers/${action.playerToken}`;

        const res = yield call(fetch, endpoint, options);
        const player = yield res.json();
        yield put(deleteAnswerByPlayerTokenSuccess(player));
    } catch (e) {
        yield put(deleteAnswerByPlayerTokenFail(e.message));
    }
}

// Settings
function* getAllSettings () {
    try {
        const res = yield call(fetch, `api/v1/settings`, { headers });
        const settings = yield res.json();
        yield put(fetchSettingsSuccess(settings[0]));
    } catch (e) {
        yield put(fetchSettingsFail(e.message));
    }
}

// Invitations
function* sendInvitations (action) {
    try {
        const options = {
            method: 'POST',
            body: JSON.stringify(action.invitations),
            headers: new Headers({ 'Content-Type': 'application/json' })
        };

        const res = yield call(fetch, `api/v1/invitations`, options);
        const answer = yield res.json();
        yield put(sendInvitationsSuccess(answer))
    } catch (e) {
        yield put(sendInvitationsFail(e.message))
    }
}

function* rootSaga() {
    // Users
    yield takeLatest(actionTypes.FETCH_USER, getUserByEmail);
    yield takeLatest(actionTypes.FETCH_USERS, getAllUsers);
    yield takeLatest(actionTypes.ADD_USER, saveUser);
    yield takeLatest(actionTypes.UPDATE_USER, saveUser);

    // Sessions
    yield takeLatest(actionTypes.FETCH_SESSION, getSessionById);
    yield takeLatest(actionTypes.FETCH_SESSION_PLAYERS, getSessionPlayers);
    yield takeLatest(actionTypes.FETCH_SESSION_ANSWERS, getSessionAnswers);
    yield takeLatest(actionTypes.FETCH_SESSIONS, getAllSessions);
    yield takeLatest(actionTypes.ADD_SESSION, saveSession);
    yield takeLatest(actionTypes.UPDATE_SESSION, saveSession);
    yield takeLatest(actionTypes.DELETE_SESSION, deleteSession);

    // Players
    yield takeLatest(actionTypes.FETCH_PLAYER, getPlayerByEmail);
    yield takeLatest(actionTypes.FETCH_PLAYER_BY_TOKEN, getPlayerByToken);
    yield takeLatest(actionTypes.FETCH_PLAYERS, getAllPlayers);
    yield takeLatest(actionTypes.ADD_PLAYER, savePlayer);
    yield takeLatest(actionTypes.UPDATE_PLAYER, savePlayer);
    yield takeLatest(actionTypes.UPDATE_PLAYER_BY_TOKEN, savePlayerByToken);
    yield takeLatest(actionTypes.DELETE_PLAYER, deletePlayer);

    // Answers
    yield takeLatest(actionTypes.FETCH_ANSWER, getAnswerByUserId);
    yield takeLatest(actionTypes.FETCH_ANSWER_BY_PLAYER_TOKEN, getAnswerByPlayerToken);
    yield takeLatest(actionTypes.FETCH_ANSWERS, getAllAnswers);
    yield takeLatest(actionTypes.ADD_ANSWER, saveAnswer);
    yield takeLatest(actionTypes.UPDATE_ANSWER_BY_PLAYER_TOKEN, saveAnswerByPlayerToken);
    yield takeLatest(actionTypes.UPDATE_ANSWER, saveAnswer);
    yield takeLatest(actionTypes.DELETE_ANSWER_BY_PLAYER_TOKEN, deleteAnswerByPlayerToken);

    // Settings
    yield takeLatest(actionTypes.FETCH_SETTINGS, getAllSettings);

    // Invitations
    yield takeLatest(actionTypes.SEND_INVITATIONS, sendInvitations);
}

export default rootSaga
