import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import constants from "../../../shared/constants";
import * as routes from "../../../shared/routes";
import classes from './styles.module.scss';
import { fetchAnswerByPlayerToken } from "../../../store/actions/answers";
import connect from "react-redux/es/connect/connect";
import Banner from "../../../components/Banner";
import Title from "../../../components/Title";
import Paragraph from "../../../components/Paragraph";
import BoxDescription from "../../../components/BoxDescription";
import FlexBox from "../../FlexBox";
import Feedback from "../../../components/Feedback";
import Circle from "../../../components/UI/Circle";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

// Backgrounds
import thankYouFlightsImage from '../../../assets/images/thank_you_flights.png';
import generalTopRightImage from '../../../assets/images/general_top_right.png';
import thankYouLowTopRightImage from '../../../assets/images/thank_you_low_top_right.png';
import thankYouHighBottomLeftImage from '../../../assets/images/thank_you_high_bottom_left.png';
import thankYouMiddleBottomLeftImage from '../../../assets/images/thank_you_middle_bottom_left.png';
import thankYouLowBottomLeftImage from '../../../assets/images/thank_you_low_bottom_left.png';
import thankYouLowMiddleLeftImage from '../../../assets/images/thank_you_low_middle_left.png';
import thankYouHighBottomRightImage from '../../../assets/images/thank_you_high_bottom_right.png';
import thankYouMiddleBottomRightImage from '../../../assets/images/thank_you_middle_bottom_right.png';
import thankYouLowBottomRightImage from '../../../assets/images/thank_you_low_bottom_right.png';

class StepThankYou extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
            messageIdToShow: null,
            topImageSrc: null,
            topImageStyles: null,
            topRightImageSrc: null,
            topRightImageStyles: null,
            middleLeftImageSrc: null,
            middleLeftImageStyles: null,
            bottomLeftImageSrc: null,
            bottomLeftImageStyles: null,
            bottomRightImageSrc: null,
            bottomRightImageStyles: null,
        };
    }

    componentDidMount() {
        const playerToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);

        if(playerToken !== null) {
            this.props.fetchAnswerByPlayerToken(playerToken);
        } else {
            this.props.history.push(routes.ERROR);
        }
    }

    componentWillReceiveProps(nextProps, nextState) {

        if(this.props.answer !== nextProps.answer &&
            nextProps.answer !== null){

            const { answer: { companies, country, countriesAnalysis, completed } } = nextProps;
            let score = 0;

            //console.log(nextProps.answer);

            if(companies && country && countriesAnalysis && completed){

                /*
                console.log("Companies:");
                console.log(constants.SCORE.COMPANIES.MAX, answer.companies.score, answer.companies.score/constants.SCORE.COMPANIES.MAX*100);
                console.log("Country:");
                console.log(constants.SCORE.COUNTRY.MAX, answer.country.score, answer.country.score/constants.SCORE.COUNTRY.MAX*100);
                console.log("Countries analysis:");
                console.log(constants.SCORE.COUNTRY_ANALYSIS.MAX, answer.countriesAnalysis.score, answer.countriesAnalysis.score/constants.SCORE.COUNTRY_ANALYSIS.MAX*100);
                */

                score += companies.score/constants.SCORE.COMPANIES.MAX*100;
                score += country.score/constants.SCORE.COUNTRY.MAX*100;
                score += countriesAnalysis.score/constants.SCORE.COUNTRY_ANALYSIS.MAX*100;
                score /= 3;

                let messageIdToShow = 'thankYou.result.middle';
                let topImageSrc = null;
                let topImageStyles = null;
                let topRightImageSrc = null;
                let topRightImageStyles = null;
                let middleLeftImageSrc = null;
                let middleLeftImageStyles = null;
                let bottomLeftImageSrc = thankYouMiddleBottomLeftImage;
                let bottomLeftImageStyles = {
                    bottom: '50px',
                    left: 0,
                    maxWidth: '855px'
                };
                let bottomRightImageSrc = thankYouMiddleBottomRightImage;
                let bottomRightImageStyles = {
                    maxWidth: '455px',
                    top: 0,
                };

                //console.log("User score: ", score);

                if (score <= 24) {
                    messageIdToShow = 'thankYou.result.low';
                    topRightImageSrc = thankYouLowTopRightImage;
                    middleLeftImageSrc = thankYouLowMiddleLeftImage;
                    bottomLeftImageSrc = thankYouLowBottomLeftImage;
                    bottomLeftImageStyles = {
                        bottom: '50px',
                        left: 0,
                        maxWidth: '855px'
                    };
                    bottomRightImageSrc = thankYouLowBottomRightImage;
                    bottomRightImageStyles = {
                        maxWidth: '580px',
                        bottom: '50px',
                    };
                }

                if (score >= 76) {
                    messageIdToShow = 'thankYou.result.high';
                    topImageSrc = thankYouFlightsImage;
                    topRightImageSrc = generalTopRightImage;
                    bottomLeftImageSrc = thankYouHighBottomLeftImage;
                    topRightImageStyles = {
                        maxWidth: '520px'
                    };
                    bottomLeftImageStyles = {
                        bottom: '-60px',
                        left: 0,
                        maxWidth: '980px'
                    };
                    bottomRightImageSrc = thankYouHighBottomRightImage;
                    bottomRightImageStyles = {
                        maxWidth: '600px',
                        bottom: '50px'
                    };
                }

                this.setState({
                    messageIdToShow,
                    topImageSrc,
                    topImageStyles,
                    topRightImageSrc,
                    topRightImageStyles,
                    middleLeftImageSrc,
                    middleLeftImageStyles,
                    bottomLeftImageSrc,
                    bottomLeftImageStyles,
                    bottomRightImageSrc,
                    bottomRightImageStyles
                }, () => {
                    localStorage.clear();
                });
            } else {
                if(!completed){
                    this.props.history.push('/');
                } else {
                    this.setState({ error: 'error.thankYou' }, () => {
                        setTimeout(() => {
                            this.props.history.push('/');
                        }, 5000);
                    });
                }
            }

        }

    }

    render() {
        const { error, messageIdToShow, topImageSrc, topImageStyles, topRightImageSrc, topRightImageStyles, middleLeftImageSrc, middleLeftImageStyles, bottomLeftImageSrc, bottomLeftImageStyles, bottomRightImageSrc, bottomRightImageStyles } = this.state;

        const bannerStyles = {
            transform: 'translateY(-65px)'
        };
        const titleStyles = {
            margin: '20px auto 50px',
            maxWidth: '620px'
        };

        const paragraphStyles = {
            margin: '0 auto 40px',
            maxWidth: '450px',
        };

        const boxDescriptionStyles = {
            fontFamily: 'Kodchasan, sans-serif',
            fontSize: '18px',
            lineHeight: '28px',
            fontWeight: '600',
        };
        const boxDescriptionWrapperStyles = {
            margin: '80px 0 140px'
        };

        const whiteCircleStyles = {
            position: 'absolute',
            top: '75px',
            right: '520px'
        };

        const blueCircleStyles = {
            position: 'absolute',
            top: '65px',
            right: '170px'
        };

        const orangeCircleStyles = {
            position: 'absolute',
            bottom: '250px',
            left: '80px'
        };

        return (
            <div style={{minHeight: '800px'}} className={classes.Step} id="Wrapper">
                <Header />
                <div
                    className={
                        [
                            classes["Step-content"],
                            classes["is-visible"],
                        ].join(' ')}>
                    <div>
                        <Banner styles={bannerStyles} />
                        <Title
                            alignment="center"
                            styles={titleStyles}>
                            <FormattedMessage id="thankYou.title"/>
                        </Title>
                        {
                            !this.props.error && !error &&
                            <Paragraph
                                alignment="center"
                                styles={paragraphStyles}
                                textId="thankYou.description"/>
                        }
                        {
                            (this.props.error || error) &&
                                <Feedback type="error" styles={{textAlign: 'center', margin: '40px auto'}}>
                                    { error ? <FormattedMessage id={error}/> : <FormattedMessage id="error.generic"/> }
                                </Feedback>
                        }
                        {
                            !this.props.error && !error &&
                            <FlexBox
                                styles={boxDescriptionWrapperStyles}>
                                {
                                    messageIdToShow &&
                                    <BoxDescription styles={boxDescriptionStyles} textId={messageIdToShow}/>
                                }
                            </FlexBox>
                        }
                    </div>
                    <div>
                        <Circle styles={whiteCircleStyles} diameter={10} backgroundColor={'#FFF'} />
                        <Circle styles={blueCircleStyles} diameter={23} backgroundColor={'#56f6ff'} />
                        <Circle styles={orangeCircleStyles} diameter={23} backgroundColor={'#fbb03b'} />
                        {
                            topImageSrc &&
                                <img
                                    style={topImageStyles}
                                    className={[classes["Step-background"], classes["Step-background--thankYou"], classes["Step-background--top"]].join(' ')} src={topImageSrc} alt="top"/>
                        }
                        {
                            topRightImageSrc &&
                                <img
                                    style={topRightImageStyles}
                                    className={[classes["Step-background"], classes["Step-background--thankYou"], classes["Step-background--topRight"]].join(' ')} src={topRightImageSrc} alt="top right"/>
                        }
                        {
                            middleLeftImageSrc &&
                                <img
                                    style={middleLeftImageStyles}
                                    className={[classes["Step-background"], classes["Step-background--thankYou"], classes["Step-background--middleLeft"]].join(' ')} src={middleLeftImageSrc} alt="middle left"/>
                        }
                        <img
                            style={bottomLeftImageStyles}
                            className={[classes["Step-background"], classes["Step-background--thankYou"], classes["Step-background--bottomLeft"]].join(' ')} src={bottomLeftImageSrc} alt="background bottom left"/>
                        <img
                            style={bottomRightImageStyles}
                            className={[classes["Step-background"], classes["Step-background--thankYou"], classes["Step-background--bottomRight"]].join(' ')} src={bottomRightImageSrc} alt="background bottom right"/>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        answer: state.answers.selected,
        isLoading: state.answers.loading,
        isSaving: state.answers.saving,
        isStored: state.answers.stored,
        error: state.answers.error
    }
};

const mapDispatchToProps = {
    fetchAnswerByPlayerToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepThankYou)
