import React, { Component } from 'react'
import './App.scss';
import * as moment from 'moment';
import 'moment/locale/it';
import { IntlProvider, addLocaleData } from 'react-intl';
import constants from "./shared/constants";
import Wrapper from './containers/Wrapper';

import itLocaleData from 'react-intl/locale-data/it';
import enLocaleData from 'react-intl/locale-data/en';

import itMessages from './translations/it.json';
import enMessages from './translations/en.json';

addLocaleData(itLocaleData);
addLocaleData(enLocaleData);

const messages = {
    it: itMessages,
    en: enMessages,
};

let language = navigator.language.toLowerCase().split(/[_-]+/)[0];
if(!messages[language]){
    language = Object.keys(messages)[0];
}

// Force language
language = 'it';

moment.locale(language);

// Set country for http request (Accept-Language usage)
localStorage.setItem(constants.LOCAL_STORAGE.KEYS.COUNTRY, language);

class App extends Component {
    render() {
        return (
            <IntlProvider
                defaultLocale="it"
                locale={'it'}
                messages={messages[language] || messages['it']}>
                <Wrapper/>
            </IntlProvider>
        );
    }
}

export default App;
