import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import classes from './styles.module.scss';
import PropTypes from "prop-types";
import Square from "../Square";
import checkIcon from '../../../assets/icons/check.svg';

class SelectBox extends Component {

    state = {
        showOverlay: false,
    };

    onClickToggleOverlay(ev){
        ev.preventDefault();

        this.toggleOverlay();
    }

    toggleOverlay(){
        const showOverlay = !this.state.showOverlay;

        this.setState({ showOverlay })
    }

    resetOverlay(){
        const showOverlay = false;

        this.setState({ showOverlay })
    }

    componentWillReceiveProps(nextProps, nextStates){
        if(nextProps.currentMainIndex !== this.props.currentMainIndex){
            this.resetOverlay();
        }
    }

    render() {

        const { styles, title, description, checked, clicked, index, icon } = this.props;

        const { showOverlay } = this.state;

        return (

            <div className={[classes.SelectBox, checked ? classes["is-selected"] : ''].join(' ')} style={styles}>
                <div className={classes["SelectBox-check"]}>
                    <Square
                        styles={{margin: '0'}}
                        clicked={clicked}
                        active={checked}
                        index={index}>
                        {
                            checked &&
                            <img className={classes["SelectBox-icon"]} src={checkIcon} alt="check" />
                        }
                    </Square>
                </div>
                <div className={classes["SelectBox-cover"]}>
                    {
                        icon &&
                            <img className={classes["SelectBox-image"]} src={icon} alt="cover" />

                    }
                </div>
                <label className={classes["SelectBox-label"]}>{ title }</label>
                <div className={classes["SelectBox-footer"]}>
                    <button
                        onClick={this.toggleOverlay.bind(this)}
                        className={classes["SelectBox-button"]}>
                        <i className={classes["SelectBox-info"]}>i</i><FormattedMessage id="general.info" />
                    </button>
                </div>
                <div className={[classes["SelectBox-overlay"], showOverlay ? classes["is-visible"] : ''].join(' ')}>
                    <button disabled className={classes["SelectBox-button"]} style={{color: 'rgba(255, 255, 255, 1)', padding: '0 0 10px'}}>
                        <i className={[classes["SelectBox-info"], classes["SelectBox-info--overlay"]].join(' ')}>i</i><FormattedMessage id="general.info" />
                    </button>
                    <div className={classes["SelectBox-content"]}>
                        {
                            description
                        }
                    </div>
                    <div className={classes["SelectBox-footer"]}>
                        <button
                            onClick={this.onClickToggleOverlay.bind(this)}
                            className={[classes["SelectBox-button"], classes["SelectBox-button--overlay"]].join(' ')}>
                            <FormattedMessage id="general.close" />
                        </button>
                    </div>
                </div>
            </div>

        )
    }
};


SelectBox.propTypes = {
    index: PropTypes.number,
    styles: PropTypes.object,
    icon: PropTypes.string,
    title: PropTypes.object,
    description: PropTypes.object,
    checked: PropTypes.bool,
    clicked: PropTypes.func,
    reset: PropTypes.bool,
    currentMainIndex: PropTypes.number,
};

export default SelectBox;
