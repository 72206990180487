import React, { Component } from 'react';
import PropTypes from "prop-types";
import classes from './styles.module.scss';

class AdminTableHeader extends Component {

    render() {
        const { title, filter, children } = this.props;

        return (
            <div className={classes.AdminTableHeader}>
                <h1 className={classes["AdminTableHeader-title"]}>
                    { title }
                </h1>
                {
                    filter &&
                        <div className={classes["AdminTableHeader-filter"]}>
                            {
                                filter
                            }
                        </div>
                }
                {
                    children &&
                        <div className={classes["AdminTableHeader-content"]}>
                            { children }
                        </div>
                }
            </div>
        )
    }
}

AdminTableHeader.propTypes = {
    title: PropTypes.object,
    filter: PropTypes.node,
    children: PropTypes.node,
};

export default AdminTableHeader;
