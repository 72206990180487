import { combineReducers } from 'redux';
import settings, { SETTINGS_DEFAULT_STATE } from './settings';
import users, { USERS_DEFAULT_STATE } from './users';
import answers, { ANSWERS_DEFAULT_STATE } from './answers';
import sessions, { SESSIONS_DEFAULT_STATE } from './sessions';
import players, { PLAYERS_DEFAULT_STATE } from './players';
import invitations, { INVITATIONS_DEFAULT_STATE } from './invitations';

const hrBocconiApp = combineReducers({
    settings,
    users,
    answers,
    sessions,
    players,
    invitations
});

export const DEFAULT_STATE = {
    settings: SETTINGS_DEFAULT_STATE,
    users: USERS_DEFAULT_STATE,
    answers: ANSWERS_DEFAULT_STATE,
    sessions: SESSIONS_DEFAULT_STATE,
    players: PLAYERS_DEFAULT_STATE,
    invitations: INVITATIONS_DEFAULT_STATE,
};

export default hrBocconiApp;
