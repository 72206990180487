import React from 'react';
import PropTypes from 'prop-types';
import bocconiLogo from '../../assets/images/bocconi-logo.png';
import bocconiLogoFooter from '../../assets/images/bocconi-logo-footer.png';
import classes from './styles.module.scss';

const logo = (props) => {
    const { type } = props;

    let logoSrc = bocconiLogo;
    if(type === 'secondary'){
        logoSrc = bocconiLogoFooter;
    }

    const description = (
        <div className={classes.Logo}>
            <span className={[classes["Logo-description"], classes["Logo-description--built"]].join(' ')}>{ 'Built' }</span>
            <span className={classes["Logo-description"]}>{ 'Bocconi University' }</span>
            <span className={[classes["Logo-description"], classes["Logo-description--sm"]].join(' ')}>{ 'Innovations in Learning & Teaching' }</span>
        </div>
    );

    if(type === 'tertiary'){
        return description;
    }

    return (
        <div className={classes.Logo}>
            <img className={[classes["Logo-image"], type === 'secondary' ? classes["Logo-image--secondary"] : ''].join(' ')} src={logoSrc} alt="Bocconi"/>
        </div>
    )
};

logo.propTypes = {
    type: PropTypes.oneOf(['primary', 'secondary', 'tertiary'])
};

logo.defaultProps = {
    type: 'primary'
};

export default logo;
