// action types
import * as actionTypes from './actionTypes';

// action creators

/*
export function addSettings(setting) {
    return { type: actionTypes.ADD_SETTINGS, setting }
}

export function addSettingsSuccess(setting) {
    return { type: actionTypes.ADD_SETTINGS_SUCCESS, setting }
}

export function addSettingsFail(error) {
    return { type: actionTypes.ADD_SETTINGS_FAIL, error }
}
export function updateSettings(setting) {
    return { type: actionTypes.UPDATE_SETTING, setting }
}

export function updateSettingsSuccess(setting) {
    return { type: actionTypes.UPDATE_SETTING_SUCCESS, setting }
}

export function updateSettingsFail(error) {
    return { type: actionTypes.UPDATE_SETTING_FAIL, error }
}*/

export function fetchSettings() {
    return { type: actionTypes.FETCH_SETTINGS }
}

export function fetchSettingsSuccess(settings) {
    return { type: actionTypes.FETCH_SETTINGS_SUCCESS, settings }
}

export function fetchSettingsFail(error) {
    return { type: actionTypes.FETCH_SETTINGS_FAIL, error }
}
