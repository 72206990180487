// PARAMETERS
const SESSION_ID = ':sessionId';
const CHALLENGE = 'challenge';
const TOKEN = ':token';

// HR ROUTES
export const HOME = '/';
export const WELCOME = `/${CHALLENGE}/${TOKEN}`;
export const PROFILE = `/${CHALLENGE}/${TOKEN}/profile`;
export const ROLE = `/${CHALLENGE}/${TOKEN}/role`;
export const COUNTRY = `/${CHALLENGE}/${TOKEN}/country`;
export const SEARCHING_COUNTRIES = `/${CHALLENGE}/${TOKEN}/searching-countries`;
export const COUNTRIES_ANALYSIS = `/${CHALLENGE}/${TOKEN}/countries-analysis`;
export const SEARCHING_COMPANIES = `/${CHALLENGE}/${TOKEN}/searching-companies`;
export const COMPANIES = `/${CHALLENGE}/${TOKEN}/companies`;
export const MEETING = `/${CHALLENGE}/${TOKEN}/meeting`;
export const THANK_YOU = `/${CHALLENGE}/${TOKEN}/thank-you`;
export const ERROR = '/error';
export const CLOSED_GAME = '/closed-game';
//export const EXPORT = '/export9562egf';


// ADMIN ROUTES
export const LOGIN = '/login';
export const ADMIN = '/admin';
export const ADMIN_SESSIONS = `${ADMIN}/sessions`;
export const ADMIN_EDIT_SESSION = `${ADMIN}/sessions/${SESSION_ID}`;
export const ADMIN_NEW_SESSION = `${ADMIN}/new-session`;
