// action types
import * as actionTypes from './actionTypes';

// action creators
export function addUser(user) {
    return { type: actionTypes.ADD_USER, user }
}

export function addUserSuccess(user) {
    return { type: actionTypes.ADD_USER_SUCCESS, user }
}

export function addUserFail(error) {
    return { type: actionTypes.ADD_USER_FAIL, error }
}

export function updateUser(user) {
    return { type: actionTypes.UPDATE_USER, user }
}

export function updateUserSuccess(user) {
    return { type: actionTypes.UPDATE_USER_SUCCESS, user }
}

export function updateUserFail(error) {
    return { type: actionTypes.UPDATE_USER_FAIL, error }
}

export function fetchUser(email) {
    return { type: actionTypes.FETCH_USER, email }
}

export function fetchUserSuccess(user) {
    return { type: actionTypes.FETCH_USER_SUCCESS, user }
}

export function fetchUserFail(error) {
    return { type: actionTypes.FETCH_USER_FAIL, error }
}

export function fetchUsers() {
    return { type: actionTypes.FETCH_USERS }
}

export function fetchUsersSuccess(users) {
    return { type: actionTypes.FETCH_USERS_SUCCESS, users }
}

export function fetchUsersFail(error) {
    return { type: actionTypes.FETCH_USERS_FAIL, error }
}
