import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import classes from './styles.module.scss';

const selectItem = (props) => {

    const { id, value, description, descriptionId, inputName, itemClicked } = props;

    return (
        <button
            onClick={itemClicked}
            data-id={id}
            data-description={description}
            data-value={value}
            data-name={inputName}
            className={classes.SelectItem}
        >
            {
                (descriptionId && <FormattedMessage id={descriptionId} />) ||
                description ||
                '-'
            }
        </button>
    );

};

selectItem.propTypes = {
    id: PropTypes.string,
    value: PropTypes.any,
    description: PropTypes.string,
    descriptionId: PropTypes.string,
    inputName: PropTypes.string,
    itemClicked: PropTypes.func,
};

export default selectItem;
