import React from 'react';
import PropTypes from 'prop-types';

import classes from './styles.module.scss';

const navigationItem = (props) => {
    const { step, clicked, active, touched, children, disabled } = props;

    return (
        <li className={classes.NavigationItem}>
            <button disabled={disabled || false}
                    onClick={clicked}
                    data-step={step}
                    className={[classes["NavigationItem-button"], active ? classes["is-active"] : '', touched ? classes["is-touched"] : '', disabled ? classes["is-disabled"] : ''].join(' ')}>{children}</button>
        </li>
    );
}

navigationItem.propTypes = {
    clicked: PropTypes.func,
    step: PropTypes.number,
    touched: PropTypes.bool,
    active: PropTypes.bool,
    children: PropTypes.any,
    disabled: PropTypes.bool,
};

export default navigationItem;
