import React, { Component } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import constants from "../../../shared/constants";
import {fetchPlayerByToken} from "../../../store/actions/players";
import {fetchSession} from "../../../store/actions/sessions";
import {connect} from "react-redux";
import classes from './styles.module.scss';
import Banner from "../../../components/Banner";
import Title from "../../../components/Title";
import Paragraph from "../../../components/Paragraph";
import BoxDescription from "../../../components/BoxDescription";
import FlexBox from "../../FlexBox";
import Button from "../../../components/UI/Button";
import Circle from "../../../components/UI/Circle";
import Loader from "../../../components/UI/Loader";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import * as routes from '../../../shared/routes';

// Backgrounds
import welcomeTopRightImage from '../../../assets/images/general_top_right.png';
import welcomeBottomLeftImage from '../../../assets/images/welcome_bottom_left.png';
import welcomeBottomRightImage from '../../../assets/images/welcome_bottom_right.png';
import yellowBookImage from '../../../assets/images/yellow_book.png';
import cupImage from '../../../assets/images/cup.png';

class StepWelcome extends Component {
    constructor(props) {
        super(props);

        this.state = {
            animate: false,
            boxDescriptionList: [
                {
                    order: '1',
                    textId: 'welcome.box1'
                },
                {
                    order: '2',
                    textId: 'welcome.box2'
                },
                {
                    order: '3',
                    textId: 'welcome.box3'
                },
                {
                    order: '4',
                    textId: 'welcome.box4'
                },
                {
                    order: '5',
                    textId: 'welcome.box5'
                },
            ],
            sessionMinutes: 90,
        };

        this.onClickStart = this.onClickStart.bind(this);
    }

    componentDidMount() {
        localStorage.clear();

        const {
            match
        } = this.props;

        if(match){
            //console.log("Current route:", match.url);

            if(match.params.token){
                this.props.fetchPlayerByToken(match.params.token);
            }
        }
    }

    onClickStart(ev){
        ev.preventDefault();

        this.setState({
            animate: true,
        });

        const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);

        if(token){
            this.props.history.push(routes.PROFILE.replace(':token', token));
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props.currentPlayer !== nextProps.currentPlayer && nextProps.currentPlayer){
            const { token, session } = nextProps.currentPlayer;

            if(!session || !token){
                window.location.href = routes.ERROR;
            } else {
                localStorage.setItem(constants.LOCAL_STORAGE.KEYS.PLAYER_SESSION, session);
                localStorage.setItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN, token);

                this.props.fetchSession(session);
            }
        }

        if(this.props.errorPlayer !== nextProps.errorPlayer){
            if(nextProps.error === 'player not found'){
                localStorage.clear();
                localStorage.setItem(constants.LOCAL_STORAGE.KEYS.STATUS, 'not-registered');
            }

            setTimeout(() => {
                // Force redirect no route to force all reset
                window.location.href = routes.ERROR;
            }, 200);
        }

        if(this.props.currentSession !== nextProps.currentSession && nextProps.currentSession){
            this.setState({
                sessionMinutes: nextProps.currentSession.minutes
            })
        }
    }

    render() {
        const { animate, boxDescriptionList, sessionMinutes } = this.state;

        const bannerStyles = {
            transform: 'translateY(-65px)'
        };
        const titleStyles = {
            margin: '10px 0 30px'
        };
        const paragraphStyles = {
            margin: '20px auto 50px',
            maxWidth: '768px',
        };
        const beCarefullyStyles = {
            margin: '0'
        };

        const boxDescriptionWrapperStyles = {
            margin: '80px 0 40px'
        };

        const whiteCircleStyles = {
            position: 'absolute',
            top: '75px',
            right: '520px'
        };

        const blueCircleStyles = {
            position: 'absolute',
            top: '65px',
            right: '170px'
        };

        const orangeCircleStyles = {
            position: 'absolute',
            bottom: '680px',
            right: '110px'
        };

        const startButtonStyles = {
            width: '320px',
            margin: '50px auto 100px'
        };

        const loaderStyles = {
            visibility: animate ? 'visible' : 'hidden',
            margin: '0 auto 100px'
        };


        const boxDescriptionTpl = boxDescriptionList.map(item => {
            return (
                <FormattedHTMLMessage id={item.textId} key={item.textId}>
                    {
                        description =>
                            <BoxDescription
                                key={item.order}
                                order={item.order}>
                                {
                                    description.replace(/--duration--/g, sessionMinutes.toString())
                                }
                            </BoxDescription>

                    }
                </FormattedHTMLMessage>
            );
        });

        return (
            <div className={classes.Step} id="Wrapper">
                <Header />
                <div
                    className={
                        [
                            classes["Step-content"],
                            classes["is-visible"],
                        ].join(' ')}>
                    <div>
                        <Banner styles={bannerStyles} />
                        <Title
                            alignment="center"
                            styles={titleStyles}>
                            <FormattedMessage id="welcome.title"/>
                        </Title>
                        {
                            <FormattedHTMLMessage id={'welcome.description'}>
                                {
                                    description =>
                                        <Paragraph
                                            alignment="center"
                                            styles={paragraphStyles}>
                                            {
                                                description.replace('--duration--', sessionMinutes.toString())
                                            }
                                        </Paragraph>

                                }
                            </FormattedHTMLMessage>
                        }
                        <Title
                            alignment="center"
                            size="medium"
                            theme="secondary">
                            <FormattedMessage id="general.instructions"/>
                        </Title>
                        <Paragraph
                            alignment="center"
                            styles={beCarefullyStyles}
                            textId="general.readCarefully"/>
                        <FlexBox
                            styles={boxDescriptionWrapperStyles}>
                            {
                                boxDescriptionTpl
                            }
                        </FlexBox>
                        <Title
                            alignment="center"
                            size="medium"
                            theme="secondary">
                            <FormattedMessage id="general.goodLuck"/>
                        </Title>
                        <Title
                            alignment="center"
                            size="medium">
                            <FormattedMessage id="general.teamMessage"/>
                        </Title>
                        <Button
                            hoverEffect={true}
                            styles={startButtonStyles}
                            clicked={this.onClickStart}>
                            <FormattedMessage id="general.start"/>
                        </Button>
                        <Loader styles={loaderStyles} />
                    </div>
                    <div>
                        <Circle styles={whiteCircleStyles} diameter={10} backgroundColor={'#FFF'} />
                        <Circle styles={blueCircleStyles} diameter={23} backgroundColor={'#56f6ff'} />
                        <Circle styles={orangeCircleStyles} diameter={23} backgroundColor={'#fbb03b'} />
                        <img
                            className={[classes["Step-background"], classes["Step-background--welcome"], classes["Step-background--middleLeft"]].join(' ')} src={yellowBookImage} alt="yellow book"/>
                        <img
                            className={[classes["Step-background"], classes["Step-background--welcome"], classes["Step-background--middleRight"]].join(' ')} src={cupImage} alt="cup"/>
                        <img
                            className={[classes["Step-background"], classes["Step-background--welcome"], classes["Step-background--topRight"]].join(' ')} src={welcomeTopRightImage} alt="background top right"/>
                        <img
                            className={[classes["Step-background"], classes["Step-background--welcome"], classes["Step-background--bottomLeft"]].join(' ')} src={welcomeBottomLeftImage} alt="background bottom left"/>
                        <img
                            className={[classes["Step-background"], classes["Step-background--welcome"], classes["Step-background--bottomRight"]].join(' ')} src={welcomeBottomRightImage} alt="background bottom right"/>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentPlayer: state.players.selected,
        currentSession: state.sessions.session,
        errorPlayer: state.players.error,
    }
};

const mapDispatchToProps = {
    fetchPlayerByToken,
    fetchSession,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepWelcome)
