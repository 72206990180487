import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import classes from './styles.module.scss';
import {resetUserStorage} from "../../../shared/helpers";

import Banner from "../../../components/Banner";
import Title from "../../../components/Title";
//import Paragraph from "../../../components/Paragraph";
import Circle from "../../../components/UI/Circle";
import Footer from "../../../components/Footer";

// Backgrounds
import errorTopRightImage from '../../../assets/images/general_top_right.png';
import errorBottomLeftImage from '../../../assets/images/thank_you_low_bottom_left.png';
import Header from "../../../components/Header";

class StepClosed extends Component {
    /*
    constructor(props) {
        super(props);
    }
    */

    componentDidMount() {
        window.scrollTo(0, 0);
        resetUserStorage();
    }

    render() {
        const bannerStyles = {
            transform: 'translateY(-65px)'
        };
        const titleStyles = {
            margin: '110px auto 50px',
            maxWidth: '620px'
        };

        /*
        const paragraphStyles = {
            margin: '50px auto 100px',
            maxWidth: '600px',
        };
        */

        const whiteCircleStyles = {
            position: 'absolute',
            top: '75px',
            right: '520px'
        };

        const blueCircleStyles = {
            position: 'absolute',
            top: '65px',
            right: '170px'
        };

        const orangeCircleStyles = {
            position: 'absolute',
            bottom: '250px',
            left: '80px'
        };

        return (
            <div style={{minHeight: '800px'}} className={classes.Step} id="Wrapper">
                <Header />
                <div
                    className={
                        [
                            classes["Step-content"],
                            classes["is-visible"],
                        ].join(' ')}>
                    <div>
                        <Banner styles={bannerStyles} />
                        <Title
                            alignment="center"
                            styles={titleStyles}>
                            <FormattedMessage id="service.closed"/>
                        </Title>
                        {
                            /*
                            <Paragraph
                                alignment="center"
                                styles={paragraphStyles}
                                textId={messageIdToShow}/>
                                */
                        }
                    </div>
                    <div>
                        <Circle styles={whiteCircleStyles} diameter={10} backgroundColor={'#FFF'} />
                        <Circle styles={blueCircleStyles} diameter={23} backgroundColor={'#56f6ff'} />
                        <Circle styles={orangeCircleStyles} diameter={23} backgroundColor={'#fbb03b'} />
                        <img
                            className={[classes["Step-background"], classes["Step-background--error"], classes["Step-background--topRight"]].join(' ')} src={errorTopRightImage} alt="top right"/>

                        <img
                            className={[classes["Step-background"], classes["Step-background--error"], classes["Step-background--bottomLeft"]].join(' ')} src={errorBottomLeftImage} alt="background bottom left"/>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default StepClosed;
