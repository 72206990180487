import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {fetchAnswerByPlayerToken, updateAnswerByPlayerToken} from "../../../store/actions/answers";
import {updatePlayerByToken} from '../../../store/actions/players';
import * as routes from "../../../shared/routes";
import connect from "react-redux/es/connect/connect";
import constants from "../../../shared/constants";
import Question from "../../../components/Question";
import Textarea from "../../../components/UI/Textarea";
import Feedback from "../../../components/Feedback";
import TwoColumnsWrapper from "../../TwoColumnsWrapper";
import Sidebar from "../../../components/Sidebar";
import Steps from "../";
import Footer from "../../../components/Footer";

import classes from './styles.module.scss';
import Button from "../../../components/UI/Button";
import Separator from "../../../components/UI/Separator";
import nextButtonIcon from '../../../assets/icons/next.svg';

class StepMeeting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: '',
            considerations: '',
            score: 0,
        };

        this.onClickNext = this.onClickNext.bind(this);
        this.onChangeConsiderations = this.onChangeConsiderations.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const playerToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);

        if(playerToken !== null) {
            this.props.fetchAnswerByPlayerToken(playerToken);
        } else {
            this.props.history.push(routes.ERROR);
        }
    }

    onChangeConsiderations(ev) {
        this.setState({
            considerations: ev.target.value,
        });
    };

    updateAnswerData(data){
        //console.log("Data to send [Meeting]: ", data);

        const playerToken = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);

        if(playerToken !== null) {
            const object = {
                ...data,
                playerToken
            };

            this.props.updateAnswerByPlayerToken(object);
        } else {
            this.setState({ error: 'error.game.removed' }, () => {
                setTimeout(() => {
                    this.props.history.push(routes.ERROR);
                }, 3000);
            });
        }
    }

    onClickNext(ev){
        ev.preventDefault();

        const data = {
            meeting: {
                considerations: this.state.considerations,
            },
            progress: {
                step: 'meeting',
                index: 1,
            },
            completed: true,
            completedAt: new Date(),
        };

        this.updateAnswerData(data);
    }

    componentWillReceiveProps(nextProps, nextState){

        if(this.props.answer !== nextProps.answer &&
            nextProps.answer !== null){

            const { progress: { step, index } } = nextProps.answer;

            const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);

            if(!(step === 'companies' && index === 2) &&
                constants.ROUTES.indexOf(`/${step}`) < constants.ROUTES.indexOf(`/meeting`)){
                this.props.history.push(`/challenge/${token}/${step}`);
            } else {
                if (step !== 'role' &&
                    step !== 'country' &&
                    step !== 'countries-analysis' &&
                    step !== 'companies' &&
                    step !== 'meeting') {
                    this.props.history.push(`/challenge/${token}/${step}`);
                } else {
                    if (step === 'meeting') {
                        this.props.updatePlayerByToken({ completed: true, token: token });
                    }
                }
            }
        }

        if(this.props.playerUpdated !== nextProps.playersUpdated && nextProps.playersUpdated){
            const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN);

            this.props.history.push(routes.THANK_YOU.replace(':token', token));
        }

        if(this.props.isStored !== nextProps.isStored){
            if(nextProps.isStored){
                const token = localStorage.getItem(constants.LOCAL_STORAGE.KEYS.PLAYER_TOKEN)
                this.props.history.push(routes.THANK_YOU.replace(':token', token));
            }
        }

        if(this.props.error !== nextProps.error){
            setTimeout(() => {
                localStorage.clear();
                this.props.history.push(routes.ERROR);
            }, 5000);
        }
    }

    render() {

        const { considerations, error } = this.state;

        const nextButtonStyles = {
            width: '130px',
            margin: '0',
            padding: '10px 15px'
        };

        const disabledNext = considerations.trim() === '';

        const contentClass = {
            margin: 0,
        };

        const questionStyles = {
            padding: '0 5px',
            maxWidth: '350px'
        };

        const questionDescriptionStyles = {
            margin: '0 10px 40px',
            fontSize: '16px',
            fontWeight: '400',
            color: '#FFF'
        };

        return (
            <TwoColumnsWrapper>
                <Sidebar
                    currentStep={8}
                    stepIndex={3}/>
                <Steps twoColumns={true}>
                    <div id="Wrapper" className={[classes.Step, classes["Step--withSidebar"]].join(' ')} style={{padding: '130px 10px 0'}}>
                        <div
                            className={
                                [
                                    classes["Step-content"],
                                    classes["is-visible"],
                                ].join(' ')}
                            style={{...contentClass, padding: '30px 0'}}>
                            <Question
                                styles={{...questionStyles}}
                                titleStyles={questionDescriptionStyles}
                                title={<FormattedMessage id="meeting.question.considerations"/>} />
                            <Textarea
                                changed={this.onChangeConsiderations}
                                value={considerations}
                                maxLength={500}
                                placeholderId="meeting.placeholder.considerations"
                                styles={{margin: '0 10px 20px'}} />
                        </div>
                        {
                            (this.props.error || error) &&
                                <Feedback type="error" styles={{ margin: '40px auto'}}>
                                    { error ? <FormattedMessage id={error}/> : <FormattedMessage id="error.generic"/> }
                                </Feedback>
                        }
                        <Separator />
                        <div className={classes["Step-footer"]} style={{justifyContent: 'flex-end'}}>
                            <Button
                                hoverEffect={true}
                                icon={nextButtonIcon}
                                disabled={disabledNext}
                                styles={nextButtonStyles}
                                clicked={this.onClickNext}>
                                <FormattedMessage id="general.next"/>
                            </Button>
                        </div>
                    </div>
                </Steps>
                <Footer />
            </TwoColumnsWrapper>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        answer: state.answers.selected,
        isLoading: state.answers.loading,
        isSaving: state.answers.saving,
        isStored: state.answers.stored,
        error: state.answers.error,
        playerUpdated: state.players.updated,
    }
};

const mapDispatchToProps = {
    fetchAnswerByPlayerToken,
    updateAnswerByPlayerToken,
    updatePlayerByToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(StepMeeting);
