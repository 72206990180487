import React from 'react';
import PropTypes from 'prop-types';
import classes from './styles.module.scss';

const selectItem = (props) => {

    const { id, value, description, inputName, itemClicked, light } = props;

    const selectItemClasses = [classes.SelectItem, light ? classes["SelectItem--light"] : ''].join(' ');

    return (
        <button
            onClick={itemClicked}
            data-id={id}
            data-description={description}
            data-value={value}
            data-name={inputName}
            className={selectItemClasses}
        >
            {
                description
            }
        </button>
    );

};

selectItem.propTypes = {
    id: PropTypes.string,
    value: PropTypes.any,
    description: PropTypes.object,
    inputName: PropTypes.string,
    itemClicked: PropTypes.func,
};

export default selectItem;
