import React, { Component } from 'react';
import classes from './styles.module.scss';

class Dialog extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showContainer: false
        };

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        const { allowScroll } = this.props;

        if (typeof window !== 'undefined' && !allowScroll) {
            document.body.style.overflow = 'hidden';
        }
    }

    componentWillMount() {
        document.addEventListener('click', this.handleClick, false);
    }

    componentWillUnmount() {
        const { allowScroll } = this.props;

        document.removeEventListener('click', this.handleClick, false);

        if (typeof window !== 'undefined' && !allowScroll) {
            document.body.style.overflow = 'visible';
        }
    }

    handleClick = e => {
        if (this.modal && !this.modal.contains(e.target)) {
            const { onDialogCloseClicked } = this.props;

            onDialogCloseClicked(e);
        }
    };

    render() {
        const {
            styles,
            children,
            height,
            width,
            showCloseButton,
            onDialogCloseClicked,
            title,
            top,
            notice,
            selector
        } = this.props;

        const containerStyles = {
            ...styles,
            width: width ? `${width}px` : '470px',
            //height: height ? `${height}px`: '470px',
            top: top ? top : height ? `calc(50% - ${height / 2}px)` : `calc(50% - 320px)`,
            left: width ? `calc(50% - ${width / 2}px)` : `calc(50% - 235px)`,
            padding: showCloseButton ? '50px 30px 30px' : '30px',
        };

        return (
            <div className={classes.Dialog}>
                <div
                    style={containerStyles}
                    className={classes['Dialog-container']}
                    ref={node => this.modal = node }>
                    {
                        showCloseButton && (
                            <button
                                onClick={onDialogCloseClicked}
                                className={classes['Dialog-close']}>
                                chiudi
                            </button>
                        )
                    }
                    {
                        title && (
                            <h1 className={classes['Dialog-title']}>
                                { title }
                            </h1>
                        )
                    }
                    {
                        selector
                    }
                    {
                        notice && (
                            <small className={classes['Dialog-notice']}>
                                { notice }
                            </small>
                        )
                    }
                    <div className={classes['Dialog-content']}>{children}</div>
                </div>
            </div>
        );
    }
}

export default Dialog;
