import React from 'react';
import PropTypes from 'prop-types';

import classes from './styles.module.scss';

import StepperItem from './StepperItem';

const stepper = (props) => {

    const { items, currentStep, styles } = props;

    const itemsTpl = items.map((item, index) => {
        const icon = index <= currentStep ? item.icon : item.fallbackIcon;

        return (
            <StepperItem
                key={index}
                active={index <= currentStep}>
                { item.icon ? <img src={icon} alt={item.label} /> : item.label }
            </StepperItem>
        )
    });

    return (
        <div className={classes.Stepper} style={styles}>
            <div className={classes["Stepper-line"]} />
            <ul className={classes["Stepper-list"]}>
                { itemsTpl }
            </ul>
        </div>
    )
};

stepper.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentStep: PropTypes.number,
    styles: PropTypes.object
};

export default stepper;
