// action types
import * as actionTypes from './actionTypes';

// action creators
export function addSession(session) {
    return { type: actionTypes.ADD_SESSION, session }
}

export function addSessionSuccess(session) {
    return { type: actionTypes.ADD_SESSION_SUCCESS, session }
}

export function addSessionFail(error) {
    return { type: actionTypes.ADD_SESSION_FAIL, error }
}

export function updateSession(session) {
    return { type: actionTypes.UPDATE_SESSION, session }
}

export function updateSessionSuccess(session) {
    return { type: actionTypes.UPDATE_SESSION_SUCCESS, session }
}

export function updateSessionFail(error) {
    return { type: actionTypes.UPDATE_SESSION_FAIL, error }
}

export function fetchSession(id) {
    return { type: actionTypes.FETCH_SESSION, id }
}

export function fetchSessionSuccess(session) {
    return { type: actionTypes.FETCH_SESSION_SUCCESS, session }
}

export function fetchSessionAnswersFail(error) {
    return { type: actionTypes.FETCH_SESSION_ANSWERS_FAIL, error }
}

export function fetchSessionAnswers(id) {
    return { type: actionTypes.FETCH_SESSION_ANSWERS, id }
}

export function fetchSessionAnswersSuccess(answers) {
    return { type: actionTypes.FETCH_SESSION_ANSWERS_SUCCESS, answers }
}

export function fetchSessionPlayersFail(error) {
    return { type: actionTypes.FETCH_SESSION_PLAYERS_FAIL, error }
}

export function fetchSessionPlayers(id) {
    return { type: actionTypes.FETCH_SESSION_PLAYERS, id }
}

export function fetchSessionPlayersSuccess(players) {
    return { type: actionTypes.FETCH_SESSION_PLAYERS_SUCCESS, players }
}

export function fetchSessionFail(error) {
    return { type: actionTypes.FETCH_SESSION_FAIL, error }
}

export function fetchSessions() {
    return { type: actionTypes.FETCH_SESSIONS }
}

export function fetchSessionsSuccess(sessions) {
    return { type: actionTypes.FETCH_SESSIONS_SUCCESS, sessions }
}

export function fetchSessionsFail(error) {
    return { type: actionTypes.FETCH_SESSIONS_FAIL, error }
}

export function deleteSession(id) {
    return { type: actionTypes.DELETE_SESSION, id }
}

export function deleteSessionSuccess() {
    return { type: actionTypes.DELETE_SESSION_SUCCESS,  }
}

export function deleteSessionFail(error) {
    return { type: actionTypes.DELETE_SESSION_FAIL, error }
}
