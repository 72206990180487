import * as actionTypes from '../actions/actionTypes';

export const SESSIONS_DEFAULT_STATE = {
    loading: false,
    saving: false,
    saved: false,
    removing: false,
    removed: false,
    error: '',
    sessions: [],
    players: [],
    session: null,
    updated: false,
    idSessionCreated: null,
    answers: []
};

export default function sessions (state = SESSIONS_DEFAULT_STATE, action) {

    //console.log(action);

    switch (action.type) {
        case actionTypes.FETCH_SESSION: {
            return {...state, session: null, loading: true, error: ''};
        }

        case actionTypes.FETCH_SESSION_SUCCESS: {
            return {...state, session: action.session, loading: false, error: ''};
        }

        case actionTypes.FETCH_SESSION_FAIL: {
            return {...state, session: null, loading: false, error: action.error};
        }

        case actionTypes.FETCH_SESSION_ANSWERS: {
            return {...state, answers: [], loading: true, error: ''};
        }

        case actionTypes.FETCH_SESSION_ANSWERS_SUCCESS: {
            return {...state, answers: action.answers, loading: false, error: ''};
        }

        case actionTypes.FETCH_SESSION_ANSWERS_FAIL: {
            return {...state, answers: [], loading: false, error: action.error};
        }

        case actionTypes.FETCH_SESSION_PLAYERS: {
            return {...state, players: [], loading: true, error: ''};
        }

        case actionTypes.FETCH_SESSION_PLAYERS_SUCCESS: {
            return {...state, players: action.players, loading: false, error: ''};
        }

        case actionTypes.FETCH_SESSION_PLAYERS_FAIL: {
            return {...state, players: [], loading: false, error: action.error};
        }

        case actionTypes.FETCH_SESSIONS: {
            return {...state, sessions: [], loading: true, saved: false, error: ''};
        }

        case actionTypes.FETCH_SESSIONS_SUCCESS: {
            return {...state, sessions: action.sessions, loading: false, error: ''};
        }

        case actionTypes.FETCH_SESSIONS_FAIL: {
            return {...state, sessions: [], loading: false, error: action.error};
        }

        case actionTypes.ADD_SESSION:
            return {...state, session: null, idSessionCreated: null, saving: true, saved: false, error: ''};

        case actionTypes.ADD_SESSION_SUCCESS:
            return {...state, session: action.session, saved: true, idSessionCreated: action.session._id, saving: false, error: ''};

        case actionTypes.ADD_SESSION_FAIL:
            return {...state, session: null, saved: false, saving: false, error: action.error};

        case actionTypes.UPDATE_SESSION:
            return {...state, session: null, saving: true, updated: false, error: ''};

        case actionTypes.UPDATE_SESSION_SUCCESS:
            return {...state, session: action.session, updated: true, saving: false, error: ''};

        case actionTypes.UPDATE_SESSION_FAIL:
            return {...state, saved: false, saving: false, error: action.error};

        case actionTypes.DELETE_SESSION:
            return {...state, removing: true, removed: false, error: ''};

        case actionTypes.DELETE_SESSION_SUCCESS:
            return {...state, removed: true, removing: false, error: ''};

        case actionTypes.DELETE_SESSION_FAIL:
            return {...state, removed: false, removing: false, error: action.error};

        default:
            return state
    }
}
